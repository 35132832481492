import React, { Component } from "react";
import { Typography, Paper, Button, Link } from "@material-ui/core";
import moment from "moment";
import { withRouter } from "react-router-dom";

class Comment extends Component {
    handleGoto(id) {
        return () => this.props.history.push("/agent/" + id);
    }
    handleResolve(id) {
        return () => this.props.resolve(id);
    }
    render() {
        const { data } = this.props;
        return (
            <Paper style={{ padding: 12, marginBottom: 12 }}>
                <Typography variant="body2">
                    {`Comentario por`}{" "}
                    <Link
                        onClick={this.handleGoto(data.agent.id)}
                        style={{ cursor: "pointer" }}
                    >
                        {data.agent.name}
                    </Link>{" "}
                    {`[${moment(data.created_at).format(
                        "DD [de] MMM [de] YYYY"
                    )}]`}
                </Typography>
                <Typography variant="body1" style={{ marginTop: 8 }}>
                    {data.content}
                </Typography>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={data.resolved}
                        onClick={this.handleResolve(data.id)}
                        size="small"
                    >
                        {data.resolved
                            ? `Resuelto ${moment(data.updated_at).fromNow()}`
                            : "Marcar como resuelto"}
                    </Button>
                </div>
            </Paper>
        );
    }
}

export default withRouter(Comment);
