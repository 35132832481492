import React, { Component } from "react";
import { Typography, TextField, withStyles } from "@material-ui/core";
import { formatMoney } from "../Utils/functions";

const style = () => ({
    input: {
        padding: 0,
        width: "50%",
    },
});

class EditableText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
        };

        this.handleEdit = this.handleEdit.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleEdit(event) {
        const { target } = event;
        const { edit } = this.state;
        if (edit) {
            const element = {
                ...this.props.element,
            };
            element[this.props.name] = target.value;
            this.props.edit(element);
        }
        this.setState({ edit: !edit });
    }

    handleKeyPress(event) {
        const { target, key } = event;
        if (key === "Enter") {
            const { edit } = this.state;
            if (edit) {
                const element = {
                    ...this.props.element,
                };
                element[this.props.name] = target.value;
                this.props.edit(element);
            }
            this.setState({ edit: !edit });
        }
    }

    render() {
        const { variant, classes, element, name } = this.props;
        const { edit } = this.state;
        return (
            <>
                {edit ? (
                    <TextField
                        onBlur={this.handleEdit}
                        autoFocus
                        className={classes.input}
                        placeholder={element[name]}
                        onKeyPress={this.handleKeyPress}
                    />
                ) : (
                    <Typography
                        variant={variant}
                        onDoubleClick={this.handleEdit}
                    >
                        {formatMoney(element[name]) || "--"}
                    </Typography>
                )}
            </>
        );
    }
}

export default withStyles(style)(EditableText);
