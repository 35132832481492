import React, { Component } from "react"
import {
    withStyles,
    Typography,
    IconButton,
    Button,
    Divider,
    Dialog,
} from "@material-ui/core"
import Loader from "../../Shared/Loader/Loader"
import { connect } from "react-redux"
import {
    getPendings,
    markAsConfirmed,
    unpayLesson,
} from "../../Actions/LessonActions"
import GeneralTable from "../../Shared/GeneralTable"
import { agentLessonInfo, agentGroupInfo } from "../Agents/info"
import { autobind } from "../../Utils/functions"
import { withSnackbar } from "notistack"
import { MonetizationOn, ExpandMore, HighlightOff } from "@material-ui/icons"
import Conditional from "../../Shared/Conditional"
const style = () => ({
    container: {
        padding: 12,
    },
    inline: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
})

class Pendings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: [],
            subselected: [],
            open: false,
            row: false,
        }
        autobind(Pendings, this)
    }
    handleConfirmLesson(row) {
        return () => {
            this.setState({ selected: [row.id] }, this.handleConfirmLessons)
        }
    }

    handleConfirmGroup(row) {
        return () => {
            const ids = row.lessons.map((lesson) => {
                return lesson.id
            })
            this.setState({ selected: ids }, this.handleConfirmLessons)
        }
    }

    handleConfirmLessons() {
        const { selected } = this.state
        const { enqueueSnackbar, markAsConfirmed } = this.props
        const body = {
            ids: selected,
        }
        markAsConfirmed(body, enqueueSnackbar)
        this.setState({ selected: [], open: false, row: false })
    }

    handleConfirmSubLessons() {
        const { subselected } = this.state
        const { enqueueSnackbar, markAsConfirmed } = this.props
        const body = {
            ids: subselected,
        }
        markAsConfirmed(body, enqueueSnackbar)
        this.setState({ subselected: [], open: false, row: false })
    }

    handleUnselect() {
        this.setState({ selected: [] })
    }

    handleSubUnselect() {
        this.setState({ subselected: [] })
    }

    handleSelectRow(id) {
        return () => {
            const { selected } = this.state
            if (selected.indexOf(id) === -1) {
                selected.push(id)
            } else {
                selected.splice(selected.indexOf(id), 1)
            }
            this.setState({ selected })
        }
    }

    handleSelectSubRow(id) {
        return () => {
            const { subselected } = this.state
            const index = subselected.indexOf(id)
            if (index === -1) {
                subselected.push(id)
            } else {
                subselected.splice(index, 1)
            }
            this.setState({ subselected })
        }
    }

    handleOpen(row) {
        return () => {
            const ids = row.lessons.map((lesson) => lesson.id)
            this.setState({ open: !this.state.open, subselected: ids, row })
        }
    }

    handleRemoveLesson(row) {
        return () => {
            const { unpayLesson, enqueueSnackbar } = this.props
            unpayLesson(row.id, enqueueSnackbar)
            this.setState({ subselected: [], open: false, row: false })
        }
    }

    render() {
        const { selected } = this.state
        const { classes, getPendings, lessons, account } = this.props
        const disabled = account.user.level === "coordinator"
        const actions = [
            {
                name: "Confirmar pago",
                icon: ({ row }) => (
                    <IconButton
                        onClick={this.handleConfirmGroup(row)}
                        color="primary"
                        disabled={disabled}
                    >
                        <MonetizationOn />
                    </IconButton>
                ),
            },
            {
                name: "Abrir detalle",
                icon: ({ row }) => (
                    <IconButton onClick={this.handleOpen(row)} color="primary">
                        <ExpandMore />
                    </IconButton>
                ),
            },
        ]
        const { open, row, subselected } = this.state
        const groups = lessons.pendings ? lessons.pendings.groups : []
        const orphans = lessons.pendings ? lessons.pendings.orphans : []
        return (
            <div className={classes.container}>
                <Dialog
                    open={open}
                    onClose={() => this.setState({ open: false })}
                    fullWidth
                    maxWidth="lg"
                >
                    <div className={classes.container}>
                        <div className={classes.inline}>
                            <Typography variant="subtitle1">
                                Detalle del grupo
                            </Typography>
                            <Button
                                color="primary"
                                variant="outlined"
                                size="small"
                                onClick={this.handleConfirmSubLessons}
                                disabled={disabled}
                            >
                                Confirmar Seleccionados
                            </Button>
                        </div>
                        <GeneralTable
                            open
                            info={agentLessonInfo}
                            data={row ? row.lessons : []}
                            selected={subselected}
                            handleSelect={this.handleSelectSubRow}
                            handleUnselect={this.handleSubUnselect}
                            ableSelect
                            name="subgroup"
                            actions={[
                                {
                                    name: "Retirar clase",
                                    icon: ({ row }) => (
                                        <IconButton
                                            disabled={disabled}
                                            onClick={this.handleRemoveLesson(
                                                row
                                            )}
                                            color="primary"
                                        >
                                            <HighlightOff
                                                style={{ color: "#cb6a6d" }}
                                            />
                                        </IconButton>
                                    ),
                                },
                                {
                                    name: "Confirmar pago",
                                    icon: ({ row }) => (
                                        <IconButton
                                            disabled={disabled}
                                            onClick={this.handleConfirmLesson(
                                                row
                                            )}
                                            color="primary"
                                        >
                                            <MonetizationOn />
                                        </IconButton>
                                    ),
                                },
                            ]}
                        />
                    </div>
                </Dialog>
                <Typography variant="h1" style={{ margin: "24px 0" }}>
                    Pagos pendiente de confirmación
                </Typography>
                <div className={classes.inline}>
                    <Typography variant="h2">
                        Tabla de pagos pendientes
                    </Typography>
                    <Conditional condition={orphans && orphans.length > 0}>
                        <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            disabled={selected.length === 0 || disabled}
                            onClick={this.handleConfirmLessons}
                        >
                            Confirmar Seleccionados
                        </Button>
                    </Conditional>
                </div>
                <Divider />
                <Loader action={getPendings}>
                    <GeneralTable
                        open
                        info={agentGroupInfo}
                        data={groups}
                        actions={actions}
                        name="groups"
                    />
                </Loader>
                <Loader action={getPendings}>
                    <Conditional condition={orphans && orphans.length > 0}>
                        <>
                            <Divider />
                            <GeneralTable
                                open
                                info={agentLessonInfo}
                                data={orphans}
                                selected={selected}
                                handleSelect={this.handleSelectRow}
                                handleUnselect={this.handleUnselect}
                                ableSelect
                                name="orphans"
                                actions={[
                                    {
                                        name: "Confirmar pago",
                                        icon: ({ row }) => (
                                            <IconButton
                                                onClick={this.handleConfirmLesson(
                                                    row
                                                )}
                                                disabled={disabled}
                                                color="primary"
                                            >
                                                <MonetizationOn />
                                            </IconButton>
                                        ),
                                    },
                                ]}
                            />
                        </>
                    </Conditional>
                </Loader>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    lessons: state.lesson,
    account: state.account,
})

const mapDispatchToProps = (dispatch) => ({
    getPendings: () => dispatch(getPendings()),
    markAsConfirmed: (body, snackbar) =>
        dispatch(markAsConfirmed(body, snackbar)),
    unpayLesson: (id, snackbar) => dispatch(unpayLesson(id, snackbar)),
})

export default withSnackbar(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Pendings))
)
