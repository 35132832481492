import React, { Component } from "react"
import { Typography, Divider, withStyles } from "@material-ui/core"
import { formatMoney } from "../../../Utils/functions"
import propTypes from "prop-types"
import GeneralTable from "../../../Shared/GeneralTable"
import { agentLessonInfo } from "../info"
import Conditional from "../../../Shared/Conditional"
import ResponsiveLesson from "./ResponsiveLesson"

const style = () => ({
    inline: {
        display: "flex",
        justifyContent: "space-between",
        alingItems: "center",
        margin: "6px 0",
    },
    total: {
        width: "40%",
        "@media (max-width:500px)": {
            width: "100%",
        },
    },
})

class PaymentDialog extends Component {
    render() {
        const {
            allLessons,
            selected,
            totalToPay,
            select,
            classes,
            credits,
            unselect,
        } = this.props
        return (
            <>
                <Typography
                    variant="h1"
                    style={{ marginBottom: 6 }}
                >{`Pago histórico de clases`}</Typography>
                <Typography variant="subtitle2">{`Este pago corresponde a todas las clases aún no pagadas en su registro histórico`}</Typography>
                <Divider />
                <Typography variant="caption">{`Puede quitar aquellas que no desea pagar en este momento`}</Typography>
                <GeneralTable
                    data={allLessons.filter((l) => !l.payed)}
                    info={agentLessonInfo}
                    name="all-lessons"
                    open={true}
                    selected={selected}
                    handleSelect={select}
                    handleUnselect={unselect}
                    ableSelect
                    maxHeight={200}
                    ResponsiveRender={ResponsiveLesson}
                />
                <Divider />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div className={classes.total}>
                        <Conditional condition={credits > 0}>
                            <>
                                <div className={classes.inline}>
                                    <Typography variant="body2">
                                        Subtotal:
                                    </Typography>
                                    <Typography variant="body2">
                                        {formatMoney(totalToPay)}
                                    </Typography>
                                </div>
                                <div className={classes.inline}>
                                    <Typography variant="body2">
                                        Créditos:
                                    </Typography>
                                    <Typography variant="body2">
                                        -{formatMoney(credits)}
                                    </Typography>
                                </div>
                                <Divider />
                            </>
                        </Conditional>
                        <div className={classes.inline}>
                            <Typography variant="subtitle1">
                                Total a pagar:
                            </Typography>
                            <Typography variant="subtitle1">
                                {totalToPay - credits > 0
                                    ? formatMoney(totalToPay - credits)
                                    : formatMoney(0)}
                            </Typography>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

PaymentDialog.propTypes = {
    allLessons: propTypes.arrayOf(propTypes.object),
    totalToPay: propTypes.number,
    selected: propTypes.arrayOf(
        propTypes.oneOfType([propTypes.string, propTypes.number])
    ),
}

PaymentDialog.defaultProps = {
    allLessons: [],
    selected: [],
    totalToPay: 0,
    credits: 0,
}

export default withStyles(style)(PaymentDialog)
