import React from "react";
import { ExitToApp } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

export const tableInfo = [
    { key: "name", label: "Nombre" },
    { key: "sii_rut", label: "SII" },
    { key: "rut", label: "Rut" },
    { key: "account", label: "N° Cuenta" },
    { key: "school&name", label: "Colegio" },
    { key: "email", label: "Correo" },
];

export const coordinatorActions = (self) => [
    {
        name: "Ver Perfil",
        action: self.handleGoto,
        icon: ({ row }) => (
            <IconButton onClick={self.handleGoto(row)} color="primary">
                <ExitToApp />
            </IconButton>
        ),
    },
];
