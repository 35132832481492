import axios from "axios"
import { createSnackbar } from "../Utils/snackbars"
import baseURL from "../Router/url"
import instance from "../Utils/Instance"

export const login = (body, snackbar) => {
    const REQUEST = axios.post(baseURL + "login/" + body.option, body)
    createSnackbar(REQUEST, snackbar)
    return {
        type: "Login",
        payload: REQUEST,
    }
}

export const logout = () => {
    return {
        type: "Logout",
    }
}

export const changePassword = (body, snackbar) => {
    const REQUEST = instance({
        method: "put",
        url: `password`,
        data: body,
    })
    createSnackbar(REQUEST, snackbar)
    return {
        type: "changePassword",
        payload: REQUEST,
    }
}

export const loginDispatcher = (dispatch) => ({
    login: (body, snackbar) => dispatch(login(body, snackbar)),
    logout: () => dispatch(logout()),
    changePassword: (body, snackbar) =>
        dispatch(changePassword(body, snackbar)),
})

export const resetAccountAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'users/reset',
        data: body
    })
    return {
        type: "resetPassword",
        payload: REQUEST
    }
}