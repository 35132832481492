import React, { Component } from "react";
import { connect } from "react-redux";
import {
    createCoordinator,
    deleteCoordinator,
    getCoordinator,
} from "../../Actions/CoordinatorActions";
import { withSnackbar } from "notistack";
import { getRequired, validate } from "../../Utils/validations";
import { coordinatorInfo } from "./formInfo";
import GeneralForm from "../../Shared/GeneralForm";
import { getAllBanks } from "../../Actions/BankAction";
import { getAllSchools } from "../../Actions/SchoolsActions";
import { Typography, withStyles, Button } from "@material-ui/core";
import { coordinatorActions } from "./tableinfo";
import Transition from "../../Shared/Transition";
import { autobind } from "../../Utils/functions";
import ViewTabs from "../../Shared/ViewTabs";
import All from "./All";
import CoordPaymentsTable from "./CoordPaymentsTable";

const style = (theme) => ({
    container: {
        padding: 12,
    },
    title: {
        padding: "12px 0 0 12px",
    },
    formContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: 24,
    },
    flexLine: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
});

class Coordinators extends Component {
    constructor(props) {
        super(props);
        this.state = {
            params: {},
            open: false,
            selected: 0,
        };

        autobind(Coordinators, this);
    }
    componentDidMount() {
        this.props.getAllBanks();
        this.props.getAllSchools();
    }

    handleChange(event) {
        const params = { ...this.state.params };
        const target = event.target;
        params[target.name] = target.value;
        this.setState({ params });
    }

    handleCreate(body) {
        const { createCoordinator, enqueueSnackbar } = this.props;
        const { params } = this.state;
        const validations = getRequired(coordinatorInfo);
        if (validate(validations, params, enqueueSnackbar)) {
            const body = { ...params };
            createCoordinator(body, enqueueSnackbar);
            this.setState({ params: {}, open: false });
        }
    }

    handleSelect(value) {
        return () => {
            this.setState({ selected: value });
        };
    }

    handleDelete(id) {
        const { deleteCoordinator, enqueueSnackbar } = this.props;
        deleteCoordinator(id, enqueueSnackbar);
    }

    handleGoto(row) {
        return () => {
            const { getCoordinator, history } = this.props;
            getCoordinator(row.id);
            history.push("/coordinator/" + row.id);
        };
    }

    setOptions(info) {
        const result = [...info];
        const { school, bank } = this.props;
        result.forEach((item, index) => {
            if (item.type === "select") {
                switch (item.name) {
                    case "school_id":
                        result[index].options = school.all || [];
                        break;
                    case "bank_id":
                        result[index].options = bank.all || [];
                        break;
                    default:
                        break;
                }
            }
        });
        return result;
    }

    handleOpen() {
        this.setState({ open: !this.state.open });
    }

    render() {
        const { classes } = this.props;
        const { params, open, selected } = this.state;
        return (
            <div className={classes.container}>
                <div className={classes.flexLine}>
                    <Typography variant="h1" style={{ padding: 12 }}>
                        Coordinadores
                    </Typography>
                    <Button
                        color="primary"
                        onClick={this.handleOpen}
                        variant="outlined"
                        size="small"
                    >
                        Nuevo Coordinador
                    </Button>
                </div>
                <Transition on={open}>
                    <div style={{ marginBottom: 24 }}>
                        <GeneralForm
                            info={this.setOptions(coordinatorInfo)}
                            handleChange={this.handleChange}
                            create={this.handleCreate}
                            params={params}
                            title="Nuevo Coordinador"
                            submit="Agregar coordinador"
                        />
                    </div>
                </Transition>
                <ViewTabs
                    tabs={["TODOS", "SUELDOS"]}
                    handleSelect={this.handleSelect}
                    selected={selected}
                >
                    <All actions={coordinatorActions(this)} />
                    <CoordPaymentsTable actions={coordinatorActions(this)} />
                </ViewTabs>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    coordinator: state.coordinator,
    bank: state.bank,
    school: state.school,
});

const mapDispatchToProps = (dispatch) => ({
    getCoordinator: (id) => dispatch(getCoordinator(id)),
    createCoordinator: (body, snackbar) =>
        dispatch(createCoordinator(body, snackbar)),
    deleteCoordinator: (id, snackbar) =>
        dispatch(deleteCoordinator(id, snackbar)),
    getAllBanks: () => dispatch(getAllBanks()),
    getAllSchools: () => dispatch(getAllSchools()),
});

export default withStyles(style)(
    withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Coordinators))
);
