import React, { Component } from "react"
import {
    withStyles,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    IconButton,
    TextField,
    TableSortLabel,
    Typography,
    Tooltip,
    Checkbox,
} from "@material-ui/core"
import { Search, KeyboardArrowDown } from "@material-ui/icons"
import { getCellValue, autobind } from "../Utils/functions"
import DeletePopup from "./DeletePopup"
import "./animation.css"
import Conditional from "./Conditional"
import ResponsiveCard from "./Table/ResponsiveCard"

const style = (theme) => ({
    container: {
        maxHeight: "50%",
        overflowY: "auto",
        height: "50%",
        marginBottom: 12,
    },
    cell: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    inline: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    search: {
        color: theme.palette.primary.dark,
        background: "#00000017",
        padding: 2,
    },
})

class GeneralTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filtered: [],
            header: "",
            order: "asc",
            on: false,
            deleteAction: undefined,
            open: false,
        }

        autobind(GeneralTable, this)
    }

    componentDidMount() {
        const { open } = this.props
        this.setState({ open: open })
    }

    handleSort(key, order) {
        return () => {
            const newOrder = order === "asc" ? "desc" : "asc"
            this.setState({ header: key, order: newOrder })
        }
    }

    handleOpenDelete() {
        this.setState({ on: !this.state.on })
    }

    handleSearch(event) {
        const { target } = event
        const { data } = this.props
        const newData = [...data]
        const result = newData.filter((element) => {
            let bool = false
            Object.keys(element).forEach((key) => {
                if (
                    String(element[key])
                        .toLocaleLowerCase()
                        .includes(target.value.toLocaleLowerCase())
                ) {
                    bool = true
                }
            })
            return bool
        })
        this.setState({ filtered: result })
    }

    handleOpen() {
        this.setState({ open: !this.state.open })
    }

    filter(data) {
        const { info, name } = this.props
        const target = document.getElementById(`searchBar-${name}`)
        if (target && target.value !== "") {
            const result = data.filter((cell) => {
                let bool = false
                info.forEach((element) => {
                    const keys = element.key.split("&")
                    if (keys.length > 1) {
                        if (
                            String(cell?.[keys[0]]?.[keys[1]])
                                .toLocaleLowerCase()
                                .includes(target.value.toLocaleLowerCase())
                        ) {
                            bool = true
                        }
                    } else {
                        if (
                            String(cell?.[keys[0]])
                                .toLocaleLowerCase()
                                .includes(target.value.toLocaleLowerCase())
                        ) {
                            bool = true
                        }
                    }
                })
                return bool
            })
            return result
        } else {
            return data
        }
    }

    sort(data) {
        const { header, order } = this.state
        if (header !== "") {
            const result = data.sort(function (a, b) {
                const keys = header.split("&")
                if (keys.length > 1) {
                    if (
                        String(a?.[keys[0]]?.[keys[1]]).toLocaleLowerCase() >
                        String(b?.[keys[0]]?.[keys[1]]).toLocaleLowerCase()
                    ) {
                        return order === "asc" ? 1 : -1
                    }
                    if (
                        String(a?.[keys[0]]?.[keys[1]]).toLocaleLowerCase() <
                        String(b?.[keys[0]]?.[keys[1]]).toLocaleLowerCase()
                    ) {
                        return order === "asc" ? -1 : 1
                    }
                } else {
                    if (
                        String(a?.[keys[0]]).toLocaleLowerCase() >
                        String(b?.[keys[0]]).toLocaleLowerCase()
                    ) {
                        return order === "asc" ? 1 : -1
                    }
                    if (
                        String(a?.[keys[0]]).toLocaleLowerCase() <
                        String(b?.[keys[0]]).toLocaleLowerCase()
                    ) {
                        return order === "asc" ? -1 : 1
                    }
                }

                return 0
            })
            return result
        }
        return data
    }

    renderTableHead() {
        const {
            info,
            actions,
            ableSelect,
            selected,
            handleUnselect,
            data,
        } = this.props
        const { header, order } = this.state
        const width = (100 / (info.length + 1)).toString() + "%"
        const cells = info.map((cell, index) => (
            <TableCell
                key={cell.key}
                style={{ textAlign: cell.align ? cell.align : "left" }}
            >
                {cell.align && cell.align === "right" && !cell.notSortable && (
                    <TableSortLabel
                        active={cell.key === header}
                        direction={order}
                        onClick={this.handleSort(cell.key, order)}
                    />
                )}
                {cell.label}{" "}
                {(!cell.align || cell.align === "left") &&
                    !cell.notSortable && (
                        <TableSortLabel
                            active={cell.key === header}
                            direction={order}
                            onClick={this.handleSort(cell.key, order)}
                        />
                    )}
            </TableCell>
        ))

        return (
            <TableRow>
                {ableSelect && (
                    <TableCell style={{ width }}>
                        <Checkbox
                            indeterminate={
                                0 < selected.length &&
                                selected.length < data.length
                            }
                            color="primary"
                            onClick={
                                selected.length > 0
                                    ? handleUnselect
                                    : this.handleSelectAll
                            }
                            checked={selected.length > 0}
                        />
                    </TableCell>
                )}
                {cells}
                {actions.length > 0 && <TableCell style={{ width }} />}
            </TableRow>
        )
    }

    renderTableBody() {
        const { info, classes, data, actions, ableSelect } = this.props
        const width = (100 / (info.length + 1)).toString() + "%"
        if (data) {
            return this.sort(this.filter(data)).map((element, index) => {
                const cells = info.map((cell, index) => {
                    return (
                        <TableCell
                            key={index}
                            style={{
                                width,
                                textAlign: cell.align ? cell.align : "left",
                            }}
                            className={classes.cell}
                        >
                            <>{getCellValue(element, cell)}</>
                        </TableCell>
                    )
                })
                return (
                    <TableRow key={index}>
                        <>
                            {ableSelect && (
                                <TableCell>
                                    {this.renderSelectRow(element)}
                                </TableCell>
                            )}
                            {cells}
                            {actions.length > 0 && (
                                <TableCell
                                    style={{ textAlign: "end", paddingLeft: 6 }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        {this.renderActions(element)}
                                    </div>
                                </TableCell>
                            )}
                        </>
                    </TableRow>
                )
            })
        }
    }

    renderActions(row) {
        const { actions } = this.props
        return actions.map((action, index) => (
            <Tooltip title={action.name || ""} key={index.toString()}>
                <span>
                    <action.icon row={row} />
                </span>
            </Tooltip>
        ))
    }

    renderResponsiveCards() {
        const {
            info,
            data,
            actions,
            ableSelect,
            ResponsiveRender,
            handleSelect,
            selected,
        } = this.props
        const Responsive = ResponsiveRender ? ResponsiveRender : ResponsiveCard
        if (data) {
            return this.filter(data).map((element, index) => {
                return (
                    <Responsive
                        info={info}
                        data={element}
                        key={index}
                        ableSelect={ableSelect}
                        actions={actions}
                        selected={selected}
                        handleSelect={handleSelect}
                    />
                )
            })
        }
    }

    renderSelectRow(row) {
        const { handleSelect, selected } = this.props
        return (
            <Checkbox
                onClick={handleSelect(row.id)}
                color="primary"
                checked={selected.indexOf(row.id) !== -1}
            />
        )
    }

    handleSelectAll() {
        const { data, handleSelect } = this.props
        if (data) {
            this.sort(this.filter(data)).forEach((element, index) => {
                handleSelect(element.id)()
            })
        }
    }

    render() {
        const { name, data, maxHeight, header, classes } = this.props
        const { on, deleteAction, deleteId, open } = this.state
        const isMobile = window.innerWidth <= 500
        return (
            <div>
                <div className={classes.inline}>
                    <div>{header}</div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: 12,
                        }}
                    >
                        <TextField
                            id={`searchBar-${name}`}
                            fullWidth
                            placeholder="Buscar... "
                            onChange={this.handleSearch}
                            label={"Buscador"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <Search
                                        position="end"
                                        className={classes.search}
                                    />
                                ),
                            }}
                        />
                    </div>
                </div>

                <div
                    style={{
                        background: "#dce9f2",
                        borderRadius: 5,
                        padding: "12px 12px 12px 12px",
                        transition: "all 1s ease-in-out",
                    }}
                >
                    <div
                        onClick={this.handleOpen}
                        style={{ cursor: "pointer" }}
                    >
                        <IconButton
                            size="small"
                            className={open ? "open" : ""}
                            style={{ transition: "all 0.3s ease-in-out" }}
                            onClick={this.handleOpen}
                        >
                            <KeyboardArrowDown />
                        </IconButton>
                        <Conditional condition={!open}>
                            <Typography
                                variant="caption"
                                style={{ paddingLeft: 12 }}
                            >
                                {data.length} elemento(s)
                            </Typography>
                        </Conditional>
                    </div>
                    <Conditional condition={open}>
                        <>
                            <Conditional condition={data.length > 0} hasElse>
                                <Conditional condition={!isMobile}>
                                    <div
                                        style={{ maxHeight, overflow: "auto" }}
                                    >
                                        <Table stickyHeader>
                                            <TableHead>
                                                {this.renderTableHead()}
                                            </TableHead>
                                            <TableBody>
                                                {this.renderTableBody()}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </Conditional>
                                <Typography variant="subtitle1">
                                    No hay datos para mostrar en la tabla...
                                </Typography>
                            </Conditional>
                        </>
                    </Conditional>
                </div>
                <Conditional condition={open && data.length > 0 && isMobile}>
                    <div style={{ maxHeight: 600, overflowY: "auto" }}>
                        {this.renderResponsiveCards()}
                    </div>
                </Conditional>

                <DeletePopup
                    on={on}
                    action={deleteAction}
                    deleteId={deleteId}
                    close={this.handleOpenDelete}
                />
            </div>
        )
    }
}

GeneralTable.defaultProps = {
    data: [],
    actions: [],
    info: [],
    mobileInfo: [],
    selected: [],
    ableSelect: false,
    handleSelect: undefined,
    maxHeight: 300,
    handleUnselect: undefined,
    ResponsiveRender: undefined,
}

export default withStyles(style)(GeneralTable)
