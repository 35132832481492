export const defaultStyle = {
    container: {
        padding: 12,
    },
    tableContainer: {
        margin: "12px 0",
        marginBottom: 24,
    },
    title: {
        margin: "24px 0",
    },
    formContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: 24,
    },
    flexLine: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
};
