import React, { Component } from "react";
import { Typography, withStyles, Divider, IconButton } from "@material-ui/core";
import { formatMoney } from "../../Utils/functions";
import { Delete } from "@material-ui/icons";
import moment from "moment";
import Conditional from "../../Shared/Conditional";

const style = (theme) => ({
    container: {
        padding: 12,
        background: theme.palette.primary.lighter,
        position: "relative",
    },
    name: {
        color: theme.palette.primary.dark,
        fontWeight: 600,
    },
    costs: {
        width: "50%",
    },
    inline: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    text: {
        marginBottom: 6,
    },
    money: {
        color: theme.palette.confirm.main,
    },
    subtext: {
        marginTop: 12,
    },
});

class DiscountCard extends Component {
    render() {
        const { classes, discount, handleDelete, disableDelete } = this.props;
        return (
            <div className={classes.container}>
                <div className={classes.inline}>
                    <Typography variant="subtitle2">
                        Descuento entre:{" "}
                        <span className={classes.name}>
                            {discount.agent.name}
                        </span>{" "}
                        (Apoderado) y{" "}
                        <span className={classes.name}>
                            {discount.tutor.name}
                        </span>{" "}
                        (Tutor/a)
                    </Typography>
                    <Conditional condition={!disableDelete}>
                        <IconButton
                            size="small"
                            color="secondary"
                            onClick={handleDelete(discount.data.id)}
                        >
                            <Delete />
                        </IconButton>
                    </Conditional>
                </div>
                <Divider />
                <Typography variant="body2" className={classes.text}>
                    Cambio de Tarifas
                </Typography>
                <div className={classes.costs}>
                    <div className={classes.inline}>
                        <Typography variant="body1">Costo de clase:</Typography>
                        <Typography variant="body1" className={classes.money}>
                            {formatMoney(discount.data.cost)}
                        </Typography>
                    </div>
                    <div className={classes.inline}>
                        <Typography variant="body1">Pago a tutor:</Typography>
                        <Typography variant="body1" className={classes.money}>
                            {formatMoney(discount.data.payment)}
                        </Typography>
                    </div>
                    <div style={{ margin: "6px 0" }} />
                    <div className={classes.inline}>
                        <Typography variant="body1">Desde:</Typography>
                        <Typography variant="body1">
                            {moment(discount.data.start_date).format(
                                "DD [de] MMMM [del] YYYY"
                            )}
                        </Typography>
                    </div>
                    <div className={classes.inline}>
                        <Typography variant="body1">Hasta:</Typography>
                        <Typography variant="body1">
                            {moment(discount.data.end_date).format(
                                "DD [de] MMMM [del] YYYY"
                            )}
                        </Typography>
                    </div>
                </div>
                <Divider />
                <Typography variant="subtitle2">
                    Aplicado cada{" "}
                    <span className={classes.name}>
                        {discount.data.lesson_amount}
                    </span>{" "}
                    clases consecutivas
                </Typography>
                <div className={classes.subtext}>
                    <Typography variant="caption">
                        Las tarifas de descuento se aplicarán independiente de
                        la asignatura.
                    </Typography>
                </div>
            </div>
        );
    }
}

DiscountCard.propTypes = {};

DiscountCard.defaultProps = {
    discount: {
        agent: { name: "no name" },
        tutor: { name: "no name" },
        data: { cost: 0, payment: 0 },
    },
    handleDelete: () => {},
    disableDelete: false,
};

export default withStyles(style)(DiscountCard);
