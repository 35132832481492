import actionMaker from "../Utils/reducers";

const SchoolReducer = (
    state = {
        all: [],
        coordinators: [],
        tutors: [],
        agents: [],
        subjects: [],
        selected: {},
    },
    action
) => {
    switch (action.type) {
        case "getAllSchools":
            return actionMaker(state, "all", "fetch", action);
        case "createSchool":
            return actionMaker(state, "all", "create", action);
        case "deleteSchool":
            return actionMaker(state, "all", "delete", action);
        case "getSchoolTutors":
            return actionMaker(state, "tutors", "fetch", action);
        case "getSchoolCoordinators":
            return actionMaker(state, "coordinators", "fetch", action);
        case "getSchoolAgents":
            return actionMaker(state, "agents", "fetch", action);
        case "selectSchool":
            return actionMaker(state, "selected", "fetch", action);
        case "createCoordinator":
            return actionMaker(state, "coordinators", "create", action);
        case "createTutor":
            return actionMaker(state, "tutors", "create", action);
        case "createAgent":
            return actionMaker(state, "agents", "create", action);
        case "deleteCoordinator":
            return actionMaker(state, "coordinators", "delete", action);
        case "deleteTutor":
            return actionMaker(state, "tutors", "delete", action);
        case "deleteSchoolAgent":
            return actionMaker(state, "agents", "delete", action);
        case "getSchoolSubjects":
            return actionMaker(state, "subjects", "fetch", action);
        case "createSubject":
            return actionMaker(state, "subjects", "create", action);
        case "deleteSubject":
            return actionMaker(state, "subjects", "delete", action);
        case "editSubject":
            return actionMaker(state, "subjects", "edit", action);
        case "getDiscounts":
            return actionMaker(state, "discounts", "fetch", action);
        default:
            break;
    }
    return state;
};

export default SchoolReducer;
