import React, { Component } from "react"
import {
    withStyles,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    withTheme,
    Divider,
    IconButton,
} from "@material-ui/core"
import GeneralForm from "../../Shared/GeneralForm"
import { withSnackbar } from "notistack"
import { getRequired, validate } from "../../Utils/validations"
import { AddCircle, Delete, KeyboardArrowDown } from "@material-ui/icons"
import EditableText from "../../Shared/EditableText"
import DeletePopup from "../../Shared/DeletePopup"
import "../../Shared/animation.css"
import classNames from "classnames"
import { autobind } from "../../Utils/functions"
import Transition from "../../Shared/Transition"
import Conditional from "../../Shared/Conditional"

const style = (theme) => ({
    container: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridTemplateAreas: "'info info info' 'subjects subjects form'",
        gridGap: 12,
    },
    paper: {
        maxHeight: 300,
        overflowY: "auto",
        gridArea: "subjects",
    },
    table: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 8,
    },
    icon: {
        padding: 3,
        color: "darkgrey",
        cursor: "pointer",
        borderRadius: "50%",
        "&:hover": {
            color: theme.palette.error.main,
        },
        marginLeft: 12,
        transition: "all 0.3s",
    },
})

class SchoolSubjects extends Component {
    constructor(props) {
        super(props)
        this.state = {
            params: {},
            on: false,
            deleteAction: undefined,
            deleteId: "",
            type_id: 1,
            open: false,
            openSubjects: false,
        }

        autobind(SchoolSubjects, this)
    }
    renderSubjects(subjects) {
        const { classes, theme } = this.props
        const { on, deleteAction, deleteId } = this.state
        const filtered = subjects.filter(
            (s) => s.subject_type_id === this.state.type_id
        )
        const { indigo, grey } = theme.palette
        return filtered.map((subject, index) => (
            <div
                key={subject.id}
                className={classNames(classes.table, "fade-in")}
                style={{
                    background: index % 2 === 0 ? indigo.lighter : grey.main,
                }}
            >
                <Typography variant="body2">{subject.name}</Typography>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                margin: "0 12px",
                                alignItems: "center",
                            }}
                        >
                            <Typography
                                variant="body1"
                                style={{ margin: "0 6px" }}
                            >
                                Costo por hora:
                            </Typography>
                            <EditableText
                                variant="body2"
                                element={subject}
                                name="cost"
                                edit={this.handleEdit}
                            />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography
                                variant="body1"
                                style={{ margin: "0 6px" }}
                            >
                                Sueldo tutor:
                            </Typography>
                            <EditableText
                                variant="body2"
                                element={subject}
                                name="payment"
                                edit={this.handleEdit}
                            />
                        </div>
                    </div>
                    <Delete className={classes.icon} onClick={
                        () => {
                            this.setState({
                                deleteAction: this.handleDelete,
                                deleteId: subject,
                                on: true
                            })
                        }
                    } />
                </div>
                <DeletePopup
                    on={on}
                    action={deleteAction}
                    close={this.handleOpenPopup}
                    deleteId={deleteId}
                />
            </div>
        ))
    }

    handleChangeType(event) {
        const { value } = event.target
        this.setState({ type_id: value })
    }

    handleOpen() {
        this.setState({ open: !this.state.open })
    }

    handleOpenPopup() {
        this.setState({ on: !this.state.on })
    }

    handleChange(event) {
        const { target } = event
        const params = { ...this.state.params }
        params[target.name] = target.value
        this.setState({ params })
    }

    handleEdit(element) {
        const body = { ...element }
        this.props.edit(body, this.props.enqueueSnackbar)
    }

    handleCreate() {
        const { params } = this.state
        const { info, id } = this.props
        const validations = getRequired(info)
        if (validate(validations, params, this.props.enqueueSnackbar)) {
            const body = { ...params, school_id: id }
            this.props.create(body, this.props.enqueueSnackbar)
            this.setState({ params: {} })
        }
    }

    handleDelete(subject) {
        const { hideSubject, id } = this.props
        const body = { id, subject_id: subject.id }
        hideSubject(body)
    }

    handleOpenSubjects() {
        this.setState({ openSubjects: !this.state.openSubjects })
    }

    render() {
        const { classes, info, subjects } = this.props
        const { params, open, openSubjects } = this.state
        return (
            <div className={classes.container}>
                <div style={{ gridArea: "info" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography variant="h2">
                            Tabla de Asignaturas
                        </Typography>
                        <IconButton onClick={this.handleOpen}>
                            <AddCircle color="primary" />
                        </IconButton>
                    </div>
                    <Divider />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "66%",
                        }}
                    >
                        <FormControl style={{ margin: "8px 0" }}>
                            <InputLabel shrink>
                                Selecciona un tipo de clase
                            </InputLabel>
                            <Select
                                onChange={this.handleChangeType}
                                value={this.state.type_id}
                            >
                                {this.props.types.map((type) => (
                                    <MenuItem value={type.id} key={type.id}>
                                        {type.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <IconButton
                            size="small"
                            onClick={this.handleOpenSubjects}
                        >
                            <KeyboardArrowDown
                                style={{
                                    transform: openSubjects
                                        ? "rotate(180deg)"
                                        : "rotate(0deg)",
                                    transition: "all 0.3s ease-in-out",
                                }}
                            />
                        </IconButton>
                    </div>
                </div>
                <Conditional condition={openSubjects}>
                    <div className={classes.paper}>
                        {this.renderSubjects(subjects || [])}
                    </div>
                </Conditional>
                <div style={{ gridArea: "form" }}>
                    <Transition on={open}>
                        <GeneralForm
                            params={params}
                            info={info}
                            title="Nueva Asignatura"
                            grid="1fr"
                            handleChange={this.handleChange}
                            create={this.handleCreate}
                            submit="Agregar"
                        />
                    </Transition>
                </div>
            </div>
        )
    }
}

export default withTheme(withSnackbar(withStyles(style)(SchoolSubjects)))
