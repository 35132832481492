import React, { Component } from "react";
import {
    Paper,
    withStyles,
    ListItem,
    ListItemText,
    Typography,
    Divider,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import routes from "../Router/route_config";

const style = () => ({
    container: {
        minHeight: "100vh",
        background: "linear-gradient(to bottom, #2cace3 60%, #22607b)",
    },
    link: {
        textDecoration: "none",
        color: "black",
    },
    title: {
        textAlign: "left",
        padding: "24px 12px",
    },
});

class SideBar extends Component {
    renderList() {
        const { classes, type } = this.props;
        const filtered = routes.filter(
            (route) =>
                (route.only && route.type === type) ||
                (!route.only && route.type >= type)
        );
        return filtered.map((route, index) => (
            <Link key={index} to={route.path} className={classes.link}>
                <ListItem button>
                    <ListItemText
                        primary={
                            <Typography variant="h3">{route.name}</Typography>
                        }
                    />
                </ListItem>
            </Link>
        ));
    }
    render() {
        const { classes } = this.props;
        return (
            <Paper square className={classes.container} elevation={2}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "24px 0",
                        background:
                            "linear-gradient(180deg, rgba(255,255,255,1) 87%, rgba(44,172,227,1) 100%)",
                    }}
                >
                    <img
                        src="/logo-rtu.png"
                        alt="logoRTU"
                        style={{
                            width: "60%",
                            margin: "auto",
                        }}
                    />
                </div>
                <Divider />
                {this.renderList()}
            </Paper>
        );
    }
}

export default withStyles(style)(SideBar);
