import React, { Component } from "react"
import { FormControl, InputLabel, Select } from "@material-ui/core"
import { renderMonths } from "../Utils/functions"

export default class MonthSelector extends Component {
    render() {
        const { value, handleMonth } = this.props
        return (
            <FormControl>
                <InputLabel shrink>Seleccione Mes</InputLabel>
                <Select value={value} onChange={handleMonth}>
                    {renderMonths()}
                </Select>
            </FormControl>
        )
    }
}
