import React, { Component } from "react"
import {
    Typography,
    Divider,
    Button,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    withStyles,
} from "@material-ui/core"
import { download, autobind } from "../../../Utils/functions"
import GeneralTable from "../../../Shared/GeneralTable"
import { paymentsInfo } from "../tutorInfo"
import moment from "moment"
import {
    getTutorsPayments,
    payTutorsAction,
} from "../../../Actions/TutorActions"
import { connect } from "react-redux"
import LoaderSpinner from "../../../Shared/Loader/LoaderSpinner"

function getMonthNumber(monthName) {
    return moment.months().indexOf(monthName) + 1
}

const style = () => ({
    inline: {
        display: "flex",
        alignItems: "center",
    },
    title: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "12px 12px 0 12px",
    },
    flex: {
        display: "flex",
        justifyContent: "flex-end",
    },
})

class PaymentTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            hasChanged: false,
            params: {
                sdate: moment(new Date()).format("YYYY-MM-[01]"),
                edate: moment(new Date())
                    .add(1, "month")
                    .format("YYYY-MM-[01]"),
                smonth: moment(new Date()).format("MMMM"),
                emonth: moment(new Date()).add(1, "month").format("MMMM"),
                syear: moment(new Date()).format("YYYY"),
                eyear: moment(new Date()).add(1, "month").format("YYYY"),
            },
        }
        autobind(PaymentTable, this)
    }

    async componentDidMount() {
        const { getTutorsPayments } = this.props
        const { params } = this.state
        const date = {
            sdate: params.sdate,
            edate: params.edate,
        }
        this.setState({ loading: true })
        await getTutorsPayments(date)
        this.setState({ loading: false, hasChanged: false })
    }

    handleReload() {
        this.componentDidMount()
    }

    handleChange(event) {
        const { target } = event
        const params = { ...this.state.params }
        params[target.name] = target.value
        params.sdate = `${params.syear}-${getMonthNumber(params.smonth)}-01`
        params.edate = `${params.eyear}-${getMonthNumber(params.emonth)}-01`
        this.setState({ params, hasChanged: true })
    }

    renderMonths() {
        const months = moment.months()
        return months.map((month) => (
            <MenuItem value={month} key={month}>
                {month}
            </MenuItem>
        ))
    }

    renderYears() {
        const startYear = 2020
        const actualYear = parseInt(moment(new Date()).format("YYYY"), 10)
        const finalYear = actualYear + 2
        const years = []
        for (let i = startYear; i < finalYear; i += 1) {
            years.push(i)
        }
        return years.map((year) => (
            <MenuItem value={year} key={year}>
                {year}
            </MenuItem>
        ))
    }

    handleGoto(value) {
        return () => {
            const { handleGoto } = this.props
            handleGoto(value)
        }
    }

    renderHeader() {
        const { params, loading, hasChanged } = this.state
        const { classes } = this.props
        return (
            <>
                <div className={classes.inline}>
                    <FormControl style={{ margin: "6px 6px", minWidth: 229 }}>
                        <InputLabel shrink>Mes desde</InputLabel>
                        <Select
                            disabled={loading}
                            value={moment(params.sdate).format("MMMM")}
                            onChange={this.handleChange}
                            name="smonth"
                        >
                            {this.renderMonths()}
                        </Select>
                    </FormControl>

                    <FormControl style={{ margin: "6px 6px", minWidth: 100 }}>
                        <InputLabel shrink>Año desde</InputLabel>
                        <Select
                            disabled={loading}
                            value={moment(params.sdate).format("YYYY")}
                            onChange={this.handleChange}
                            name="syear"
                        >
                            {this.renderYears()}
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.inline}>
                    <FormControl style={{ margin: "6px 6px", minWidth: 229 }}>
                        <InputLabel shrink>Mes hasta</InputLabel>
                        <Select
                            disabled={loading}
                            value={moment(params.edate).format("MMMM")}
                            onChange={this.handleChange}
                            name="emonth"
                        >
                            {this.renderMonths()}
                        </Select>
                    </FormControl>

                    <FormControl style={{ margin: "6px 6px", minWidth: 100 }}>
                        <InputLabel shrink>Año hasta</InputLabel>
                        <Select
                            disabled={loading}
                            value={moment(params.edate).format("YYYY")}
                            onChange={this.handleChange}
                            name="eyear"
                        >
                            {this.renderYears()}
                        </Select>
                    </FormControl>
                </div>
                {hasChanged &&
                    <Button
                        size="small"
                        style={{ margin: 6 }}
                        onClick={this.handleReload}
                        color="primary"
                        variant="outlined"
                    >
                        Filtrar
                    </Button>
                }
            </>
        )
    }

    render() {
        const { data, level, classes, actions } = this.props
        const { params, loading } = this.state
        const date = {
            sdate: params.sdate,
            edate: params.edate,
        }
        const disabled =
            moment(params.edate).month() - moment(params.sdate).month() > 1
        return (
            <>
                <div className={classes.title}>
                    <Typography variant="h2">
                        Tabla de Sueldos Pendientes
                    </Typography>
                    {level === "admin" && (
                        <div className={classes.flex}>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                style={{ marginRight: 12 }}
                                disabled={disabled}
                                onClick={() => {
                                    this.props.payTutors(date)
                                }}
                            >
                                Marcar Sueldos como Pagados
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() =>
                                    download("tutors", "Tutores", date)
                                }
                                size="small"
                            >
                                Exportar Tabla sueldos
                            </Button>
                        </div>
                    )}
                </div>
                <Divider />
                {this.renderHeader()}
                {loading ? <LoaderSpinner /> :
                    <GeneralTable
                        data={data}
                        info={paymentsInfo}
                        actions={actions}
                        open
                    />
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    tutor: state.tutor,
})

const mapDispatchToProps = (dispatch) => ({
    getTutorsPayments: (date) => dispatch(getTutorsPayments(date)),
    payTutors: (date) => dispatch(payTutorsAction(date)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(style)(PaymentTable))
