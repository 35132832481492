import React, { Component } from "react";
import {
    withStyles,
    Typography,
    IconButton,
    Checkbox,
} from "@material-ui/core";
import {
    formatDateMobile,
    formatMoney,
    autobind,
    formatHour,
} from "../../Utils/functions";
import Conditional from "../../Shared/Conditional";
import { KeyboardArrowDown } from "@material-ui/icons";

const style = (theme) => ({
    container: {
        padding: 12,
        background: "#dce9f2",
        margin: "6px 0",
        borderRadius: 5,
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    row1: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "6px 0",
    },
});

class ResponsiveLesson extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
        autobind(ResponsiveLesson, this);
    }

    handleOpen() {
        this.setState({ open: !this.state.open });
    }
    renderActions() {
        const { actions } = this.props;
        console.log(actions);
    }
    renderFields() {
        const { data, classes } = this.props;
        return (
            <>
                <div className={classes.row1}>
                    <Typography variant="subtitle2">Tutor:</Typography>
                    <Typography variant="subtitle2">
                        {data.tutor.name}
                    </Typography>
                </div>
                <div className={classes.row1}>
                    <Typography variant="subtitle2">Apoderado:</Typography>
                    <Typography variant="subtitle2">
                        {data.agent.name}
                    </Typography>
                </div>
                <div className={classes.row1}>
                    <Typography variant="subtitle2">Estudiante:</Typography>
                    <Typography variant="subtitle2">
                        {data.student.name}
                    </Typography>
                </div>
                <div className={classes.row1}>
                    <Typography variant="subtitle2">Monto:</Typography>
                    <Typography variant="subtitle2">
                        {formatMoney(data.payment)}
                    </Typography>
                </div>
                <div className={classes.row1}>
                    <Typography variant="subtitle2">Duración:</Typography>
                    <Typography variant="subtitle2">
                        {formatHour(data.duration)}
                    </Typography>
                </div>
            </>
        );
    }

    renderCheckbox() {
        const { data, handleSelect, selected } = this.props;
        return (
            <Checkbox
                color="primary"
                onClick={handleSelect(data.id)}
                checked={selected.indexOf(data.id) !== -1}
            />
        );
    }
    render() {
        const { open } = this.state;
        const { classes, data } = this.props;
        return (
            <div className={classes.container}>
                <div className={classes.fields}>
                    <div className={classes.row}>
                        <Typography variant="subtitle2">
                            {formatDateMobile(data.date)}
                        </Typography>
                        <Typography variant="subtitle2">
                            {data.subject.name}
                        </Typography>
                        <IconButton size="small" onClick={this.handleOpen}>
                            <KeyboardArrowDown />
                        </IconButton>
                    </div>
                </div>
                <Conditional condition={open}>
                    {this.renderFields()}
                </Conditional>
            </div>
        );
    }
}

ResponsiveLesson.propTypes = {};

ResponsiveLesson.defaultProps = {
    actions: [],
    handleSelect: () => {},
    selected: [],
};

export default withStyles(style)(ResponsiveLesson);
