import React, { Component } from 'react'
import { Button, withStyles } from '@material-ui/core'
import Transition from '../../../Shared/Transition'
import GeneralForm from '../../../Shared/GeneralForm'
import { formInfo } from '../info'
import { autobind } from '../../../Utils/functions'
import { getRequired, validate } from '../../../Utils/validations'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'

const style = () => ({
  button: {
    position: 'absolute',
    right: 12,
    top: 92
  }
})
class NewAgent extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      open: false
    }

    autobind(NewAgent, this)
  }

  handleOpen() {
    this.setState({ open: !this.state.open })
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleCreate() {
    const { params } = this.state
    const { enqueueSnackbar, onCreate } = this.props
    const validations = getRequired(formInfo)
    if (validate(validations, params, enqueueSnackbar)) {
      const body = { ...params }
      onCreate(body, enqueueSnackbar)
      this.setState({ params: {}, open: false })
    }
  }

  setOptions(info) {
    const result = [...info]
    const { school, bank } = this.props
    result.forEach((item, index) => {
      if (item.type === "select") {
        switch (item.name) {
          case "school_id":
            result[index].options = school.all || []
            break
          case "bank_id":
            result[index].options = bank.all || []
            break
          default:
            break
        }
      }
    })
    return result
  }

  render() {
    const { classes } = this.props
    const { params, open } = this.state
    return (
      <div className={classes.container}>
        <Button
          color="primary"
          variant="outlined"
          onClick={this.handleOpen}
          size="small"
          className={classes.button}
        >
          Nuevo Apoderado
        </Button>
        <Transition on={open}>
          <div style={{ marginBottom: 24 }}>
            <GeneralForm
              info={this.setOptions(formInfo)}
              params={params}
              handleChange={this.handleChange}
              title="Nuevo Apoderado"
              create={this.handleCreate}
              submit="Agregar apoderado"
            />
          </div>
        </Transition>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  back: state.bank,
  school: state.school
})

export default connect(mapStateToProps)(withSnackbar(withStyles(style)(NewAgent)))