import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import { SnackbarProvider } from "notistack";
import { MuiThemeProvider } from "@material-ui/core";
import theme from "./theme";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import store from "./store";
import CacheBuster from "./CacheBuster";

const persistor = persistStore(store);

ReactDOM.render(
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MuiThemeProvider theme={theme}>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <BrowserRouter>
                        <SnackbarProvider
                            disableWindowBlurListener
                            autoHideDuration={2000}
                            maxSnack={5}
                        >
                            <CacheBuster>
                                {({
                                    loading,
                                    isLatestVersion,
                                    refreshCacheAndReload
                                }) => {
                                    if (loading) {
                                        return null;
                                    }
                                    if (!loading && !isLatestVersion) {
                                        refreshCacheAndReload();
                                    }
                                    return <App />;
                                }}
                            </CacheBuster>
                        </SnackbarProvider>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </MuiThemeProvider>
    </MuiPickersUtilsProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
