import React, { Component } from "react"
import { Typography, withStyles, Button } from "@material-ui/core"

const style = (theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
        position: "relative",
    },
    button: {
        borderRadius: 0,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        width: 100,
    },
    active: {
        background: theme.palette.primary.lighter,
        color: theme.palette.primary.dark,
    },
    tabContainer: {
        background: theme.palette.primary.lighter,
        minHeight: "70vh",
        position: "relative",
    },
})

class ViewTabs extends Component {
    renderTabs() {
        const { tabs } = this.props
        return tabs.map((tab, index) => {
            const { classes, selected, handleSelect } = this.props
            const isSelected = index === selected ? classes.active : ""
            return (
                <Button
                    className={`${classes.button} ${isSelected}`}
                    color="primary"
                    onClick={handleSelect(index)}
                    key={index}
                >
                    <Typography variant="subtitle1" className={isSelected}>
                        {tab}
                    </Typography>
                </Button>
            )
        })
    }
    render() {
        const { classes, selected } = this.props
        return (
            <>
                <div className={classes.container}>{this.renderTabs()}</div>
                <div className={classes.tabContainer}>
                    {this.props.children[selected]}
                </div>
            </>
        )
    }
}

ViewTabs.propTypes = {}

ViewTabs.defaultProps = {
    Tabs: [],
}

export default withStyles(style)(ViewTabs)
