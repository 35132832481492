import React, { Component } from "react"
import { Typography, Divider } from "@material-ui/core"
import GeneralTable from "../../Shared/GeneralTable"
import { tableInfo } from "./tutorInfo"
import { getAllTutors } from "../../Actions/TutorActions"
import { connect } from "react-redux"

class All extends Component {
    render() {
        const { actions, tutors } = this.props
        return (
            <>
                <Typography variant="h2" style={{ padding: "12px 0 0 12px" }}>
                    Tabla de Tutores
                </Typography>
                <Divider />
                <GeneralTable
                    data={tutors}
                    info={tableInfo}
                    name="all"
                    actions={actions}
                    open
                />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    tutor: state.tutor,
})

const mapDispatchToProps = (dispatch) => ({
    getAllTutors: (user) => dispatch(getAllTutors(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(All)
