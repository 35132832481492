import { createSnackbar } from "../Utils/snackbars";
import instance from "../Utils/Instance";

export const getAllBanks = () => {
    const REQUEST = instance({
        method: "get",
        url: `banks`,
    });
    return {
        type: "getAllBanks",
        payload: REQUEST,
    };
};

export const createBank = (body, snackbar) => {
    const REQUEST = instance({
        method: "post",
        url: `banks`,
        data: body,
    });
    createSnackbar(REQUEST, snackbar);
    return {
        type: "createBank",
        payload: REQUEST,
    };
};

export const deleteBank = (id, snackbar) => {
    const REQUEST = instance({
        method: "delete",
        url: `bank/${id}`,
    });
    createSnackbar(REQUEST, snackbar);
    return {
        type: "deleteBank",
        payload: REQUEST,
    };
};

export const bankDispatcher = (dispatch) => ({
    getAllBanks: () => dispatch(getAllBanks()),
    createBank: (body, snackbar) => dispatch(createBank(body, snackbar)),
    deleteBank: (id, snackbar) => dispatch(deleteBank(id, snackbar)),
});
