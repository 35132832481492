import React, { Component } from "react";
import { Paper, Tabs, Tab } from "@material-ui/core";
import AgentDash from "./AgentDash";
import TutorDash from "./TutorDash";
import { withRouter } from "react-router-dom";

class SubInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            option: 0,
        };

        this.handleChange = this.handleChange.bind(this);
        this.gotoAgent = this.gotoAgent.bind(this);
        this.gotoTutor = this.gotoTutor.bind(this);
    }

    handleChange(event, value) {
        this.setState({ option: value });
    }

    gotoAgent(id) {
        this.props.history.push("/agent/" + id);
    }

    gotoTutor(id) {
        this.props.history.push("/tutor/" + id);
    }

    render() {
        const { option } = this.state;
        const { agentsData, tutorsData } = this.props;
        return (
            <>
                <Paper
                    style={{
                        width: "fit-content",
                        margin: "auto",
                        marginBottom: 24,
                    }}
                >
                    <Tabs value={option} onChange={this.handleChange}>
                        <Tab value={0} label="Apoderados" />
                        <Tab value={1} label="Tutores" />
                    </Tabs>
                </Paper>
                <div
                    style={{
                        width: "50%",
                        margin: "auto",
                        background: "#DCE2F2",
                        padding: 12,
                        borderRadius: 12,
                    }}
                >
                    {option === 0 ? (
                        <AgentDash
                            data={agentsData}
                            goto={this.gotoAgent}
                            addSimulation={this.props.addSimulation}
                            simulation_ids={this.props.simulation_ids}
                            level={this.props.level}
                        />
                    ) : (
                        <TutorDash data={tutorsData} goto={this.gotoTutor} />
                    )}
                </div>
            </>
        );
    }
}

export default withRouter(SubInfo);
