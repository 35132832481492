import React, { Component } from "react"
import { withStyles, Typography, IconButton, } from "@material-ui/core"
import { connect } from "react-redux"
import { createAgent, selectAgent, } from "../../../Actions/AgentActions"
import { withSnackbar } from "notistack"
import { getAllSchools } from "../../../Actions/SchoolsActions"
import { getAllBanks } from "../../../Actions/BankAction"
import { ExitToApp } from "@material-ui/icons"
import { autobind } from "../../../Utils/functions"
import All from "./All"
import Payed from "./Payed"
import Defaulters from "./Defaulters"
import ViewTabs from "../../../Shared/ViewTabs"
import actioncable from "actioncable"
import NewAgent from "./NewAgent"

const style = () => ({
    container: {
        padding: 12,
    },
    title: {
        padding: "12px 0 0 12px",
    },
    formContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: 24,
    },
    flexLine: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    dialog: {
        padding: 24,
    },
    loader: {
        position: "relative",
        height: 80,
        marginTop: 12,
    }
})

class Agents extends Component {
    constructor() {
        super()
        this.state = {
            selected: 0,
            sending_info: {},
        }

        autobind(Agents, this)
    }
    componentDidMount() {
        const { getAllBanks, getAllSchools } = this.props
        this.cable = actioncable.createConsumer(
            `${process.env.REACT_APP_CHANNEL_URL}/cable`
        )
        this.channel = this.cable.subscriptions.create(
            { channel: "MailsChannel" },
            {
                connected: () => { console.log("connected!") },
                disconnected: () => { console.log("disconnected!") },
                received: (data) => { this.handleReceive(data) },
            }
        )
        getAllBanks()
        getAllSchools()
    }

    handleReceive(data) {
        this.setState({ sending_info: data.info })
    }

    handleOpenProfile(row) {
        return () => {
            this.props.selectAgent(row.id)
            this.props.history.push("/agent/" + row.id)
        }
    }

    handleSelect(value) {
        return () => {
            this.setState({ selected: value })
        }
    }

    render() {
        const { classes, createAgent } = this.props
        const { selected, sending_info } = this.state
        const actions = [
            {
                name: "Ver Perfil",
                icon: ({ row }) => (
                    <IconButton onClick={this.handleOpenProfile(row)} color="primary">
                        <ExitToApp />
                    </IconButton>
                ),
            },
        ]
        return (
            <div className={classes.container}>
                <Typography variant="h1" style={{ padding: 12 }}>
                    Apoderados
                </Typography>
                <NewAgent onCreate={createAgent} />
                <ViewTabs
                    selected={selected}
                    handleSelect={this.handleSelect}
                    tabs={["TODOS", "PAGADOS", "MOROSOS"]}
                >
                    <All actions={actions} />
                    <Payed actions={actions} />
                    <Defaulters
                        actions={actions}
                        mails_info={sending_info}
                    />
                </ViewTabs>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    agent: state.agent,
    bank: state.bank,
    school: state.school,
})

const mapDispatchToProps = (dispatch) => ({
    createAgent: (body, snackbar) => dispatch(createAgent(body, snackbar)),
    getAllBanks: () => dispatch(getAllBanks()),
    getAllSchools: () => dispatch(getAllSchools()),
    selectAgent: (id) => dispatch(selectAgent(id)),
})

export default withSnackbar(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Agents))
)
