import React, { Component } from "react";
import { Paper, Typography } from "@material-ui/core";

export default class WelcomeMessage extends Component {
    render() {
        return (
            <div style={{ gridArea: "classes", marginTop: 24 }}>
                <Paper
                    style={{
                        width: "60%",
                        margin: "auto",
                        padding: 24,
                    }}
                >
                    <Typography variant="h2" style={{ marginBottom: 12 }}>
                        Bienvenido!
                    </Typography>
                    <Typography variant="body2">
                        Por favor rellena la información necesaria para
                        completar el proceso de ingreso y comenzar a utilizar la
                        aplicación. Recuerda que la informacion que ingreses
                        será considerara como correcta y se utilizará para
                        enviar correos y realizar transacciones. Si te equivocas
                        no te preocupes siempre puedes volver a editar la
                        información :)
                    </Typography>
                </Paper>
            </div>
        );
    }
}
