import { combineReducers } from "redux";

import LoginReducer from "./LoginReducer";
import SchoolReducer from "./SchoolReducers";
import CoordinatorReducer from "./CoordinatorReducer";
import TutorReducer from "./TutorReducer";
import { BankReducer } from "./BankReducer";
import { AgentReducer } from "./AgentReducer";
import { LessonReducer } from "./LessonReducer";
import { subjectTypeReducer } from "./SubjectTypesReducer";
import { RegisterReducer } from "./RegisterReducer";

const reducers = combineReducers({
    account: LoginReducer,
    school: SchoolReducer,
    coordinator: CoordinatorReducer,
    tutor: TutorReducer,
    bank: BankReducer,
    agent: AgentReducer,
    lesson: LessonReducer,
    types: subjectTypeReducer,
    register: RegisterReducer,
});

export default reducers;
