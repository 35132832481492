import React, { Component } from "react";
import { withStyles, Typography, Divider } from "@material-ui/core";
import { defaultStyle } from "../../Utils/staticStyles";
import GeneralTable from "../../Shared/GeneralTable";
import { schoolDispatcher } from "../../Actions/SchoolsActions";
import { connect } from "react-redux";
import { combineDispatchers, formatMoney } from "../../Utils/functions";
import { subjectDispatcher } from "../../Actions/Subjetc";
import DiscountCard from "./DiscountCard";

class Subjects extends Component {
    componentDidMount() {
        const { selectSchool, getSubjectTypes, getDiscounts } = this.props;
        const { user } = this.props.account;
        const { school } = user;
        getSubjectTypes();
        selectSchool(school.id);
        getDiscounts(school.id);
    }

    renderDiscounts() {
        const { school } = this.props;
        const discounts = school.discounts || [];
        return discounts.map((discount) => (
            <DiscountCard discount={discount} disableDelete />
        ));
    }

    render() {
        const { classes, school, types } = this.props;
        const info = [
            { key: "name", label: "Nombre" },
            { key: "type", label: "Tipo de Clase" },
            { key: "cost", label: "Costo", format: formatMoney },
            { key: "payment", label: "Pago Tutor", format: formatMoney },
        ];
        const data = school.selected.subjects || [];
        let withTypeData = [];
        if (data.length > 0) {
            withTypeData = data.map((element) => {
                const ownType = types.all.find(
                    (t) => t.id === element.subject_type_id
                );
                return { ...element, type: ownType.name };
            });
        }
        return (
            <div className={classes.container}>
                <Typography variant="h1" className={classes.title}>
                    Tarifas
                </Typography>
                <Typography variant="h2">Tabla de Tarifas</Typography>
                <Divider />
                <div className={classes.tableContainer}>
                    <GeneralTable
                        data={withTypeData}
                        info={info}
                        goto={this.handleSelect}
                        delete={this.handleDelete}
                        name="schools"
                        open
                    />
                </div>
                <Typography variant="h2">Descuentos</Typography>
                <Divider />
                {this.renderDiscounts()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    account: state.account,
    school: state.school,
    types: state.types,
});

const mapDispatchToProps = (dispatch) =>
    combineDispatchers(dispatch, schoolDispatcher, subjectDispatcher);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(defaultStyle)(Subjects));
