import React, { Component } from 'react'
import { Button, FormControl, InputLabel, MenuItem, Paper, Select, Typography, withStyles } from '@material-ui/core'
import { autobind, download } from '../../Utils/functions'
import moment from 'moment'
import LoaderSpinner from '../../Shared/Loader/LoaderSpinner'

const style = () => ({
  container: {
    padding: 12,
  },
  title: {
    margin: '12px 0'
  },
  inline: {
    display: 'flex',
    gap: '12px',
  },
  actionContainer: {
    maxWidth: 600,
    padding: 12,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  button: {
    textAlign: 'end',
    marginTop: 24
  },
  loader: {
    height: 150,
    position: 'relative'
  }
})

function getMonthNumber(monthName) {
  return moment.months().indexOf(monthName) + 1
}

class Bills extends Component {
  constructor() {
    super()
    this.state = {
      params: {
        sdate: moment(new Date()).format("YYYY-MM-[01]"),
        edate: moment(new Date())
          .add(1, "month")
          .format("YYYY-MM-[01]"),
        smonth: moment(new Date()).format("MMMM"),
        emonth: moment(new Date()).add(1, "month").format("MMMM"),
        syear: moment(new Date()).format("YYYY"),
        eyear: moment(new Date()).add(1, "month").format("YYYY"),
      },
      loading: false
    }
    autobind(Bills, this)
  }

  handleChange(event) {
    const { target } = event
    const params = { ...this.state.params }
    params[target.name] = target.value
    params.sdate = `${params.syear}-${getMonthNumber(params.smonth)}-01`
    params.edate = `${params.eyear}-${getMonthNumber(params.emonth)}-01`
    this.setState({ params })
  }

  renderYears() {
    const startYear = 2020
    const actualYear = parseInt(moment(new Date()).format("YYYY"), 10)
    const finalYear = actualYear + 2
    const years = []
    for (let i = startYear; i < finalYear; i += 1) {
      years.push(i)
    }
    return years.map((year) => (
      <MenuItem value={year} key={year}>
        {year}
      </MenuItem>
    ))
  }


  renderMonths() {
    const months = moment.months()
    return months.map((month) => (
      <MenuItem value={month} key={month}>
        {month}
      </MenuItem>
    ))
  }

  renderHeader() {
    const { params } = this.state
    const { classes } = this.props
    return (
      <>
        <div className={classes.inline}>
          <FormControl style={{ margin: "6px 0px", minWidth: 229 }}>
            <InputLabel shrink>Mes desde</InputLabel>
            <Select
              value={moment(params.sdate).format("MMMM")}
              onChange={this.handleChange}
              name="smonth"
            >
              {this.renderMonths()}
            </Select>
          </FormControl>

          <FormControl style={{ margin: "6px 0px", minWidth: 100 }}>
            <InputLabel shrink>Año desde</InputLabel>
            <Select
              value={moment(params.sdate).format("YYYY")}
              onChange={this.handleChange}
              name="syear"
            >
              {this.renderYears()}
            </Select>
          </FormControl>
        </div>
        <div className={classes.inline}>
          <FormControl style={{ margin: "6px 0px", minWidth: 229 }}>
            <InputLabel shrink>Mes hasta</InputLabel>
            <Select
              value={moment(params.edate).format("MMMM")}
              onChange={this.handleChange}
              name="emonth"
            >
              {this.renderMonths()}
            </Select>
          </FormControl>

          <FormControl style={{ margin: "6px 0px", minWidth: 100 }}>
            <InputLabel shrink>Año hasta</InputLabel>
            <Select
              value={moment(params.edate).format("YYYY")}
              onChange={this.handleChange}
              name="eyear"
            >
              {this.renderYears()}
            </Select>
          </FormControl>
        </div>
      </>
    )
  }

  async handleDownload() {
    const { params } = this.state
    const date = {
      sdate: params.sdate,
      edate: params.edate,
    }
    this.setState({ loading: true })
    console.log("start")
    await download('bills', 'Boletas', date)
    console.log("end")
    this.setState({ loading: false })
  }

  render() {
    const { classes } = this.props
    const { params, loading } = this.state
    const date = {
      sdate: params.sdate,
      edate: params.edate,
    }
    return (
      <div className={classes.container}>
        <div className={classes.title}>
          <Typography variant="h1">Boletas</Typography>
        </div>
        <Typography variant="caption">Seleccióne intervalo de fechas para descargar las boletas correspondientes</Typography>
        <Paper className={classes.actionContainer}>
          <Typography variant="subtitle1">Boletas</Typography>
          {this.renderHeader()}
          {loading ?
            <div className={classes.loader}>
              <LoaderSpinner />
            </div> :
            <div className={classes.button}>
              <Button color="primary" variant="outlined" size="small" onClick={this.handleDownload}>
                Descargar boletas
              </Button>
            </div>}
        </Paper>
      </div>
    )
  }
}

export default withStyles(style)(Bills)