import instance from "../Utils/Instance";
import { createSnackbar } from "../Utils/snackbars";

export const getLesson = (id) => {
    const REQUEST = instance({
        method: "get",
        url: `lesson/${id}`,
    });
    return {
        type: "GetLesson",
        payload: REQUEST,
    };
};

export const editLesson = (id, body) => {
    const REQUEST = instance({
        method: "put",
        url: `lesson/${id}`,
        data: body,
    });
    return {
        type: "EditLesson",
        payload: REQUEST,
    };
};

export const confirmLesson = (id) => {
    const REQUEST = instance({
        method: "post",
        url: `lesson/${id}/confirm`,
    });
    return {
        type: "ConfirmSomething",
        payload: REQUEST,
    };
};

export const payLesson = (id) => {
    const REQUEST = instance({
        method: "post",
        url: `lesson/${id}/pay`,
    });
    return {
        type: "createPayment",
        payload: REQUEST,
    };
};

export const markLesson = (id) => {
    const REQUEST = instance({
        method: "post",
        url: `lesson/${id}/mark`,
    });
    return {
        type: "createPayment",
        payload: REQUEST,
    };
};

export const payTransfer = (id) => {
    const REQUEST = instance({
        method: "post",
        url: `lesson/${id}/trasnfer`,
    });
    return {
        type: "ConfirmSomething",
        payload: REQUEST,
    };
};

export const confirmLessonPayment = (id) => {
    const REQUEST = instance({
        method: "post",
        url: `lesson/${id}/confirmpay`,
    });
    return {
        type: "ConfirmSomething",
        payload: REQUEST,
    };
};

export const getPendings = () => {
    const REQUEST = instance({
        method: "get",
        url: "lessons/pending",
    });
    return {
        type: "getPendings",
        payload: REQUEST,
    };
};

export const markAsConfirmed = (body, snackbar) => {
    const REQUEST = instance({
        method: "put",
        url: "lessons/markconfirm",
        data: body,
    });
    createSnackbar(REQUEST, snackbar);
    return {
        type: "getPendings",
        payload: REQUEST,
    };
};

export const unpayLesson = (id, snackbar) => {
    const REQUEST = instance({
        method: "post",
        url: `lessons/unpay/${id}`,
    });
    createSnackbar(REQUEST, snackbar);
    return {
        type: "getPendings",
        payload: REQUEST,
    };
};

export const lessonDispatcher = (dispatch) => ({
    getLesson: (id) => dispatch(getLesson(id)),
    editLesson: (id, body) => dispatch(editLesson(id, body)),
    confirmLesson: (id) => dispatch(confirmLesson(id)),
    payLesson: (id) => dispatch(payLesson(id)),
    confirmLessonPayment: (id) => dispatch(confirmLessonPayment(id)),
    getPendings: () => dispatch(getPendings()),
});
