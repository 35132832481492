import React, { Component } from "react";
import {
    withStyles,
    TableRow,
    TableCell,
    Table,
    TableHead,
    TableBody,
    IconButton,
} from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import { formatMoney } from "../../Utils/functions";

const style = () => ({
    cell: {
        padding: "12px 6px",
    },
    head: {
        padding: 6,
    },
});

class TutorDash extends Component {
    handleGoto(id) {
        return () => this.props.goto(id);
    }

    renderBody() {
        const { data, classes } = this.props;
        return data.map((element) => (
            <TableRow key={element.id}>
                <TableCell className={classes.cell}>{element.name}</TableCell>
                <TableCell
                    className={classes.cell}
                    style={{ textAlign: "right" }}
                >
                    {formatMoney(element.total)}
                </TableCell>
                <TableCell style={{ textAlign: "end" }}>
                    <IconButton
                        color="primary"
                        onClick={this.handleGoto(element.id)}
                    >
                        <ExitToApp />
                    </IconButton>
                </TableCell>
            </TableRow>
        ));
    }
    render() {
        const { classes } = this.props;
        return (
            <>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.head}>
                                Nombre
                            </TableCell>
                            <TableCell
                                className={classes.head}
                                style={{ textAlign: "right" }}
                            >
                                Monto a Pagar
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>{this.renderBody()}</TableBody>
                </Table>
            </>
        );
    }
}

export default withStyles(style)(TutorDash);
