import React, { Component } from 'react'
import { Button, Divider, Typography, withStyles } from '@material-ui/core'
import { generatePaymentLink, getAgentPayment } from '../../API/agents'
import moment from 'moment'
import GeneralTable from '../../Shared/GeneralTable'
import { autobind, formatHour, formatMoney } from '../../Utils/functions'
import { createSnackbar } from '../../Utils/snackbars'
import { CheckCircleOutline } from '@material-ui/icons'

const style = () => ({
  container: {
    padding: 24,
    maxWidth: 800,
    margin: 'auto',
    height: '100vh'
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '3px 0'
  }
})

const tableInfo = [
  { key: "date", label: "Fecha" },
  { key: "tutor", label: "Tutor" },
  { key: "duration", label: "Duración", format: formatHour },
  { key: "cost", label: "Monto", format: formatMoney, align: "right" },
]

class OnlinePayment extends Component {
  constructor() {
    super()
    this.state = {
      agent: {},
      sdate: moment().format("DD [de] MMM [del] YYYY"),
      edate: moment().format("DD [de] MMM [del] YYYY"),
      lessons: [],
      amount: 0,
      total: 0,
      credits: 0
    }
    this.clicked = false
    autobind(OnlinePayment, this)
  }

  async handleGenerateLink() {
    const { agent, sdate, edate } = this.state
    const body = { id: agent.id, sdate, edate }
    if (!this.clicked) {
      this.clicked = true
      const response = await generatePaymentLink(body)
      const url = response?.data?.url?.payment_url
      if (!url) return alert("Algo salió mal por favor avisar a administración")
      window.location.href = url
    }
  }

  async componentDidMount() {
    const { match: { params: { id } } } = this.props
    const url = new URL(window.location.href)
    const edate = url.searchParams.get("edate")
    const sdate = url.searchParams.get("sdate")
    const response = await getAgentPayment({ id, edate, sdate })
    console.log(response)
    const { agent, lessons, amount, credits, total } = response.data
    this.setState({ agent, sdate, edate, lessons, amount, credits, total })
  }

  render() {
    const { classes } = this.props
    const { agent, sdate, edate, lessons, amount, credits, total } = this.state
    return (
      <div className={classes.container}>
        <img src="/logo-rtu.png" alt="logo" width="200px" />
        <Typography variant="h1" style={{ marginBottom: 12 }}>Pago de clases</Typography>
        <Typography variant="h2" style={{ marginBottom: 24 }}>{agent?.name || "Apoderado"}</Typography>
        <Typography variant="subtitle1">Clases desde {moment(sdate).format("DD [de] MMM [del] YYYY")} - hasta {moment(edate).format("DD [de] MMM [del] YYYY")}</Typography>
        {lessons.length > 0 ?
          <>
            <GeneralTable open data={lessons} info={tableInfo} />
            <div style={{ marginTop: 12, display: 'flex', justifyContent: 'end' }}>
              <div style={{ minWidth: 200, background: '#dce9f2', padding: 12, borderRadius: 8 }}>
                <div className={classes.inline}>
                  <Typography variant="body1">Subtotal:</Typography>
                  <Typography variant="body1">{formatMoney(total)}</Typography>
                </div>
                <div className={classes.inline}>
                  <Typography variant="body1">Créditos:</Typography>
                  <Typography variant="body1">{formatMoney(credits)}</Typography>
                </div>
                <Divider />
                <div className={classes.inline}>
                  <Typography variant="body1">Total:</Typography>
                  <Typography variant="body1">{formatMoney(amount)}</Typography>
                </div>
              </div>
            </div>
            <div style={{ marginTop: 12, textAlign: 'end' }}>
              <Button color="primary" variant="contained" onClick={this.handleGenerateLink} >Pagar por WebPay</Button>
            </div>
          </>
          :
          <>
            <div style={{ display: 'flex', minWidth: 200, background: '#dce9f2', padding: 12, borderRadius: 8, marginTop: 24 }}>
              <CheckCircleOutline style={{ color: 'green', marginRight: 12 }} />
              <Typography variant="subtitle1">Período pagado con éxito! Muchas gracias por preferirnos.</Typography>
            </div>
          </>
        }
      </div>
    )
  }
}

export default withStyles(style)(OnlinePayment)