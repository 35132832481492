import React, { Component } from "react";
import { connect } from "react-redux";
import {
    createDiscount,
    getDiscounts,
    deleteDiscount,
} from "../../Actions/SchoolsActions";
import { Typography, withStyles, IconButton } from "@material-ui/core";
import Loader from "../../Shared/Loader/Loader";
import propTypes from "prop-types";
import DiscountCard from "./DiscountCard";
import { autobind } from "../../Utils/functions";
import {
    AddCircle,
    AddCircleOutline,
    KeyboardArrowDown,
} from "@material-ui/icons";
import GeneralForm from "../../Shared/GeneralForm";
import Transition from "../../Shared/Transition";
import { getRequired, validate } from "../../Utils/validations";
import { discountForm } from "./schoolInfo";
import { withSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import Conditional from "../../Shared/Conditional";
import moment from "moment";

const style = () => ({
    grid: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: 12,
        marginBottom: 12,
    },
    inline: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
});

class DiscountContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            show: false,
            params: {
                start_date: moment(new Date()).format("YYYY-MM-DD"),
                end_date: moment(new Date())
                    .add(1, "months")
                    .format("YYYY-MM-DD"),
            },
        };
        autobind(DiscountContainer, this);
    }

    handleDestroy(id) {
        return () => {
            const { deleteDiscount, enqueueSnackbar } = this.props;
            deleteDiscount(id, enqueueSnackbar);
        };
    }

    handleOpen() {
        this.setState({ open: !this.state.open });
    }

    handleShow() {
        this.setState({ show: !this.state.show });
    }

    handleChange(event) {
        const target = event.target;
        const params = { ...this.state.params };
        params[target.name] = target.value;
        this.setState({ params });
    }

    handleCreate() {
        const { params } = this.state;
        const validations = getRequired(discountForm);
        if (validate(validations, params, this.props.enqueueSnackbar)) {
            const body = { ...params, school_id: this.props.match.params.id };
            this.props.createDiscount(body, this.props.enqueueSnackbar);
            this.setState({ params: {}, form: false });
        }
    }

    renderDiscounts() {
        const { school } = this.props;
        const discounts = school.discounts || [];
        return discounts.map((discount, index) => (
            <DiscountCard
                key={index}
                discount={discount}
                handleDelete={this.handleDestroy}
            />
        ));
    }

    setOptions(info) {
        const result = [...info];
        const { school } = this.props;
        result.forEach((item, index) => {
            if (item.type === "select") {
                switch (item.name) {
                    case "tutor_id":
                        result[index].options = school.tutors;
                        break;
                    case "agent_id":
                        result[index].options = school.agents;
                        break;
                    default:
                        break;
                }
            }
        });
        return result;
    }

    render() {
        const { school, getDiscounts, classes } = this.props;
        const { open, params, show } = this.state;
        const id = school.selected.id;
        return (
            <div>
                <div className={classes.inline}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                            variant="subtitle1"
                            style={{ margin: "12px 24px 12px 0" }}
                        >
                            Descuentos
                        </Typography>
                        <IconButton size="small" onClick={this.handleShow}>
                            <KeyboardArrowDown />
                        </IconButton>
                    </div>
                    <IconButton color="primary" onClick={this.handleOpen}>
                        {open ? <AddCircleOutline /> : <AddCircle />}
                    </IconButton>
                </div>
                <div style={{ position: "relative" }}>
                    <Conditional condition={show}>
                        <Loader action={getDiscounts} params={[id]}>
                            <div className={classes.grid}>
                                {this.renderDiscounts()}
                            </div>
                        </Loader>
                    </Conditional>
                </div>

                <Transition on={open}>
                    <GeneralForm
                        params={params}
                        info={this.setOptions(discountForm)}
                        title="Nuevo Descuento"
                        grid="1fr"
                        handleChange={this.handleChange}
                        create={this.handleCreate}
                        submit="Crear Descuento"
                    />
                </Transition>
            </div>
        );
    }
}

DiscountContainer.propTypes = {
    school: propTypes.object.isRequired,
    getDiscounts: propTypes.func.isRequired,
};

DiscountContainer.defaultProps = {};

const mapStateToProps = (state) => ({
    school: state.school,
});

const mapDispatchToProps = (dispatch) => ({
    createDiscount: (body, snackbar) =>
        dispatch(createDiscount(body, snackbar)),
    getDiscounts: (id) => dispatch(getDiscounts(id)),
    deleteDiscount: (id, snackbar) => dispatch(deleteDiscount(id, snackbar)),
});

export default withRouter(
    withSnackbar(
        withStyles(style)(
            connect(mapStateToProps, mapDispatchToProps)(DiscountContainer)
        )
    )
);
