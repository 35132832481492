import { createSnackbar } from "../Utils/snackbars"
import instance from "../Utils/Instance"

export const getAllSchools = () => {
    const REQUEST = instance({
        method: "get",
        url: `schools`,
    })
    return {
        type: "getAllSchools",
        payload: REQUEST,
    }
}

export const createSchool = (body, snackbar) => {
    const REQUEST = instance({
        method: "post",
        url: `schools`,
        data: body,
    })
    createSnackbar(REQUEST, snackbar)
    return {
        type: "createSchool",
        payload: REQUEST,
    }
}

export const editSchool = (body, snackbar) => {
    const REQUEST = instance({
        method: "put",
        url: `school/${body.id}`,
        data: body,
    })
    createSnackbar(REQUEST, snackbar)
    return {
        type: "selectSchool",
        payload: REQUEST,
    }
}

export const deleteSchool = (id, snackbar) => {
    const REQUEST = instance({
        method: "delete",
        url: `school/${id}`,
    })
    createSnackbar(REQUEST, snackbar)
    return {
        type: "deleteSchool",
        payload: REQUEST,
    }
}

export const getSchoolTutors = (id) => {
    const REQUEST = instance({
        method: "get",
        url: `school/tutors/${id}`,
    })
    return {
        type: "getSchoolTutors",
        payload: REQUEST,
    }
}

export const getSchoolCoordinators = (id) => {
    const REQUEST = instance({
        method: "get",
        url: `school/coordinators/${id}`,
    })
    return {
        type: "getSchoolCoordinators",
        payload: REQUEST,
    }
}

export const getSchoolAgents = (id) => {
    const REQUEST = instance({
        method: "get",
        url: `school/agents/${id}`,
    })
    return {
        type: "getSchoolAgents",
        payload: REQUEST,
    }
}

export const getSchoolSubjects = (id) => {
    const REQUEST = instance({
        method: "get",
        url: `school/subjects/${id}`,
    })
    return {
        type: "getSchoolSubjects",
        payload: REQUEST,
    }
}

export const createSubject = (body, snackbar) => {
    const REQUEST = instance({
        method: "post",
        url: `subjects`,
        data: body,
    })
    createSnackbar(REQUEST, snackbar)
    return {
        type: "createSubject",
        payload: REQUEST,
    }
}

export const deleteSubject = (id, snackbar) => {
    const REQUEST = instance({
        method: "delete",
        url: `subject/${id}`,
    })
    createSnackbar(REQUEST, snackbar)
    return {
        type: "deleteSubject",
        payload: REQUEST,
    }
}

export const editSubject = (body, snackbar) => {
    const REQUEST = instance({
        method: "put",
        url: `subject/${body.id}`,
        data: body,
    })
    createSnackbar(REQUEST, snackbar)
    return {
        type: "editSubject",
        payload: REQUEST,
    }
}

export const selectSchool = (id) => {
    const REQUEST = instance({
        method: "get",
        url: `school/${id}`,
    })
    return {
        type: "selectSchool",
        payload: REQUEST,
    }
}

export const createDiscount = (body, snackbar) => {
    const REQUEST = instance({
        method: "post",
        url: "/discounts",
        data: body,
    })
    createSnackbar(REQUEST, snackbar)
    return {
        type: "getDiscounts",
        payload: REQUEST,
    }
}

export const getDiscounts = (id) => {
    const REQUEST = instance({
        method: "get",
        url: `schools/${id}/discounts/`,
    })
    return {
        type: "getDiscounts",
        payload: REQUEST,
    }
}

export const deleteDiscount = (id, snackbar) => {
    const REQUEST = instance({
        method: "delete",
        url: `/discounts/${id}`,
    })
    createSnackbar(REQUEST, snackbar)
    return {
        type: "getDiscounts",
        payload: REQUEST,
    }
}

export const hideSubjectAction = body => {
    const REQUEST = instance({
        method: 'put',
        url: 'school/subject',
        data: body
    })
    // createSnackbar(REQUEST, snackbar)
    return {
        type: "getSchoolSubjects",
        payload: REQUEST
    }
}

export const schoolDispatcher = (dispatch) => ({
    getAllSchools: () => dispatch(getAllSchools()),
    createSchool: (body, snackbar) => dispatch(createSchool(body, snackbar)),
    deleteSchool: (id, snackbar) => dispatch(deleteSchool(id, snackbar)),
    getSchoolTutors: (id) => dispatch(getSchoolTutors(id)),
    getSchoolCoordinators: (id) => dispatch(getSchoolCoordinators(id)),
    getSchoolAgents: (id) => dispatch(getSchoolAgents(id)),
    getSchoolSubjects: (id) => dispatch(getSchoolSubjects(id)),
    createSubject: (body, snackbar) => dispatch(createSubject(body, snackbar)),
    deleteSubject: (id, snackbar) => dispatch(deleteSubject(id, snackbar)),
    editSubject: (body, snackbar) => dispatch(editSubject(body, snackbar)),
    selectSchool: (id) => dispatch(selectSchool(id)),
    createDiscount: (body, snackbar) =>
        dispatch(createDiscount(body, snackbar)),
    getDiscounts: (id) => dispatch(getDiscounts(id)),
    hideSubjectAction: body => dispatch(hideSubjectAction(body))
})
