import React, { Component } from "react";
import { withStyles, Typography } from "@material-ui/core";
import Conditional from "../Conditional";
import { getCellValue } from "../../Utils/functions";

const style = (theme) => ({
    container: {
        padding: 12,
        background: "#dce9f2",
        margin: "6px 0",
        borderRadius: 5,
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
});

class ResponsiveCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
        };
    }

    handleOpen() {
        this.setState({ open: !this.state.open });
    }
    renderActions() {
        const { actions } = this.props;
        console.log(actions);
    }
    renderFields() {
        const { info, data, classes } = this.props;
        console.log(info);
        console.log(data);
        const card = info.map((cell) => {
            const value = getCellValue(data, cell);
            return (
                <div className={classes.row}>
                    <Typography variant="subtitle2">{cell.label}:</Typography>
                    <Typography variant="subtitle2">{value}</Typography>
                </div>
            );
        });
        return card;
    }
    render() {
        const { open } = this.state;
        const { classes, info, data, actions, ableSelect } = this.props;
        return (
            <div className={classes.container}>
                <div className={classes.fields}>{this.renderFields()}</div>
                <Conditional condition={open}>
                    <div className={classes.actions}>
                        {this.renderActions()}
                    </div>
                </Conditional>
            </div>
        );
    }
}

export default withStyles(style)(ResponsiveCard);
