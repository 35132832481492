import { createSnackbar } from "../Utils/snackbars"
import instance from "../Utils/Instance"

export const createAgent = (body, snackbar) => {
    const REQUEST = instance({
        method: "post",
        url: `agents`,
        data: body,
    })
    createSnackbar(REQUEST, snackbar)
    return {
        type: "createAgent",
        payload: REQUEST,
    }
}

export const createCreditAction = body => {
    const REQUEST = instance({
        method: 'post',
        url: 'agents/credit',
        data: body
    })
    return {
        type: "createCredit",
        payload: REQUEST
    }
}

export const createSchoolAgent = (body, snackbar) => {
    const REQUEST = instance({
        method: "post",
        url: `agents/school`,
        data: body,
    })
    createSnackbar(REQUEST, snackbar)
    return {
        type: "createAgent",
        payload: REQUEST,
    }
}

export const deleteAgent = (id, snackbar) => {
    const REQUEST = instance({
        method: "delete",
        url: `agent/${id}`,
    })
    createSnackbar(REQUEST, snackbar)
    return {
        type: "deleteAgent",
        payload: REQUEST,
    }
}

export const deleteSchoolAgent = (id, snackbar) => {
    const REQUEST = instance({
        method: "delete",
        url: `agent/${id}/school`,
    })
    createSnackbar(REQUEST, snackbar)
    return {
        type: "deleteSchoolAgent",
        payload: REQUEST,
    }
}

export const getAgents = (date) => {
    const REQUEST = instance({
        method: "get",
        url: "agents",
        headers: { ...date },
    })
    return {
        type: "getAllAgents",
        payload: REQUEST,
    }
}

export const getDefaulters = (date) => {
    const REQUEST = instance({
        method: "get",
        url: "agents/defaulters",
        headers: { ...date },
    })
    return {
        type: "getAllDefaulters",
        payload: REQUEST,
    }
}

export const getPayed = (date) => {
    const REQUEST = instance({
        method: "get",
        url: "agents/payed",
        headers: { ...date },
    })
    return {
        type: "getAllPayed",
        payload: REQUEST,
    }
}

export const selectAgent = (id, date) => {
    const REQUEST = instance({
        method: "get",
        url: `agent/${id}`,
        headers: date,
    })
    return {
        type: "selectAgent",
        payload: REQUEST,
    }
}

export const getAgentLessons = (id) => {
    const REQUEST = instance({
        method: "get",
        url: `agent/lessons/${id}`,
    })
    return {
        type: "getAgentLessons",
        payload: REQUEST,
    }
}

export const payMultiple = (ids) => {
    const REQUEST = instance({
        method: "post",
        url: "lessons/payall",
        data: ids,
    })
    return {
        type: "selectAgent",
        payload: REQUEST,
    }
}

export const useCredits = (ids, snackbar, date) => {
    const REQUEST = instance({
        method: "post",
        url: "lessons/usecredits",
        data: ids,
        headers: date,
    })
    createSnackbar(REQUEST, snackbar)
    return {
        type: "selectAgent",
        payload: REQUEST,
    }
}

export const createStudent = (body, snackbar, date) => {
    const REQUEST = instance({
        method: "post",
        url: `students`,
        data: body,
        headers: date,
    })
    createSnackbar(REQUEST, snackbar)
    return {
        type: "createStudent",
        payload: REQUEST,
    }
}

export const getAgentStudents = (id) => {
    const REQUEST = instance({
        method: "get",
        url: `agent/students/${id}`,
    })
    return {
        type: "getAgentStudents",
        payload: REQUEST,
    }
}

export const createComment = (body, snackbar, date) => {
    const REQUEST = instance({
        method: "post",
        url: `agents/comment`,
        data: body,
        headers: date,
    })
    createSnackbar(REQUEST, snackbar)
    return {
        type: "selectAgent",
        payload: REQUEST,
    }
}

export const editAgent = (body, snackbar, date) => {
    const REQUEST = instance({
        method: "put",
        url: `agent/${body.id}`,
        data: body,
        headers: date,
    })
    createSnackbar(REQUEST, snackbar)
    return {
        type: "selectAgent",
        payload: REQUEST,
    }
}

export const deleteStudent = (id, snackbar, date) => {
    const REQUEST = instance({
        method: "delete",
        url: `student/${id}`,
        headers: date,
    })
    createSnackbar(REQUEST, snackbar)
    return {
        type: "selectAgent",
        payload: REQUEST,
    }
}

export const markAsPayed = (body, snackbar, date) => {
    const REQUEST = instance({
        method: "put",
        url: "lessons/mark",
        headers: date,
        data: body,
    })
    createSnackbar(REQUEST, snackbar)
    return {
        type: "selectAgent",
        payload: REQUEST,
    }
}

export const sendDefaulterEmail = (body, snackbar, date) => {
    const REQUEST = instance({
        method: "post",
        url: `agents/reminder`,
        headers: body.date,
        data: body,
    })
    createSnackbar(REQUEST, snackbar)
    return {
        type: "sendEmail",
        payload: REQUEST,
    }
}

export const agentDispatcher = (dispatch) => ({
    createAgent: (body, snackbar) => dispatch(createAgent(body, snackbar)),
    createSchoolAgent: (body, snackbar) =>
        dispatch(createSchoolAgent(body, snackbar)),
    deleteAgent: (id, snackbar) => dispatch(deleteAgent(id, snackbar)),
    deleteSchoolAgent: (id, snackbar) =>
        dispatch(deleteSchoolAgent(id, snackbar)),
    getAgents: () => dispatch(getAgents()),
    selectAgent: (id, date) => dispatch(selectAgent(id, date)),
    getAgentLessons: (id) => dispatch(getAgentLessons(id)),
    createStudent: (body, snackbar, date) =>
        dispatch(createStudent(body, snackbar, date)),
    getAgentStudents: (id) => dispatch(getAgentStudents(id)),
    createComment: (body, snackbar, date) =>
        dispatch(createComment(body, snackbar, date)),
    editAgent: (body, snackbar, date) => dispatch(body, snackbar, date),
    deleteStudent: (id, snackbar, date) => dispatch(id, snackbar, date),
    markAsPayed: (body, snackbar, date) => markAsPayed(body, snackbar, date),
})
