import React, { Component } from "react"
import { withStyles, Typography, IconButton } from "@material-ui/core"
import Loader from "../../Shared/Loader/Loader"
import { deleteRegister, getAllRegisters } from "../../Actions/RegisterAction"
import { connect } from "react-redux"
import { autobind, formatDate } from "../../Utils/functions"
import GeneralTable from "../../Shared/GeneralTable"
import { Delete } from "@material-ui/icons"

const style = (theme) => ({
    container: {
        padding: 12,
    },
    register: {
        background: theme.palette.indigo.lighter,
        borderRadius: 12,
        boxShadow: "0px 1px 8px #00000026",
        marginBottom: 12,
        padding: 12,
        transition: "all 0.1s linear",
        cursor: "pointer",
        "&:hover": {
            transform: "scale(1.01)",
        },
    },
})

const info = [
    { label: "Contenido", key: "content" },
    { label: "Fecha", key: "created_at", format: formatDate, align: "right", }
]

class Registers extends Component {
    constructor() {
        super()
        autobind(Registers, this)
    }

    handleDelete(row) {
        return () => {
            const { deleteRegister } = this.props
            deleteRegister(row.id)
        }
    }

    render() {
        const actions = [
            {
                name: "Eliminar registro",
                icon: ({ row }) => (
                    <IconButton
                        onClick={this.handleDelete(row)}
                        color="secondary"
                    >
                        <Delete />
                    </IconButton>
                ),
            },
        ]
        const { classes, getAllRegisters, registers } = this.props
        return (
            <div className={classes.container}>
                <Typography variant="h1" style={{ margin: "24px 0" }}>
                    Registro de Pagos
                </Typography>
                <Loader action={getAllRegisters}>
                    <GeneralTable
                        data={registers.all || []}
                        info={info}
                        name="registers"
                        open={true}
                        actions={actions}
                    />
                </Loader>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    registers: state.register,
})

const mapDispatchToProps = (dispatch) => ({
    getAllRegisters: () => dispatch(getAllRegisters()),
    deleteRegister: (id) => dispatch(deleteRegister(id))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(style)(Registers))
