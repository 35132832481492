import instance from "../Utils/Instance"

export const getAgentPayment = (body) => {
  return instance({
    method: 'get',
    url: 'agents/payments',
    params: body
  })
}

export const generatePaymentLink = body => {
  return instance({
    method: 'post',
    url: 'agents/payments/pay',
    data: body
  })
}