import React, { Component } from 'react'
import { IconButton, Paper, Tab, Tabs, Typography, withStyles } from '@material-ui/core'
import Agent from './Agent'
import { autobind } from '../../../Utils/functions'
import instance from '../../../Utils/Instance'
import Conditional from '../../../Shared/Conditional'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'

const style = () => ({
  tabs: {
    margin: "0 12px"
  },
  inline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})


class AgentWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: this.props.match.params.id,
      options: []
    }
    autobind(AgentWrapper, this)
  }

  getSimilar() {
    instance({
      method: 'get',
      url: `agent/same/${this.props.match.params.id}`
    }).then(response => {
      console.log(response)
      const options = response.data
      const ids = options.map(option => option.id)
      if (options.length > 1) {
        options.push({ name: 'Todos', id: ids.join("_") })
      }
      this.setState({ options: options, selected: options[options.length - 1].id.toString() })
    })
  }

  componentDidMount() {
    this.setState({ selected: this.props.match.params.id })
    this.getSimilar()
  }

  renderMobile() {
    const { options, selected } = this.state
    const option = options.find(option => option.id.toString() === selected)
    return (
      <Typography variant="subtitle2">
        {option ? option.name : ""}
      </Typography>
    )
  }

  handleNext() {
    const { options, selected } = this.state
    const option = options.find(option => option.id.toString() === selected)
    const actualIndex = options.indexOf(option)
    const newIndex = (actualIndex + 1) % options.length
    this.setState({ selected: options[newIndex].id.toString() })
  }

  handlePrev() {
    const { options, selected } = this.state
    const option = options.find(option => option.id.toString() === selected)
    const actualIndex = options.indexOf(option)
    const newIndex = (actualIndex - 1 + options.length) % options.length
    this.setState({ selected: options[newIndex].id.toString() })
  }

  handleSelectTab(event, value) {
    this.setState({ selected: value })
  }

  renderTabs() {
    const { options } = this.state
    const tabs = options.map(option => {
      return <Tab key={option.name} label={option.name} value={option.id.toString()} />
    })
    return tabs
  }

  render() {
    const { classes } = this.props
    const { selected, options } = this.state
    const isMobile = window.innerWidth < 500
    return (
      <div className={classes.container}>
        <Conditional condition={options.length > 1}>
          <Paper className={classes.tabs}>
            {isMobile ? (
              <div className={classes.inline}>
                <IconButton onClick={this.handlePrev}>
                  <KeyboardArrowLeft />
                </IconButton>
                {this.renderMobile()}
                <IconButton onClick={this.handleNext}>
                  <KeyboardArrowRight />
                </IconButton>
              </div>
            ) : (
                <Tabs value={selected} onChange={this.handleSelectTab}>
                  {this.renderTabs()}
                </Tabs>
              )
            }
          </Paper>
        </Conditional>
        <Agent match={{ params: { id: selected } }} />
      </div>
    )
  }
}

export default withStyles(style)(AgentWrapper)