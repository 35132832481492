import {
    formatMoney,
    formatDateMobile,
    formatHour,
} from "../../Utils/functions"
import { ExitToApp } from "@material-ui/icons"
import React from "react"
import { IconButton } from "@material-ui/core"

export const formInfo = [
    { name: "name", label: "Nombre", required: true },
    { name: "email", label: "Correo", required: true },
    { name: "phone", label: "Teléfono", required: true },
    { name: "rut", label: "Rut", required: true, type: "rut" },
    { name: "account", label: "Numero de Cuenta" },
    { name: "sii_rut", label: "Rut SII", type: "rut" },
    {
        name: "school_id",
        label: "Colegio",
        required: true,
        type: "select",
        option: [],
    },
    {
        name: "bank_id",
        label: "Banco",
        type: "select",
        options: [],
    },
]

export const tableInfo = [
    { key: "name", label: "Nombre" },
    { key: "rut", label: "Rut" },
    { key: "account", label: "N° Cuenta", notSortable: true },
    { key: "email", label: "Correo" },
    { key: "school&name", label: "Colegio" },
]

export const paymentsInfo = [
    { key: "rut", label: "RUT" },
    { key: "name", label: "Nombre" },
    { key: "account", label: "Cuenta Destino", notSortable: true },
    { key: "email", label: "Correo" },
    { key: "payment_without_discount", label: "Monto sin descuento", format: formatMoney, align: "right" },
    { key: "payment", label: "Monto", format: formatMoney, align: "right" },

]

export const agentInfo = [
    {
        name: "agent_id",
        label: "Apoderado",
        type: "select",
        required: true,
        options: [], //this.unifyAgents(this.props.agent.all),
    },
]

export const subjectInfo = [
    {
        name: "subject_id",
        label: "Asignatura",
        type: "select",
        required: true,
        options: [], //this.filterSubjects(this.props.tutor.school.subjects),
    },
]

export const area = `
'classes classes classes classes classes' 
'class class class class class'
'agents agents . subjects subjects' 
'form form form form form' 
'agent agent . subject subject'
`

export const areaMobile = `
'classes' 
'form' 
'class'
'agents' 
'subjects' 
'agent'
'subject'
`

export const tutorLessonInfo = [
    { key: "date", label: "Fecha", format: formatDateMobile },
    { key: "duration", label: "Duración", format: formatHour },
    { key: "subject&name", label: "Asignatura" },
    { key: "tutor&name", label: "Tutor" },
    { key: "agent&name", label: "Apoderado" },
    { key: "student&name", label: "Alumno" },
    { key: "payment", label: "Monto", format: formatMoney, align: "right" },
]

export const tutorLessonActions = (self) => {
    return [
        {
            name: "Ver Clase",
            icon: ({ row }) => (
                <IconButton onClick={self.handleGoto(row)} color="primary">
                    <ExitToApp />
                </IconButton>
            ),
        },
    ]
}
