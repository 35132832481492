import React, { Component } from "react"
import { Button, Dialog, Typography, withStyles } from "@material-ui/core"
import moment from "moment"
import { autobind, firstUpper, formatMoney } from "../../Utils/functions"
import Conditional from "../Conditional"
import { connect } from "react-redux"
import GeneralForm from "../GeneralForm"
import { getSchoolTutors } from "../../Actions/SchoolsActions"
import { createCreditAction } from "../../Actions/AgentActions"

const style = (theme) => ({
    container: {
        display: "grid",
        gridGap: 6,
        alignSelf: "center",
    },
    block: {
        width: "60%",
        padding: 6,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        justifySelf: "center",
        "@media (max-width:1000px)": {
            width: "95%",
            boxSizing: "border-box",
        },
    },
    success: {
        color: theme.palette.confirm.main,
    },
    middle: {
        color: theme.palette.error.main,
    },
    total: {
        color: theme.palette.primary.dark,
    },
    credits: {
        color: theme.palette.primary.main,
    },
    onlyAdmin: {
        position: 'absolute',
        bottom: 12,
        right: 12
    }
})

const creditInfo = [
    {
        name: "tutor_id",
        label: "Tutor",
        required: true,
        type: "select",
        options: [],
    },
    { name: "amount", label: "Monto", required: true },
    { name: "date", label: "Fecha de creación", required: true, type: "date" },
]

class LessonsResumeCard extends Component {
    constructor() {
        super()
        this.state = {
            openCredits: false,
            params: {
                date: moment(new Date()).format("YYYY-MM-DD")
            }
        }
        autobind(LessonsResumeCard, this)
    }

    componentDidMount() {
        const { getSchoolTutors, agent } = this.props
        getSchoolTutors(agent?.selected?.school?.id)
    }

    handleOpenCredits() {
        this.setState({ openCredits: !this.state.openCredits })
    }

    handleChange(event) {
        const { target } = event
        const { params } = this.state
        params[target.name] = target.value
        this.setState({ params })
    }

    handleCreate() {
        const { agent, createCredit, reload } = this.props
        const { params } = this.state
        const body = {
            ...params,
            id: agent.selected.id
        }
        createCredit(body).then(() => {
            reload()
        })
        this.setState({
            params: { data: moment(new Date()).format("YYYY-MM-DD") },
            openCredits: false
        })
    }

    setOptions(info) {
        const result = [...info]
        const { school } = this.props
        result.forEach((item, index) => {
            if (item.type === "select") {
                switch (item.name) {
                    case "tutor_id":
                        result[index].options = school.tutors
                        break
                    default:
                        break
                }
            }
        })
        return result
    }

    render() {
        const {
            classes,
            payed,
            notPayed,
            month,
            credits,
            base,
            spent,
            account
        } = this.props
        const { openCredits, params } = this.state
        const upperMonth = firstUpper(month)
        const level = account.user.level
        return (
            <>
                <div className={classes.container}>
                    <div className={classes.block}>
                        <Typography variant="subtitle2">
                            Total clases {upperMonth}:
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            className={classes.success}
                        >
                            {formatMoney(payed + notPayed)}
                        </Typography>
                    </div>
                    <Conditional condition={base - spent > 0}>
                        <div
                            className={classes.block}
                            style={{ borderColor: "#249e24" }}
                        >
                            <Typography variant="subtitle2">
                                Creditos de descuento:
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.credits}
                            >
                                {formatMoney(base - spent)}
                            </Typography>
                        </div>
                    </Conditional>
                    <Conditional condition={spent > 0}>
                        <div
                            className={classes.block}
                            style={{ borderColor: "#249e24" }}
                        >
                            <Typography variant="subtitle2">
                                Creditos usados:
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.credits}
                            >
                                {formatMoney(spent)}
                            </Typography>
                        </div>
                    </Conditional>
                    <div
                        className={classes.block}
                        style={{ borderColor: "#249e24" }}
                    >
                        <Typography variant="subtitle2">
                            Total por pagar {upperMonth}:
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            className={classes.middle}
                        >
                            {formatMoney(notPayed - base + spent)}
                        </Typography>
                    </div>
                    <div className={classes.block}>
                        <Typography variant="subtitle2">
                            Total pagado {upperMonth}:
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            className={classes.total}
                        >
                            {formatMoney(payed - spent)}
                        </Typography>
                    </div>
                    {level === "admin" &&
                        <>
                            <div className={classes.onlyAdmin}>
                                <Button size="small" variant="outlined" color="primary" onClick={this.handleOpenCredits}>
                                    Añadir Créditos
                                </Button>
                            </div>
                            <Dialog open={openCredits} onClose={this.handleOpenCredits}>
                                <GeneralForm
                                    params={params}
                                    info={this.setOptions(creditInfo)}
                                    title="Nuevo Crédito"
                                    grid="1fr"
                                    handleChange={this.handleChange}
                                    create={this.handleCreate}
                                    submit="Crear"
                                />
                            </Dialog>
                        </>
                    }
                </div>
                <Conditional condition={credits > 0}>
                    <div
                        style={{ position: "absolute", right: 12, top: "100%" }}
                    >
                        <Typography variant="caption">
                            *Los creditos de descuento se aplicarán en su
                            siguiente pago
                        </Typography>
                    </div>
                </Conditional>
            </>
        )
    }
}

LessonsResumeCard.propTypes = {}

LessonsResumeCard.defaultProps = {
    payed: 0,
    notPayed: 0,
    month: moment(new Date()).format("MMMM"),
    credits: 0,
}

const mapStateToProps = state => ({
    account: state.account,
    school: state.school,
    agent: state.agent
})

const mapDispatchToProps = dispatch => ({
    getSchoolTutors: (id) => dispatch(getSchoolTutors(id)),
    createCredit: body => dispatch(createCreditAction(body))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(LessonsResumeCard))
