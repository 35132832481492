import React, { Component } from "react"
import {
    Typography,
    withStyles,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
} from "@material-ui/core"
import { defaultStyle } from "../../Utils/staticStyles"
import {
    addSimulationAction,
    loadDashboard,
} from "../../Actions/CoordinatorActions"
import { connect } from "react-redux"
import { deleteLesson } from "../../Actions/TutorActions"
import Summary from "./Summary"
import SubInfo from "./SubInfo"
import moment from "moment"
import { autobind } from "../../Utils/functions"
import LoaderSpinner from "../../Shared/Loader/LoaderSpinner"

function getMonthNumber(monthName) {
    return moment.months().indexOf(monthName) + 1
}

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            params: {
                sdate: moment(new Date()).format("YYYY-MM-[01]"),
                edate: moment(new Date())
                    .add(1, "month")
                    .format("YYYY-MM-[01]"),
                smonth: moment(new Date()).format("MMMM"),
                emonth: moment(new Date()).add(1, "month").format("MMMM"),
                syear: moment(new Date()).format("YYYY"),
                eyear: moment(new Date()).add(1, "month").format("YYYY"),
            },
        }
        autobind(Dashboard, this)
    }

    async componentDidMount() {
        const { loadDashboard, account } = this.props
        const { params } = this.state
        const date = {
            sdate: params.sdate,
            edate: params.edate,
        }
        const id = this.props.id || account.user.user.id
        this.setState({ loading: true })
        await loadDashboard(id, date)
        this.setState({ loading: false })
    }

    handleReload() {
        this.componentDidMount()
    }

    handleChange(event) {
        const { target } = event
        const params = { ...this.state.params }
        params[target.name] = target.value
        params.sdate = `${params.syear}-${getMonthNumber(params.smonth)}-01`
        params.edate = `${params.eyear}-${getMonthNumber(params.emonth)}-01`
        this.setState({ params })
    }

    handleDelete(id) {
        this.props.deleteLesson(id, this.props.enqueueSnackbar)
    }

    handleGoto(id) {
        this.props.history.push("/lesson/" + id)
    }

    renderMonths() {
        const months = moment.months()
        return months.map((month) => (
            <MenuItem value={month} key={month}>
                {month}
            </MenuItem>
        ))
    }

    renderYears() {
        const startYear = 2020
        const actualYear = parseInt(moment(new Date()).format("YYYY"), 10)
        const finalYear = actualYear + 2
        const years = []
        for (let i = startYear; i < finalYear; i += 1) {
            years.push(i)
        }
        return years.map((year) => (
            <MenuItem value={year} key={year}>
                {year}
            </MenuItem>
        ))
    }

    handleAddSimulation(id) {
        const { params } = this.state
        const { coordinator, addSimulation } = this.props
        const { dashboard } = coordinator
        const body = {
            sdate: params.sdate,
            edate: params.edate,
            agent_id: id,
            id: dashboard.coordinator.id,
        }
        addSimulation(body)
    }

    renderHeader() {
        const { params, loading } = this.state
        const { classes } = this.props
        return (
            <>
                <div className={classes.inline}>
                    <FormControl style={{ margin: "6px 6px", minWidth: 229 }}>
                        <InputLabel shrink>Mes desde</InputLabel>
                        <Select
                            disabled={loading}
                            value={moment(params.sdate).format("MMMM")}
                            onChange={this.handleChange}
                            name="smonth"
                        >
                            {this.renderMonths()}
                        </Select>
                    </FormControl>

                    <FormControl style={{ margin: "6px 6px", minWidth: 100 }}>
                        <InputLabel shrink>Año desde</InputLabel>
                        <Select
                            disabled={loading}
                            value={moment(params.sdate).format("YYYY")}
                            onChange={this.handleChange}
                            name="syear"
                        >
                            {this.renderYears()}
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.inline}>
                    <FormControl style={{ margin: "6px 6px", minWidth: 229 }}>
                        <InputLabel shrink>Mes hasta</InputLabel>
                        <Select
                            disabled={loading}
                            value={moment(params.edate).format("MMMM")}
                            onChange={this.handleChange}
                            name="emonth"
                        >
                            {this.renderMonths()}
                        </Select>
                    </FormControl>

                    <FormControl style={{ margin: "6px 6px", minWidth: 100 }}>
                        <InputLabel shrink>Año hasta</InputLabel>
                        <Select
                            disabled={loading}
                            value={moment(params.edate).format("YYYY")}
                            onChange={this.handleChange}
                            name="eyear"
                        >
                            {this.renderYears()}
                        </Select>
                    </FormControl>
                </div>
                <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={this.handleReload}
                    style={{ margin: 6 }}
                >
                    Filtrar
                </Button>
            </>
        )
    }

    render() {
        const { classes, coordinator, account } = this.props
        const { loading } = this.state
        const { dashboard } = coordinator
        const simulations =
            dashboard && dashboard.simulation_ids
                ? dashboard.simulation_ids
                : ""
        return (
            <>
                <div className={classes.container}>
                    <div className={classes.flexLine}>
                        <Typography variant="h1" className={classes.title}>
                            Resumen Coordinador{" "}
                            {dashboard && dashboard.coordinator.name}
                        </Typography>
                    </div>
                    {this.renderHeader()}
                    {loading ? <LoaderSpinner /> :
                        <>
                            {dashboard && (
                                <>
                                    <Summary
                                        salary={dashboard.salary}
                                        incomes={dashboard.incomes}
                                        margin={dashboard.margin}
                                        payments={dashboard.payments}
                                        payed={dashboard.payed}
                                    />
                                    <SubInfo
                                        agentsData={dashboard.agents}
                                        tutorsData={dashboard.tutors}
                                        addSimulation={this.handleAddSimulation}
                                        simulation_ids={simulations.split("&")}
                                        level={account.user.level}
                                    />
                                </>
                            )}
                        </>
                    }
                </div >
            </>

        )
    }
}

const mapStateToProps = (state) => ({
    coordinator: state.coordinator,
    account: state.account,
})

const mapDispatchToProps = (dispatch) => ({
    loadDashboard: (id, month, year) =>
        dispatch(loadDashboard(id, month, year)),
    deleteLesson: (id) => dispatch(deleteLesson(id)),
    addSimulation: (body) => dispatch(addSimulationAction(body)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(defaultStyle)(Dashboard))
