import React, { Component } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import Home from "../Components/Home"
import Login from "../Components/Login/Login"
import { connect } from "react-redux"
import NotFound from "../Components/NotFound/NotFound"
import Schools from "../Components/Schools/Schools"
import Coordinators from "../Components/Coordinators/Coordinators"
import Coordinator from "../Components/Coordinators/Coordinator"
import Tutors from "../Components/Tutors/Tutors"
import School from "../Components/Schools/School"
import Agents from "../Components/Agents/Index/Agents"
import Tutor from "../Components/Tutors/Tutor"
import Banks from "../Components/Banks/Banks"
import Lesson from "../Components/Lesson/Lesson"
import Dashboard from "../Components/Dashboard/Dashboard"
import AdminDashboard from "../Components/Dashboard/AdminDashboard"
import Subjects from "../Components/Schools/Subjects"
import AgentPay from "../Components/Agents/AgentPay"
import Pendings from "../Components/Lesson/Pendings"
import AgentWrapper from "../Components/Agents/Profile/AgentWrapper"
import Bills from "../Components/Bills/Bills"
import Registers from "../Components/Registers/Registers"
import InvalidOrder from "../Shared/InvalidOrder"
import OnlinePayment from "../Components/Agents/OnlinePayment"

class Routes extends Component {
    render() {
        const { account } = this.props
        return (
            <Switch>
                <PrivateRoute
                    exact
                    path="/login"
                    component={Login}
                    account={account}
                    type={3}
                />
                <PrivateRoute
                    exact
                    path="/login/administracion"
                    component={Login}
                    account={account}
                    type={3}
                />
                <PrivateRoute
                    exact
                    path="/home"
                    component={Home}
                    account={account}
                    type={3}
                />
                <PrivateRoute
                    exact
                    path="/"
                    component={AdminDashboard}
                    account={account}
                    type={0}
                />
                <PrivateRoute
                    exact
                    path="/schools"
                    component={Schools}
                    account={account}
                    type={0}
                />
                <PrivateRoute
                    exact
                    path="/school/:id"
                    component={School}
                    account={account}
                    type={1}
                    owned={true}
                />
                <PrivateRoute
                    exact
                    path="/coordinators"
                    component={Coordinators}
                    account={account}
                    type={0}
                />
                <PrivateRoute
                    exact
                    path="/tutors"
                    component={Tutors}
                    account={account}
                    type={1}
                    owned={true}
                />
                <PrivateRoute
                    exact
                    path="/tutor/:id"
                    component={Tutor}
                    account={account}
                    type={2}
                    owned={true}
                />
                <PrivateRoute
                    exact
                    path="/agents"
                    component={Agents}
                    account={account}
                    type={1}
                    owned={true}
                />
                <PrivateRoute
                    exact
                    path="/dashboard"
                    component={Dashboard}
                    account={account}
                    type={1}
                    owned={true}
                    only
                />
                <PrivateRoute
                    exact
                    path="/subjects"
                    component={Subjects}
                    account={account}
                    type={1}
                    owned={true}
                    only
                />
                <PrivateRoute
                    exact
                    path="/dashboards"
                    component={AdminDashboard}
                    account={account}
                    type={0}
                    owned={true}
                />
                <PrivateRoute
                    exact
                    path="/banks"
                    component={Banks}
                    account={account}
                    type={0}
                />
                <PrivateRoute
                    exact
                    path="/bills"
                    component={Bills}
                    account={account}
                    type={0}
                />
                <PrivateRoute
                    exact
                    path="/registers"
                    component={Registers}
                    account={account}
                    type={0}
                />
                <PrivateRoute
                    exact
                    path="/pendings"
                    component={Pendings}
                    account={account}
                    type={1}
                />
                <PrivateRoute
                    exact
                    path="/coordinator/:id"
                    component={Coordinator}
                    account={account}
                    type={1}
                    owned={true}
                />
                <PrivateRoute
                    exact
                    path="/agent/:id"
                    component={AgentWrapper}
                    account={account}
                    type={2}
                    owned={true}
                />
                <PrivateRoute
                    exact
                    path="/invalid_order"
                    component={InvalidOrder}
                    account={account}
                    type={2}
                    owned={true}
                />
                <PrivateRoute
                    exact
                    path="/agent/:id/payed/:lesson_id"
                    component={AgentPay}
                    account={account}
                    type={2}
                    owned={true}
                />
                <PrivateRoute
                    exact
                    path="/lesson/:id/:tutor_id"
                    component={Lesson}
                    account={account}
                    type={2}
                    owned={true}
                />
                <Route
                    exact
                    path="/payment/:id"
                    component={OnlinePayment}
                    account={account}
                    type={3}
                />
                <PrivateRoute
                    exact
                    path="*"
                    component={NotFound}
                    account={account}
                    type={3}
                />
            </Switch>
        )
    }
}

function PrivateRoute({
    component: Component,
    account,
    path,
    type,
    owned,
    only,
}) {
    if (account.user.user && account.user.user.id) {
        if (
            (only && account.user.type === type) ||
            (!only && account.user.type <= type)
        ) {
            return <Route exact path={path} component={Component} />
        } else {
            if (account.user.level === "admin") {
                return <Redirect to={"/dashboards"} />
            } else if (account.user.level === "coordinator") {
                return <Redirect to={"/dashboard"} />
            } else if (account.user.level === "tutor") {
                return <Redirect to={"/tutor/" + account.user.user.id} />
            } else {
                return <Redirect to={"/agent/" + account.user.user.id} />
            }
        }
    } else {
        return <Route exact path={path} component={Login} />
    }
}
const mapStateToProps = (state) => ({
    account: state.account,
})

export default connect(mapStateToProps)(Routes)
