import React, { Component } from "react"
import {
    Typography,
    Divider,
    withStyles,
    Button,
} from "@material-ui/core"
import GeneralTable from "../../../Shared/GeneralTable"
import { tableInfo } from "../info"
import { getPayed } from "../../../Actions/AgentActions"
import { connect } from "react-redux"
import { autobind } from "../../../Utils/functions"
import DateIntervalSelector from "../../../Shared/Fields/DateIntervalSelector"
import DateIntervalContext from "../../../Shared/Fields/DateIntevalContext"
import LoaderSpinner from "../../../Shared/Loader/LoaderSpinner"
import moment from 'moment'

const style = (theme) => ({
    title: {
        padding: "12px 0 0 12px",
    },
})

class Payed extends Component {
    constructor() {
        super()
        this.state = {
            params: {
                sdate: moment(new Date()).format("YYYY-MM-[01]"),
                edate: moment(new Date())
                    .add(1, "month")
                    .format("YYYY-MM-[01]"),
            },
            loading: false
        }
        autobind(Payed, this)
    }

    async componentDidMount() {
        const { getPayed } = this.props
        const { params } = this.state
        const date = {
            sdate: params.sdate,
            edate: params.edate,
        }
        this.setState({ loading: true })
        await getPayed(date)
        this.setState({ loading: false })
    }

    handleReload() {
        this.componentDidMount()
    }

    handleUpdate(params) {
        this.setState({ params })
    }

    render() {
        const { classes, actions, agent } = this.props
        const { params, loading } = this.state
        return (
            <div>
                <Typography variant="h2" className={classes.title}>
                    Apoderados Pagados
                </Typography>
                <Divider />
                <DateIntervalContext.Provider value={{ params, update: this.handleUpdate, disabled: loading }}>
                    <DateIntervalSelector />
                </DateIntervalContext.Provider>
                <Button
                    onClick={this.handleReload}
                    size="small"
                    color="primary"
                    variant="outlined"
                    style={{ margin: 6 }}
                >Filtrar
                </Button>
                {loading ? <LoaderSpinner /> :
                    <GeneralTable
                        actions={actions}
                        data={agent.payed}
                        info={tableInfo}
                        name="payed"
                        maxHeight={500}
                        open
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    agent: state.agent,
})

const mapDispatchToProps = (dispatch) => ({
    getPayed: (date) => dispatch(getPayed(date)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(style)(Payed))
