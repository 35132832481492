import React, { Component } from "react";
import {
    withStyles,
    Paper,
    ListItem,
    Typography,
    Button,
} from "@material-ui/core";
import { KeyboardArrowUp, KeyboardArrowDown, Add } from "@material-ui/icons";
import ShowList from "../../Shared/ShowList";
import GeneralForm from "../../Shared/GeneralForm";
import { validate, getRequired } from "../../Utils/validations";
import { withSnackbar } from "notistack";
import Transition from "../../Shared/Transition";
import Conditional from "../../Shared/Conditional";

const style = () => ({
    divider: {
        margin: "12px 0",
    },
    title: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: 5,
    },
    paper: {
        display: "grid",
        gridTemplateColumns: "6fr 1fr",
        gridGap: 12,
    },
});

class SchoolResume extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            form: false,
            params: {},
            on: false,
            deleteAciont: undefined,
            deleteId: "",
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleForm = this.handleForm.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.goto = this.goto.bind(this);
    }

    handleOpen() {
        this.setState({ open: !this.state.open });
    }

    handleForm() {
        this.setState({ form: !this.state.form });
    }

    handleChange(event) {
        const target = event.target;
        const params = { ...this.state.params };
        params[target.name] = target.value;
        this.setState({ params });
    }

    handleCreate() {
        const { params } = this.state;
        const { info } = this.props;
        const validations = getRequired(info);
        if (validate(validations, params, this.props.enqueueSnackbar)) {
            const body = { ...params };
            this.props.create(body, this.props.enqueueSnackbar);
            this.setState({ params: {}, form: false });
        }
    }

    handleDelete(id) {
        this.props.delete(id, this.props.enqueueSnackbar);
    }

    goto(id) {
        const { option } = this.props;
        this.props.goto(option, id);
    }

    render() {
        const { classes, name, data, singular, info, option } = this.props;
        const { open, form, params } = this.state;
        return (
            <div style={{ margin: "24px 0" }}>
                <div className={classes.paper}>
                    <Paper>
                        <ListItem
                            button
                            className={classes.title}
                            onClick={this.handleOpen}
                        >
                            <Typography variant="h2">{name}</Typography>
                            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </ListItem>
                    </Paper>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleForm}
                    >
                        <Add
                            style={{
                                transition: "all 0.2s ease-in",
                                transform: form ? "rotate(45deg)" : "rotate(0)",
                            }}
                        />
                    </Button>
                </div>
                <Transition on={form}>
                    <div style={{ marginTop: 12 }}>
                        <GeneralForm
                            title={"Nuevo " + singular}
                            info={info}
                            params={params}
                            handleChange={this.handleChange}
                            create={this.handleCreate}
                        />
                    </div>
                </Transition>
                <Conditional condition={open}>
                    <ShowList
                        data={data}
                        delete={this.handleDelete}
                        goto={this.goto}
                        option={option}
                    />
                </Conditional>
            </div>
        );
    }
}

export default withSnackbar(withStyles(style)(SchoolResume));
