import React, { Component } from "react"
import { Typography, Divider, withStyles, IconButton, Button, } from "@material-ui/core"
import Loader from "../../../Shared/Loader/Loader"
import GeneralTable from "../../../Shared/GeneralTable"
import { defaulterInfo } from "../info"
import { getDefaulters, sendDefaulterEmail } from "../../../Actions/AgentActions"
import { connect } from "react-redux"
import { autobind } from "../../../Utils/functions"
import { Send } from "@material-ui/icons"
import "../../../Shared/Loader/animation.css"
import { withSnackbar } from "notistack"
import DateIntervalContext from "../../../Shared/Fields/DateIntevalContext"
import DateIntervalSelector from "../../../Shared/Fields/DateIntervalSelector"
import MailDialog from "./MailDialog"
import moment from 'moment'
import LoaderSpinner from "../../../Shared/Loader/LoaderSpinner"

const style = () => ({
    inline: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "12px 0 0 12px",
    },
})

class Defaulters extends Component {
    constructor() {
        super()
        this.state = {
            params: {
                sdate: moment(new Date()).format("YYYY-MM-[01]"),
                edate: moment(new Date())
                    .add(1, "month")
                    .format("YYYY-MM-[01]"),
            },
            sending: false,
            selected: [],
        }
        autobind(Defaulters, this)
    }

    async componentDidMount() {
        const { getDefaulters } = this.props
        const { params } = this.state
        const date = {
            sdate: params.sdate,
            edate: params.edate,
        }
        this.setState({ loading: true })
        await getDefaulters(date)
        this.setState({ loading: false })
    }

    handleReload() {
        this.componentDidMount()
    }

    handleUpdate(params) {
        this.setState({ params })
    }

    handleSendMail(row) {
        return () => {
            this.setState({ sending: true })
            const { enqueueSnackbar, sendDefaulterEmail } = this.props
            const { params } = this.state
            const date = {
                sdate: params.sdate,
                edate: params.edate,
            }
            const body = {
                ids: [row.id],
                date,
            }
            sendDefaulterEmail(body, enqueueSnackbar)
        }
    }

    handleSendMultiple() {
        this.setState({ sending: true })
        const { enqueueSnackbar, sendDefaulterEmail } = this.props
        const { params, selected } = this.state
        const date = {
            sdate: params.sdate,
            edate: params.edate,
        }
        const body = {
            ids: selected,
            date,
        }
        sendDefaulterEmail(body, enqueueSnackbar)
    }

    handleSelectRow(id) {
        return () => {
            const { selected } = this.state
            const index = selected.indexOf(id)
            if (index === -1) {
                selected.push(id)
            } else {
                selected.splice(index, 1)
            }
            this.setState({ selected })
        }
    }

    handleUnselect() {
        this.setState({ selected: [] })
    }

    handleCloseDialog() {
        this.setState({ sending: false })
    }

    render() {
        const { classes, actions, agent, mails_info, } = this.props
        const { params, sending, selected, loading } = this.state

        const newActions = [...actions]
        newActions.push({
            name: "Enviar Correo",
            icon: ({ row }) => (
                <IconButton onClick={this.handleSendMail(row)} color="primary">
                    <Send />
                </IconButton>
            ),
        })

        return (
            <div>
                <div className={classes.inline}>
                    <Typography variant="h2">Apoderados Morosos</Typography>
                    <Button
                        size="small"
                        color="primary"
                        variant="outlined"
                        onClick={this.handleSendMultiple}
                        disabled={selected.length === 0}
                    >
                        Enviar correos de recordatorio
                    </Button>
                </div>

                <Divider />
                <DateIntervalContext.Provider value={{ params, update: this.handleUpdate, disabled: loading }}>
                    <DateIntervalSelector />
                </DateIntervalContext.Provider>
                <Button
                    onClick={this.handleReload}
                    size="small"
                    color="primary"
                    variant="outlined"
                    style={{ margin: 6 }}
                >Filtrar
                </Button>
                {loading ? <LoaderSpinner /> :
                    <GeneralTable
                        actions={newActions}
                        data={agent.defaulters}
                        info={defaulterInfo}
                        name="Defaulters"
                        maxHeight={500}
                        open
                        ableSelect
                        selected={selected}
                        handleSelect={this.handleSelectRow}
                        handleUnselect={this.handleUnselect}
                    />
                }
                <MailDialog sending={sending} onClose={this.handleCloseDialog} mails_info={mails_info} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    agent: state.agent,
})

const mapDispatchToProps = (dispatch) => ({
    getDefaulters: (date) => dispatch(getDefaulters(date)),
    sendDefaulterEmail: (body, snackbar) =>
        dispatch(sendDefaulterEmail(body, snackbar)),
})

export default withSnackbar(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Defaulters))
)
