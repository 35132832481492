import React, { Component } from 'react'
import { FormControl, InputLabel, MenuItem, Select, withStyles } from '@material-ui/core'
import moment from 'moment'

const style = () => ({

})

class YearSelector extends Component {
  renderYears() {
    const actualYear = parseInt(moment(new Date()).format("YYYY"), 10)
    const years = Array.from(Array(5)).map((value, index) => (actualYear + index - 2).toString())
    return years.map(year => (<MenuItem value={year}>{year}</MenuItem>))
  }

  render() {
    const { classes, value, onChange } = this.props
    return (
      <div className={classes.container}>
        <FormControl>
          <InputLabel shrink>Seleccione Año</InputLabel>
          <Select value={value} onChange={onChange}>
            {this.renderYears()}
          </Select>
        </FormControl>
      </div>
    )
  }
}

YearSelector.propTypes = {

}


YearSelector.defaultProps = {
  value: moment(new Date()).format("YYYY")
}

export default withStyles(style)(YearSelector)