import React, { Component } from "react";
import { Typography } from "@material-ui/core";

export default class Lazy extends Component {
    render() {
        console.log(this.props.loading)
        return (
            <>
                {this.props.loading ? (
                    <Typography variant="h1">Cargando...</Typography>
                ) : (
                    this.props.children
                )}
            </>
        );
    }
}
