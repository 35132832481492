import React, { Component } from "react";
import { Typography, Divider, withStyles } from "@material-ui/core";
import GeneralTable from "../../Shared/GeneralTable";
import Loader from "../../Shared/Loader/Loader";
import { getAllCoordinators } from "../../Actions/CoordinatorActions";
import { connect } from "react-redux";
import { tableInfo } from "./tableinfo";

const style = (theme) => ({
    title: {
        padding: "12px 0 0 12px",
    },
});

class All extends Component {
    render() {
        const { getCoordinators, coordinator, actions, classes } = this.props;
        return (
            <Loader action={getCoordinators} params={[]}>
                <div>
                    <Typography variant="h2" className={classes.title}>
                        Tabla de Coordinadores
                    </Typography>
                    <Divider />
                    <GeneralTable
                        data={coordinator.all}
                        info={tableInfo}
                        actions={actions}
                        name="all"
                        open
                        maxHeight={500}
                    />
                </div>
            </Loader>
        );
    }
}

const mapStateToProps = (state) => ({
    coordinator: state.coordinator,
});

const mapDispatchToProps = (dispatch) => ({
    getCoordinators: () => dispatch(getAllCoordinators()),
});

export default withStyles(style)(
    connect(mapStateToProps, mapDispatchToProps)(All)
);
