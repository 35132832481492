import React, { Component } from "react"
import { Typography, Divider, withStyles } from "@material-ui/core"
import GeneralTable from "../../../Shared/GeneralTable"
import { tableInfo } from "../info"
import { getAgents } from "../../../Actions/AgentActions"
import { connect } from "react-redux"
import LoaderSpinner from "../../../Shared/Loader/LoaderSpinner"

const style = (theme) => ({
    title: {
        padding: "12px 0 0 12px",
    },
})

class All extends Component {
    constructor() {
        super()
        this.state = {
            loading: false
        }
    }
    async componentDidMount() {
        const { getAgents } = this.props
        this.setState({ loading: true })
        await getAgents()
        this.setState({ loading: false })
    }
    render() {
        const { classes, actions, agent } = this.props
        const { loading } = this.state
        return (
            <div>
                <Typography variant="h2" className={classes.title}>
                    Todos los apoderados
                </Typography>
                <Divider />
                {loading ? <LoaderSpinner /> :
                    <GeneralTable
                        actions={actions}
                        data={agent.all}
                        info={tableInfo}
                        name="payed"
                        maxHeight={500}
                        open
                    />
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    agent: state.agent,
})

const mapDispatchToProps = (dispatch) => ({
    getAgents: (date) => dispatch(getAgents(date)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(style)(All))
