import React, { Component } from 'react'
import { Button, Typography, withStyles } from '@material-ui/core'
import { autobind } from '../Utils/functions'

const style = () => ({
  container: {
    margin: 12,
    '& > *': {
      margin: '12px 0'
    }
  }
})

class InvalidOrder extends Component {
  constructor() {
    super()
    autobind(InvalidOrder, this)
  }

  handleBack() {
    const { history } = this.props
    history.push("/")
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Typography variant="h1">
          El enlace de pago ha expirado
        </Typography>
        <Button color="primary" variant="contained" onClick={this.handleBack}>
          Volver
        </Button>
      </div>
    )
  }
}

export default withStyles(style)(InvalidOrder)