import React, { Component } from "react"
import { withStyles, Typography, Button, Divider, IconButton } from "@material-ui/core"
import { connect } from "react-redux"
import { tutorDispatcher } from "../../Actions/TutorActions"
import GeneralForm from "../../Shared/GeneralForm"
import { getRequired, validate } from "../../Utils/validations"
import { getAllStore } from "../../Utils/stateUtils"
import { withSnackbar } from "notistack"
import lessonInfo from "../Lesson/formInfo"
import UserCard from "../../Shared/UserCard"
import Conditional from "../../Shared/Conditional"
import moment from "moment"
import {
	checkUser,
	autobind,
	combineDispatchers,
	checkInfo,
	filterOwned,
} from "../../Utils/functions"
import { agentDispatcher } from "../../Actions/AgentActions"
import { schoolDispatcher } from "../../Actions/SchoolsActions"
import { bankDispatcher } from "../../Actions/BankAction"
import {
	area,
	agentInfo,
	subjectInfo,
	tutorLessonInfo,
	areaMobile,
} from "./tutorInfo"
import MonthSelector from "../../Shared/MonthSelector"
import Asociations from "./Components/Asociations"
import WelcomeMessage from "../../Shared/WelcomeMessage"
import GeneralTable from "../../Shared/GeneralTable"
import TutorResumeCard from "../../Shared/Cards/TutorResumeCard"
import ResponsiveLessonTutor from "./ResponsiveLessonTutor"
import { Delete } from "@material-ui/icons"
import DeletePopup from "../../Shared/DeletePopup"
import YearSelector from "../../Shared/YearSelector"

const style = (theme) => ({
	container: {
		padding: 12,
	},
	grid: {
		display: "grid",
		gridTemplateAreas: area,
		gridGap: 24,
		gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
		"@media (max-width:1000px)": {
			gridTemplateAreas: areaMobile,
			gridTemplateColumns: "1fr",
		},
	},
	lessonTitle: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginTop: 8,
	},
	resume: {
		background: theme.palette.indigo.lighter,
		display: "grid",
		borderRadius: 24,
		position: "relative",
	},
	topContainer: {
		display: "grid",
		marginBottom: 48,
		gridTemplateColumns: "1fr 1fr",
		"@media (max-width:1000px)": {
			display: "grid",
			marginBottom: 48,
			gridTemplateColumns: "1fr",
		},
		gridGap: 24,
	},
	inline: {
		display: 'flex',
		'& > *': {
			marginRight: 3
		},
		marginBottom: 24
	}
})

class Tutor extends Component {
	constructor(props) {
		super(props)
		this.state = {
			params: { date: moment(new Date()).format("YYYY-MM-DD") },
			agentParams: {},
			subjectParams: {},
			agentForm: false,
			subjectForm: false,
			open: false,
			month: moment(new Date()).format("MMMM"),
			year: moment(new Date()).format("YYYY"),
			openDelete: false,
			action: undefined
		}

		autobind(Tutor, this)
	}

	componentDidMount() {
		const month =
			moment.months().indexOf(moment(new Date()).format("MMMM")) + 1
		const { year } = this.state
		const date = { month, year }
		this.props.getTutor(this.props.match.params.id, date)
		this.props.getAgents()
		this.props.getAllBanks()
	}

	handleOpenClass() {
		this.setState({ open: !this.state.open })
	}

	handleChange(event) {
		const { target } = event
		const params = { ...this.state.params }
		params[target.name] = target.value
		this.setState({ params })
	}

	handleCreate() {
		const { account } = this.props
		const { params } = this.state
		const validations = getRequired(lessonInfo)
		// Check if a tutor is trying to create a lesson on the month before or 5 days from now
		let validDate = true
		const isTutor = account.user.level === "tutor"
		if (isTutor) {
			if (
				moment(params.date).format("MMMM") !==
				moment(new Date()).format("MMMM")
			) {
				validDate = false
				this.props.enqueueSnackbar(
					"Solo se pueden crear clases en el mes actual.",
					{ variant: "warning" }
				)
				this.props.enqueueSnackbar(
					"Esta acción la puede realizar un supervisor, si es necesario.",
					{ variant: "info" }
				)
			}
			if (moment(new Date()).diff(moment(params.date), "days") > 5) {
				validDate = false
				this.props.enqueueSnackbar(
					"Solo se pueden crear clases con 5 días maximo de retraso.",
					{ variant: "warning" }
				)
			}
		}
		if (
			validate(validations, params, this.props.enqueueSnackbar) &&
			validDate
		) {
			const body = {
				...params,
				tutor_id: this.props.match.params.id,
				duration:
					parseInt(params.hours) + parseFloat(params.minutes / 60),
			}
			const { year } = this.state

			const month = moment.months().indexOf(this.state.month) + 1
			const date = { month, year }
			this.props.createLesson(body, this.props.enqueueSnackbar, date)
			this.setState({
				params: { date: moment(new Date()).format("YYYY-MM-DD") },
				open: false,
			})
		}
	}

	handleChangeAgent(event) {
		const { target } = event
		const agentParams = { ...this.state.agentParams }
		agentParams[target.name] = target.value
		this.setState({ agentParams })
	}

	handleCreateAgent() {
		const { agentParams } = this.state
		const validations = [
			{
				name: "agent_id",
				type: "select",
			},
		]
		const { year } = this.state
		const month = moment.months().indexOf(this.state.month) + 1
		const date = { month, year }
		if (validate(validations, agentParams, this.props.enqueueSnackbar)) {
			const body = {
				...agentParams,
				tutor_id: this.props.match.params.id,
				date,
			}
			this.props.asignAgent(body, this.props.enqueueSnackbar)
			this.setState({ agentParams: {}, agentForm: false })
		}
	}

	handleChangeSubject(event) {
		const { target } = event
		const subjectParams = { ...this.state.subjectParams }
		subjectParams[target.name] = target.value
		this.setState({ subjectParams })
	}

	handleCreateSubject() {
		const { subjectParams } = this.state
		const validations = [
			{
				name: "subject_id",
				type: "select",
			},
		]
		const { year } = this.state
		const month = moment.months().indexOf(this.state.month) + 1
		const date = { month, year }
		if (validate(validations, subjectParams, this.props.enqueueSnackbar)) {
			const body = {
				...subjectParams,
				tutor_id: this.props.match.params.id,
				date,
			}
			this.props.asignSubject(body, this.props.enqueueSnackbar)
			this.setState(
				{ subjectParams: {}, subjectForm: false },
				this.render
			)
		}
	}

	handleDelete(row) {
		return () => {
			const { year } = this.state

			const month = moment.months().indexOf(this.state.month) + 1
			const date = { month, year }
			this.setState({
				openDelete: true,
				action: () => { this.props.deleteLesson(row.id, this.props.enqueueSnackbar, date) }
			})

		}
	}

	handleGoto(row) {
		return () => {
			this.props.history.push("/lesson/" + row.id + "/" + row.tutor.id)
		}
	}

	setOptions(info) {
		const result = [...info]
		const tutor = this.props.tutor.selected
		const subjects = tutor.subjects || []
		const { params } = this.state
		let selectedAgent
		if (tutor.agents) {
			selectedAgent = tutor.agents.find(
				(agent) => params.agent_id === agent.id
			)
		}

		const renamedSubject = subjects.map((subject) => {
			return {
				...subject,
				name: `${subject.name} - ${subject.subject_type}`,
			}
		})
		result.forEach((item, index) => {
			if (item.type === "select") {
				switch (item.name) {
					case "agent_id":
						result[index].options = tutor.agents || []
						break
					case "student_id":
						result[index].options =
							selectedAgent && selectedAgent.students
								? selectedAgent.students
								: []
						break
					case "subject_id":
						result[index].options = renamedSubject || []
						break
					default:
						break
				}
			}
		})
		return result
	}

	handleOpen(option) {
		const state = { ...this.state }
		state[option] = !state[option]
		this.setState(state)
	}

	handleEdit(event) {
		const { target } = event
		const body = {
			id: this.props.match.params.id,
			[target.name]: target.value,
		}
		const { year } = this.state

		const month = moment.months().indexOf(this.state.month) + 1
		const date = { month, year }
		this.props.editTutor(body, this.props.enqueueSnackbar, date)
	}

	handleUnasign(option, id) {
		const { year } = this.state
		const month = moment.months().indexOf(this.state.month) + 1
		const date = { month, year }
		if (option === "agent") {
			const body = {
				agent_id: id,
				tutor_id: this.props.match.params.id,
				date,
			}
			this.props.unasignAgent(body, this.props.enqueueSnackbar)
		} else {
			const body = {
				subject_id: id,
				tutor_id: this.props.match.params.id,
				date,
			}
			this.props.unasignSubject(body, this.props.enqueueSnackbar)
		}
	}

	handleMonth(event) {
		const { target } = event
		this.setState({ month: target.value })
		const result = moment.months().indexOf(target.value) + 1
		const { id } = this.props.match.params
		const { year } = this.state
		const date = { month: result, year }
		this.props.getTutor(id, date)
	}

	handleYear(event) {
		const { target } = event
		this.setState({ year: target.value })
		const { id } = this.props.match.params
		const month = moment.months().indexOf(this.state.month) + 1
		const date = { month, year: target.value }
		this.props.getTutor(id, date)
	}

	agentOptions(info) {
		const newInfo = [...info]
		const data = Array.isArray(this.props.agent.all)
			? this.props.agent.all
			: []
		const agents = this.props.tutor.selected.agents || []
		let result = filterOwned(data, agents)
		const school = this.props.tutor.selected.school
		if (school) {
			newInfo.forEach((element, index) => {
				if (element.name === "agent_id") {
					result = result.filter(
						(agent) =>
							agent.school.id ===
							this.props.tutor.selected.school.id
					)
					newInfo[index].options = result
				}
			})
		}
		return newInfo
	}

	subjectOptions(info) {
		const newInfo = [...info]
		const data = this.props.tutor.selected.school
			? this.props.tutor.selected.school.subjects
			: []
		const { subjects } = this.props.tutor.selected
		let result = filterOwned(data, subjects)
		result = result.map((subject) => {
			return {
				...subject,
				name: `${subject.name} - ${subject.subject_type}`,
			}
		})
		newInfo.forEach((element, index) => {
			if (element.name === "subject_id") {
				newInfo[index].options = result
			}
		})
		return newInfo
	}

	render() {
		const { classes, account, bank, match } = this.props
		const tutor = this.props.tutor.selected
		const {
			params,
			subjectParams,
			agentParams,
			agentForm,
			subjectForm,
			open,
			month,
			openDelete,
			action,
			year
		} = this.state
		const isTutor = account.user.level === "tutor"
		const isCoord = account.user.level === "coordinator"
		const isAdmin = account.user.level === "admin"
		const lessons = tutor.lessons || []
		const filtered = lessons.filter(
			(l) => moment(l.date).format("MMMM") === month
		)

		const actions = [
			{
				name: "Eliminar Clase",
				icon: ({ row }) => (
					<IconButton
						onClick={this.handleDelete(row)}
						color="secondary"
						disabled={row.payed || !(isAdmin || (isCoord && month === moment().format("MMMM")))}
					>
						<Delete />
					</IconButton>
				),
			},
		]

		return (
			<>
				<DeletePopup
					on={openDelete}
					close={() => this.setState({ openDelete: false, action: undefined })}
					action={action}
				/>
				{/* Check if params correspond with the tutor id */}
				{isTutor && checkUser(account, match, "tutor")}
				<div className={classes.container}>
					<div className={classes.topContainer}>
						<div>
							<UserCard
								data={tutor}
								able={isTutor || isCoord || isAdmin}
								onChange={this.handleEdit}
								banks={bank.all}
							/>
						</div>
						<div className={classes.resume}>
							<TutorResumeCard
								payed={tutor.payed}
								payment={tutor.total}
								month={month}
								adjustments={tutor.adjustments}
							/>
						</div>
					</div>
					<div className={classes.grid}>
						<Conditional
							condition={checkInfo(tutor, account)}
							hasElse
						>
							<>
								<div style={{ gridArea: "classes" }}>
									<div className={classes.inline}>
										<MonthSelector
											value={month}
											handleMonth={this.handleMonth}
										/>
										<YearSelector value={year} onChange={this.handleYear} />
									</div>
									<div className={classes.lessonTitle}>
										<Typography variant="h2">
											Clases de {tutor.name}
										</Typography>
										<Button
											color="primary"
											variant="contained"
											onClick={this.handleOpenClass}
											size="small"
										>
											Agregar Clase
										</Button>
									</div>
									<div style={{ marginTop: 12 }}>
										<Conditional
											condition={open}
											transition
										>
											<GeneralForm
												info={this.setOptions(
													lessonInfo
												)}
												title="Notificar Clase"
												handleChange={this.handleChange}
												create={this.handleCreate}
												params={params}
												submit="Notificar"
											/>
										</Conditional>
									</div>
									<Divider />
									<GeneralTable
										data={filtered || lessons}
										info={tutorLessonInfo}
										name="lessons"
										open={true}
										ResponsiveRender={ResponsiveLessonTutor}
										actions={actions}
									/>
								</div>
								<div style={{ gridArea: "agents" }}>
									<Asociations
										isTutor={isTutor}
										title="Apoderados"
										open={this.handleOpen}
										option={"agent"}
										unasign={this.handleUnasign}
										elements={tutor.agents || []}
									/>
								</div>
								<div style={{ gridArea: "subjects" }}>
									<Asociations
										isTutor={isTutor}
										title="Asignaturas"
										open={this.handleOpen}
										option={"subject"}
										unasign={this.handleUnasign}
										elements={tutor.subjects || []}
									/>
								</div>
								<div style={{ gridArea: "agent" }}>
									<Conditional
										condition={agentForm && !isTutor}
										transition
									>
										<GeneralForm
											info={this.agentOptions(agentInfo)}
											handleChange={
												this.handleChangeAgent
											}
											create={this.handleCreateAgent}
											title="Nuevo apoderado"
											params={agentParams}
											submit="asignar"
										/>
									</Conditional>
								</div>
								<div style={{ gridArea: "subject" }}>
									{" "}
									<Conditional
										condition={subjectForm && !isTutor}
										transition
									>
										<GeneralForm
											info={this.subjectOptions(
												subjectInfo
											)}
											handleChange={
												this.handleChangeSubject
											}
											create={this.handleCreateSubject}
											title="Nueva asignatura"
											params={subjectParams}
											submit="asignar"
										/>
									</Conditional>
								</div>
							</>
							<WelcomeMessage />
						</Conditional>
					</div>
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => getAllStore(state)

const mapDispatchToProps = (dispatch) =>
	combineDispatchers(
		dispatch,
		tutorDispatcher,
		agentDispatcher,
		schoolDispatcher,
		bankDispatcher
	)
export default withSnackbar(
	connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Tutor))
)
