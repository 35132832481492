import React, { Component } from "react"
import {
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    withStyles,
    Typography,
    Paper,
    Button,
} from "@material-ui/core"
import {
    getAllSchools,
    getSchoolCoordinators,
} from "../../Actions/SchoolsActions"
import { connect } from "react-redux"
import { defaultStyle } from "../../Utils/staticStyles"
import Dashboard from "./Dashboard"
import moment from 'moment'
import { autobind, firstUpper } from "../../Utils/functions"
import baseURL from "../../Router/url"

class AdminDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            option: "",
            id: "",
            month: moment(new Date()).format("MMMM"),
            year: moment(new Date()).format("YYYY")
        }

        autobind(AdminDashboard, this)

    }

    componentDidMount() {
        const { getAllSchools } = this.props
        getAllSchools()
    }

    renderMenu() {
        const schools = this.props.school.all || []
        return schools.map((school) => (
            <MenuItem value={school.id} key={school.id}>
                {school.name}
            </MenuItem>
        ))
    }

    componentDidUpdate(prevProps) {
        if (prevProps.school.coordinators !== this.props.school.coordinators)
            this.setState({
                id: this.props.school.coordinators[0].id,
            })
        return null
    }

    handleChange(event) {
        const option = event.target.value
        const schools = this.props.school.all
        const school = schools.find((s) => String(s.id) === String(option))
        this.props.getSchoolCoordinators(school.id)
        this.setState({ option })
    }

    handleMonth(event) {
        const { target } = event
        this.setState({ month: target.value })
    }

    handleYear(event) {
        const { target } = event
        this.setState({ year: target.value })
    }

    handleSend() {
        const month = moment.months().indexOf(this.state.month) + 1
        const { year } = this.state
        const body = { month, year, monthname: firstUpper(moment().month(this.state.month).format("MMMM")) }
        fetch(baseURL + `resume`, { headers: body }).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob)
                let a = document.createElement("a")
                a.href = url
                a.download = `resumen.xlsx`
                a.click()
            })
        })
    }

    renderMonths() {
        return moment.months().map((month) => (
            <MenuItem value={month} key={month}>
                {firstUpper(month)}
            </MenuItem>
        ))
    }

    renderYears() {
        const actualYear = parseInt(moment(new Date()).format("YYYY"), 10)
        const years = Array.from(Array(5)).map((value, index) => (actualYear + index - 2).toString())
        return years.map(year => (<MenuItem value={year}>{year}</MenuItem>))
    }

    render() {
        const { classes } = this.props
        const { option, id, year, month } = this.state
        return (
            <>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start'
                }}>
                    <div className={classes.container}>
                        <Typography variant="h1" className={classes.title}>
                            Resumen de Colegios
                        </Typography>
                        <FormControl>
                            <InputLabel>Seleccione Colegio</InputLabel>
                            <Select value={option} onChange={this.handleChange}>
                                {this.renderMenu()}
                            </Select>
                        </FormControl>
                    </div>
                    <Paper style={{
                        margin: 12,
                        padding: 12,
                        width: 300
                    }}>
                        <FormControl style={{ marginBottom: 12 }}>
                            <InputLabel>Seleccione Año</InputLabel>
                            <Select value={year} onChange={this.handleYear}>
                                {this.renderYears()}
                            </Select>
                        </FormControl>
                        <FormControl style={{ marginBottom: 12 }}>
                            <InputLabel>Seleccione Mes</InputLabel>
                            <Select value={month} onChange={this.handleMonth}>
                                {this.renderMonths()}
                            </Select>
                        </FormControl>
                        <div style={{ marginTop: 24, textAlign: 'end' }}>
                            <Button color="primary" variant="contained" onClick={this.handleSend} size="small">
                                Descargar Resumen
                            </Button>
                        </div>
                    </Paper>
                </div>
                {id && <Dashboard id={id} />}
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    school: state.school,
})

const mapDispatchToProps = (dispatch) => ({
    getAllSchools: () => dispatch(getAllSchools()),
    getSchoolCoordinators: (school_id) =>
        dispatch(getSchoolCoordinators(school_id)),
})

export default withStyles(defaultStyle)(
    connect(mapStateToProps, mapDispatchToProps)(AdminDashboard)
)
