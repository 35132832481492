import React from "react"
import moment from "moment"
import { Redirect } from "react-router-dom"
import baseURL from "../Router/url"
import { MenuItem, Tooltip, IconButton } from "@material-ui/core"
import { CheckCircleOutline } from "@material-ui/icons"

export const getCellValue = (element, cell) => {
    let result
    const key = cell.key
    const keys = cell.key.split("&")
    if (key !== "all") {
        if (keys.length > 1) {
            result = element[keys[0]]?.[keys[1]]
        } else {
            result = element[keys[0]]
        }
        if (cell.format) {
            return cell.format(result)
        } else {
            return result
        }
    } else {
        return cell.format(element)
    }
}

export const formatMoney = (value) => {
    if (!value) return "N/A"
    const separated = String(Math.round(value)).split(".")
    let decimal = ""
    if (separated.length > 1) {
        decimal = "," + separated[1]
    }
    const integer = separated[0]
    const str = Array.from(String(integer)).reverse()
    const result = []
    str.forEach((char, index) => {
        result.push(char)
        if ((index + 1) % 3 === 0 && index !== str.length - 1) {
            result.push(".")
        }
    })
    return (
        <span style={{ fontFamily: " monospace" }}>
            {"$ " + result.reverse().join("") + decimal}
        </span>
    )
}

export const firstUpper = (data) => {
    const array = Array.from(data)
    array[0] = array[0].toUpperCase()
    return array.join("")
}

export const formatHour = (value) => {
    const factors = value.split(".")
    const hours = factors[0]
    const minutes = parseFloat("0." + factors[1]) * 60
    return `${hours}Hrs ${Math.round(minutes)}min`
}

export const formatDate = (data) => {
    return moment(data).format("DD [de] MMM YYYY")
}

export const formatDateMobile = (data) => {
    return moment(data).format("MMM-DD")
}

export const renderStatusCell = (lesson) => {
    if (lesson.payed) {
        if (lesson.confirmpay) {
            return (
                <Tooltip title="Pago confirmado">
                    <div>
                        <IconButton disabled style={{ paddingRight: 0 }}>
                            <CheckCircleOutline style={{ color: "#249e24" }} />
                        </IconButton>
                    </div>
                </Tooltip>
            )
        }
        return (
            <Tooltip title="Pago en proceso de confirmación">
                <div>
                    <IconButton disabled style={{ paddingRight: 0 }}>
                        <CheckCircleOutline style={{ color: "#adaa2e" }} />
                    </IconButton>
                </div>
            </Tooltip>
        )
    }
    return (
        <Tooltip title="Clase no pagada">
            <div>
                <IconButton disabled style={{ paddingRight: 0 }}>
                    <CheckCircleOutline style={{ color: "#cb6a6d" }} />
                </IconButton>
            </div>
        </Tooltip>
    )
}

export const checkUser = (account, match, level) => {
    if (account.user.level !== "admin") {
        if (String(match.params.id) !== String(account.user.user.id)) {
            return <Redirect to={"/" + level + "/" + account.user.user.id} />
        } else {
            return null
        }
    } else {
        return null
    }
}

export const autobind = (object, self) => {
    const functions = Object.getOwnPropertyNames(object.prototype).filter((f) =>
        f.includes("handle")
    )
    functions.forEach((f) => {
        self[f] = self[f].bind(self)
    })
}

export const download = (path, name, date) => {
    return fetch(baseURL + `${path}/download`, { headers: date }).then((response) => {
        response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob)
            let a = document.createElement("a")
            a.href = url
            a.download = `${name}.xlsx`
            a.click()
        })
    })
}

export const combineDispatchers = (dispatch, ...dispatchers) => {
    let result = {}
    dispatchers.forEach((dispatcher) => {
        result = { ...result, ...dispatcher(dispatch) }
    })
    return result
}

export const renderMonths = () => {
    return moment.months().map((month) => (
        <MenuItem value={month} key={month}>
            {firstUpper(month)}
        </MenuItem>
    ))
}

export const checkInfo = (user, account) => {
    let ready = true
    if (
        account.user.level === "admin" ||
        account.user.level === "coordinator"
    ) {
        return ready
    }
    Object.keys(user).forEach((key) => {
        if (!key.includes("_id")) {
            if (
                user[key] === null ||
                user[key] === undefined ||
                user[key] === ""
            ) {
                ready = false
            }
        }
    })
    return ready
}

export const filterOwned = (toFilter, owned) => {
    return toFilter.filter((e) => {
        const ids = owned.map((a) => a.id)
        if (ids.indexOf(e.id) === -1) {
            return true
        }
        return false
    })
}
