import React from "react";
import { ExitToApp } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

export const subjectInfo = [
    { name: "name", label: "Nombre Asignatura", required: true },
    { name: "cost", label: "Cobro por hora de clase", required: true },
    { name: "payment", label: "Sueldo Tutor", required: true },
    {
        name: "subject_type_id",
        label: "Tipo de clase",
        required: true,
        type: "select",
        options: [],
    },
];

export const formInfo = [{ name: "name", label: "Nombre", required: true }];

export const tableInfo = [{ key: "name", label: "Nombre" }];

export const schoolTableActions = (self) => [
    {
        name: "Ver Colegio",
        icon: ({ row }) => (
            <IconButton onClick={self.handleSelect(row)} color="primary">
                <ExitToApp />
            </IconButton>
        ),
    },
];

export const discountForm = [
    {
        name: "tutor_id",
        label: "Tutor",
        required: true,
        type: "select",
        options: [],
    },
    {
        name: "agent_id",
        label: "Apoderado",
        required: true,
        type: "select",
        options: [],
    },
    { name: "lesson_amount", label: "Cada cuantas clases", required: true },
    { name: "cost", label: "Nuevo cobro", required: true },
    { name: "payment", label: "Nuevo pago a tutor", required: true },
    { name: "start_date", label: "Válido desde", required: true, type: "date" },
    { name: "end_date", label: "Válido hasta", required: true, type: "date" },
];
