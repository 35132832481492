import React, { Component } from "react";
import {
    Paper,
    Tabs,
    Tab,
    withStyles,
    TextField,
    Button,
} from "@material-ui/core";
import { withSnackbar } from "notistack";

const style = () => ({
    container: {
        width: "80%",
        margin: "auto",
        "@media (max-width:1000px)": {
            width: "100%",
        },
    },
    separated: {
        marginBottom: 24,
    },
    form: {
        padding: 24,
        position: "relative",
    },
    input: {
        margin: "12px 0",
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
});

class LoginFormHidden extends Component {
    constructor(props) {
        super(props);
        this.send = this.send.bind(this);
        this.handleKey = this.handleKey.bind(this);
    }

    send() {
        const { username, password } = this.props;
        const options = ["coordinator", "admin"];
        const body = {
            username,
            password,
            option: options[this.props.option],
        };
        this.props.handleLogin();
        this.props.login(body, this.props.enqueueSnackbar).then((response) => {
            if (response.payload.data.status === "error") {
                this.props.handleFalseLogin();
            }
        });
    }

    handleKey(event) {
        if (event.key === "Enter") {
            this.send();
        }
    }

    render() {
        const { classes, handleOption, option } = this.props;
        return (
            <div className={classes.container}>
                <Paper className={classes.separated} elevation={2}>
                    <Tabs
                        variant="fullWidth"
                        onChange={handleOption}
                        value={option}
                    >
                        <Tab label="Coordinador" />
                        <Tab label="Administrador" />
                    </Tabs>
                </Paper>
                <Paper className={classes.form} elevation={2}>
                    <TextField
                        value={this.props.username}
                        onChange={this.props.handleChange}
                        name="username"
                        className={classes.input}
                        label="Usuario"
                        placeholder="..."
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        onKeyPress={this.handleKey}
                    />
                    <TextField
                        className={classes.input}
                        label="Contraseña"
                        name="password"
                        value={this.props.password}
                        type="password"
                        placeholder="..."
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        onChange={this.props.handleChange}
                        onKeyPress={this.handleKey}
                    />
                    <div className={classes.buttons}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            style={{ marginRight: 6 }}
                            onClick={this.props.handleOpen}
                        >
                            Olvide mi contraseña
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={this.send}
                        >
                            Ingresar
                        </Button>
                    </div>
                </Paper>
            </div>
        );
    }
}

export default withStyles(style)(withSnackbar(LoginFormHidden));
