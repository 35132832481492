import React, { Component } from "react"
import {
    Typography,
    Button,
    Divider,
    withStyles,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core"
import GeneralTable from "../../Shared/GeneralTable"
import { connect } from "react-redux"
import {
    getCoordPayments,
    payCoordinatorsAction,
} from "../../Actions/CoordinatorActions"
import { paymentsInfo } from "../Tutors/tutorInfo"
import moment from "moment"
import { autobind } from "../../Utils/functions"
import baseURL from "../../Router/url"
import LoaderSpinner from "../../Shared/Loader/LoaderSpinner"

const style = () => ({
    title: {
        padding: "12px 12px 0 12px",
    },
    inline: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    inlineDate: {
        display: "flex",
        alignItems: "center",
    }
})

function getMonthNumber(monthName) {
    return moment.months().indexOf(monthName) + 1
}

class CoordPaymentsTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            params: {
                sdate: moment(new Date()).format("YYYY-MM-[01]"),
                edate: moment(new Date())
                    .add(1, "month")
                    .format("YYYY-MM-[01]"),
                smonth: moment(new Date()).format("MMMM"),
                emonth: moment(new Date()).add(1, "month").format("MMMM"),
                syear: moment(new Date()).format("YYYY"),
                eyear: moment(new Date()).add(1, "month").format("YYYY"),
            },
        }
        autobind(CoordPaymentsTable, this)
    }

    async componentDidMount() {
        const { getCoordPayments } = this.props
        const { params } = this.state
        const date = {
            sdate: params.sdate,
            edate: params.edate,
        }
        this.setState({ loading: true })
        await getCoordPayments(date)
        this.setState({ loading: false })
    }

    handleReload() {
        this.componentDidMount()
    }

    handleChange(event) {
        const { target } = event
        const params = { ...this.state.params }
        params[target.name] = target.value
        params.sdate = `${params.syear}-${getMonthNumber(params.smonth)}-01`
        params.edate = `${params.eyear}-${getMonthNumber(params.emonth)}-01`
        this.setState({ params })
    }

    renderMonths() {
        const months = moment.months()
        return months.map((month) => (
            <MenuItem value={month} key={month}>
                {month}
            </MenuItem>
        ))
    }

    renderYears() {
        const startYear = 2020
        const actualYear = parseInt(moment(new Date()).format("YYYY"), 10)
        const finalYear = actualYear + 2
        const years = []
        for (let i = startYear; i < finalYear; i += 1) {
            years.push(i)
        }
        return years.map((year) => (
            <MenuItem value={year} key={year}>
                {year}
            </MenuItem>
        ))
    }

    renderHeader() {
        const { params, loading } = this.state
        const { classes } = this.props
        return (
            <>
                <div className={classes.inlineDate}>
                    <FormControl style={{ margin: "6px 6px", minWidth: 229 }}>
                        <InputLabel shrink>Mes desde</InputLabel>
                        <Select
                            disabled={loading}
                            value={moment(params.sdate).format("MMMM")}
                            onChange={this.handleChange}
                            name="smonth"
                        >
                            {this.renderMonths()}
                        </Select>
                    </FormControl>

                    <FormControl style={{ margin: "6px 6px", minWidth: 100 }}>
                        <InputLabel shrink>Año desde</InputLabel>
                        <Select
                            disabled={loading}
                            value={moment(params.sdate).format("YYYY")}
                            onChange={this.handleChange}
                            name="syear"
                        >
                            {this.renderYears()}
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.inlineDate}>
                    <FormControl style={{ margin: "6px 6px", minWidth: 229 }}>
                        <InputLabel shrink>Mes hasta</InputLabel>
                        <Select
                            disabled={loading}
                            value={moment(params.edate).format("MMMM")}
                            onChange={this.handleChange}
                            name="emonth"
                        >
                            {this.renderMonths()}
                        </Select>
                    </FormControl>

                    <FormControl style={{ margin: "6px 6px", minWidth: 100 }}>
                        <InputLabel shrink>Año hasta</InputLabel>
                        <Select
                            disabled={loading}
                            value={moment(params.edate).format("YYYY")}
                            onChange={this.handleChange}
                            name="eyear"
                        >
                            {this.renderYears()}
                        </Select>
                    </FormControl>
                </div>
                <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={this.handleReload}
                    style={{ margin: 6 }}
                >
                    Filtrar
                </Button>
            </>
        )
    }

    download = () => {
        const { params } = this.state
        const date = {
            sdate: params.sdate,
            edate: params.edate,
        }
        fetch(baseURL + "coordinators/download", { headers: date }).then(
            (response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob)
                    let a = document.createElement("a")
                    a.href = url
                    a.download = "Coordinadores.xlsx"
                    a.click()
                })
            }
        )
    };

    downloadWithoutDiscounts = () => {
        const { params } = this.state
        const date = {
            sdate: params.sdate,
            edate: params.edate,
            nodiscounts: true,
        }
        fetch(baseURL + "coordinators/download", { headers: date }).then(
            (response) => {
                response.blob().then((blob) => {
                    let url = window.URL.createObjectURL(blob)
                    let a = document.createElement("a")
                    a.href = url
                    a.download = "Coordinadores.xlsx"
                    a.click()
                })
            }
        )
    }

    render() {
        const { actions, classes, coordinator } = this.props
        const { params, loading } = this.state
        const date = {
            sdate: params.sdate,
            edate: params.edate,
        }
        const disabled =
            moment(params.edate).month() - moment(params.sdate).month() > 1
        return (
            <>
                <div className={`${classes.inline} ${classes.title}`}>
                    <Typography variant="h2">
                        Tabla de Sueldos Pendientes
                    </Typography>
                    <div>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            style={{ marginRight: 12 }}
                            disabled={disabled}
                            onClick={() => this.props.payCoordinators(date)}
                        >
                            Marcar Sueldos como Pagados
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={this.download}
                            size="small"
                            style={{ marginRight: 12 }}
                        >
                            Exportar Tabla sueldos
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={this.downloadWithoutDiscounts}
                            size="small"
                        >
                            Exportar Tabla sueldos sin descuentos
                        </Button>
                    </div>
                </div>
                <Divider />
                {this.renderHeader()}
                {loading ? <LoaderSpinner /> :
                    <GeneralTable
                        data={coordinator.payments}
                        actions={actions}
                        info={paymentsInfo}
                        name="payments"
                        open
                        maxHeight={500}
                    />
                }

            </>
        )
    }
}

const mapStateToProps = (state) => ({
    coordinator: state.coordinator,
})

const mapDispatchToProps = (dispatch) => ({
    getCoordPayments: (date) => dispatch(getCoordPayments(date)),
    payCoordinators: (date) => dispatch(payCoordinatorsAction(date)),
})

export default withStyles(style)(
    connect(mapStateToProps, mapDispatchToProps)(CoordPaymentsTable)
)
