import React from "react";
import "./App.css";
import Routes from "./Router/Routes";
import { connect } from "react-redux";
import SideBar from "./Shared/SideBar";
import Header from "./Shared/Header";
import moment from "moment";
import "moment/locale/es";

class App extends React.Component {
    componentDidMount() {
        moment.locale("es");
    }

    getRouteComponent() {
        return <Routes />;
    }

    userLayout() {
        const { account } = this.props;
        if (account.user.type === 0) {
            return (
                <div className="main-container">
                    <SideBar type={0} />
                    <div className="main-content">
                        <div className="header-container">
                            <Header />
                        </div>
                        {this.getRouteComponent()}
                    </div>
                </div>
            );
        } else if (account.user.type === 1) {
            return (
                <div className="main-container">
                    <SideBar type={1} />
                    <div className="main-content">
                        <div className="header-container">
                            <Header />
                        </div>
                        {this.getRouteComponent()}
                    </div>
                </div>
            );
        } else if (account.user.type === 2) {
            return (
                <div className="main-content">
                    <div className="header-container">
                        <Header />
                    </div>
                    {this.getRouteComponent()}
                </div>
            );
        }
    }
    render() {
        const { account } = this.props;
        const loggedIn = account.user.user && account.user.user.id;
        return (
            <div className="App">
                {loggedIn ? this.userLayout() : this.getRouteComponent()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    account: state.account,
});

export default connect(mapStateToProps)(App);
