import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import DeletePopup from "./DeletePopup";
import "./animation.css";
import CoordinatorCard from "./Cards/CoordinatorCard";
import { autobind } from "../Utils/functions";
import TutorCard from "./Cards/TutorCard";
import AgentCard from "./Cards/AgentCard";

const style = () => ({
    listItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
});

class ShowList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            on: false,
            deleteAction: undefined,
            deleteId: "",
            data: this.props.data.slice(0, 1),
        };
        autobind(ShowList, this);
        this.handleOpenPopup = this.handleOpenPopup.bind(this);
        this.delete = this.delete.bind(this);
    }
    componentDidMount() {
        this.recursive();
    }
    handleGoto(id) {
        this.props.goto(id);
    }

    recursive = () => {
        setTimeout(() => {
            const hasMore = this.state.data.length < this.props.data.length;
            this.setState((prev, props) => ({
                data: props.data.slice(0, prev.data.length + 1),
            }));
            if (hasMore) {
                this.recursive();
            }
        }, 50);
    };

    delete(id) {
        this.props.delete(id);
    }

    handleOpenPopup() {
        this.setState({ on: !this.state.on });
    }

    handleDelete(id) {
        this.setState(
            {
                deleteAction: this.delete,
                deleteId: id,
            },
            this.handleOpenPopup
        );
    }

    renderList() {
        const { option } = this.props;
        const { data } = this.state;
        return data.map((element) => (
            <div key={element.id} className={"fade-in"}>
                {option === "coordinator" && (
                    <CoordinatorCard
                        data={element}
                        delete={this.handleDelete}
                        goto={this.handleGoto}
                    />
                )}
                {option === "tutor" && (
                    <TutorCard
                        data={element}
                        delete={this.handleDelete}
                        goto={this.handleGoto}
                    />
                )}
                {option === "agent" && (
                    <AgentCard
                        data={element}
                        delete={this.handleDelete}
                        goto={this.handleGoto}
                    />
                )}
            </div>
        ));
    }
    render() {
        const { on, deleteAction, deleteId } = this.state;
        return (
            <div
                style={{
                    marginTop: 12,
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    gridGap: 12,
                }}
            >
                {this.renderList()}
                <DeletePopup
                    on={on}
                    action={deleteAction}
                    deleteId={deleteId}
                    close={this.handleOpenPopup}
                />
            </div>
        );
    }
}

export default withStyles(style)(ShowList);
