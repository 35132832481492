const coordinatorInfo = [
    { name: "name", label: "Nombre", required: true },
    { name: "email", label: "Correo", required: true },
    { name: "rut", label: "Rut", required: true, type: "rut" },
    { name: "account", label: "Numero de Cuenta" },
    { name: "sii_rut", label: "Rut SII", type: "rut" },
    {
        name: "school_id",
        label: "Colegio",
        required: true,
        type: "select",
        option: []
    },
    {
        name: "bank_id",
        label: "Banco",
        type: "select",
        options: []
    }
];

const asignInfo = [
    {
        name: "tutor_id",
        label: "Tutor",
        required: true,
        type: "select",
        options: []
    }
];

export { coordinatorInfo, asignInfo };
