import React, { Component } from "react";
import {
    withStyles,
    Typography,
    Divider,
    Button,
    ListItem,
    ListItemText,
    List,
    Dialog,
    DialogTitle,
    DialogContent,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
    getLesson,
    confirmLesson,
    payLesson,
    confirmLessonPayment,
    editLesson,
    payTransfer,
} from "../../Actions/LessonActions";
import Lazy from "../../Shared/Lazy";
import InfoLine from "../../Shared/InfoLine";
import { reduxMaker } from "../../Utils/reduxMaker";
import { formatHour, autobind } from "../../Utils/functions";
import { CheckCircleOutline } from "@material-ui/icons";
import GeneralForm from "../../Shared/GeneralForm";
import { getTutor } from "../../Actions/TutorActions";
import Transition from "../../Shared/Transition";
import Conditional from "../../Shared/Conditional";
import moment from "moment";

const style = (theme) => ({
    container: {
        padding: 12,
    },
    centeredGrid: {
        display: "grid",
        gridTemplateColumns: "1fr  1fr  1fr",
        width: "55%",
        margin: "auto",
        marginTop: 24,
        gridGap: "5%",
    },
    step: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: 400,
        marginBottom: 8,
    },
    confirmed: {
        color: theme.palette.confirm.main,
        transition: "all 0.3s ease-in-out",
    },
    notConfirmed: {
        color: theme.palette.error.main,
        transition: "all 0.3s ease-in-out",
    },
});

class Lesson extends Component {
    constructor(props) {
        super(props);
        this.state = {
            params: {},
            open: false,
            show: false,
        };
        autobind(Lesson, this);
    }
    componentDidMount() {
        const { match } = this.props;
        const { id, tutor_id } = match.params;
        this.props.getLesson(id);
        this.props.getTutor(tutor_id);
        this.fillForm();
    }

    fillForm() {
        const selected = this.props.lesson;
        if (selected && selected.duration) {
            const params = { ...this.state.params };
            const factors = selected.duration.split(".");
            const hours = factors[0];
            const minutes = parseFloat("0." + factors[1]) * 60;
            params.date = selected.date;
            params.hours = hours;
            params.minutes = minutes;
            params.agent_id = selected.agent_id;
            params.student_id = selected.student.id;
            params.subject_id = selected.subject_id;
            this.setState({ params });
        }
    }

    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(prevProps.lesson) !==
            JSON.stringify(this.props.lesson)
        ) {
            this.fillForm();
        }
    }

    handleOpen() {
        this.setState({ open: !this.state.open });
    }

    handleShow() {
        this.setState({ show: true });
    }

    handleSend(id, option) {
        switch (option) {
            case 0: {
                return () => this.props.confirmLesson(id);
            }
            case 1: {
                return () => {
                    this.handleOpen();
                    this.props.payLesson(id).then((response) => {
                        console.log(response);
                        this.redirectToPayment(
                            response.payload.data.payment_url
                        );
                    });
                };
            }
            case 2: {
                return () => this.props.confirmLessonPayment(id);
            }
            case 3: {
                return () => {
                    this.handleOpen();
                    this.handleShow();
                };
            }
            default: {
                return null;
            }
        }
    }

    redirectToPayment(url) {
        window.location = url;
    }

    handleChange(event) {
        const { target } = event;
        const params = { ...this.state.params };
        params[target.name] = target.value;
        this.setState({ params });
    }

    setOptions(info) {
        const result = [...info];
        const tutor = this.props.tutor.selected;
        const subjects = tutor.subjects || [];
        const { params } = this.state;
        let selectedAgent;
        if (tutor.agents) {
            selectedAgent = tutor.agents.find(
                (agent) => params.agent_id === agent.id
            );
        }

        const renamedSubject = subjects.map((subject) => {
            return {
                ...subject,
                name: `${subject.name} - ${subject.subject_type}`,
            };
        });
        result.forEach((item, index) => {
            if (item.type === "select") {
                switch (item.name) {
                    case "agent_id":
                        result[index].options = tutor.agents || [];
                        break;
                    case "student_id":
                        result[index].options =
                            selectedAgent && selectedAgent.students
                                ? selectedAgent.students
                                : [];
                        break;
                    case "subject_id":
                        result[index].options = renamedSubject || [];
                        break;
                    default:
                        break;
                }
            }
        });
        return result;
    }

    handleEdit() {
        const body = { ...this.state.params };
        body.duration =
            parseInt(this.state.params.hours) +
            parseInt(this.state.params.minutes) / 60;
        this.props.editLesson(this.props.match.params.id, body);
    }

    handleOpenEdit() {
        this.setState({ openEdit: !this.state.openEdit });
    }

    render() {
        const { classes, lesson, account, tutor } = this.props;
        const { params, openEdit, open, show } = this.state;
        const { selected } = tutor;
        let info = [];
        if (selected) {
            info = [
                { name: "date", label: "Fecha", required: true, type: "date" },
                {
                    name: "hours",
                    label: "Horas",
                    required: true,
                    type: "select",
                    options: [
                        { name: 1, id: 1 },
                        { name: 2, id: 2 },
                        { name: 3, id: 3 },
                        { name: 4, id: 4 },
                    ],
                },
                {
                    name: "minutes",
                    label: "Minutos",
                    required: true,
                    type: "select",
                    options: [
                        { name: 0, id: 0 },
                        { name: 5, id: 5 },
                        { name: 10, id: 10 },
                        { name: 15, id: 15 },
                        { name: 20, id: 20 },
                        { name: 25, id: 25 },
                        { name: 30, id: 30 },
                        { name: 35, id: 35 },
                        { name: 40, id: 40 },
                        { name: 45, id: 45 },
                        { name: 50, id: 50 },
                        { name: 55, id: 55 },
                    ],
                },
                {
                    name: "agent_id",
                    label: "Apoderado",
                    required: true,
                    type: "select",
                    options: selected.agents
                        ? selected.agents.map((agent) => ({
                              name: agent.name,
                              id: agent.id,
                          }))
                        : [],
                },
                {
                    name: "student_id",
                    label: "Alumno",
                    required: true,
                    type: "select",
                    options: [],
                },
                {
                    name: "subject_id",
                    label: "Asignatura",
                    required: true,
                    type: "select",
                    options: selected.subjects
                        ? selected.subjects.map((subject) => {
                              return {
                                  name:
                                      subject.name +
                                      " - " +
                                      subject.subject_type,
                                  id: subject.id,
                              };
                          })
                        : [],
                },
            ];
        }
        return (
            <>
                <Dialog open={open} onClose={this.handleOpen}>
                    <DialogTitle>Seleccione su metodo de pago</DialogTitle>
                    <DialogContent style={{ padding: 24 }}>
                        <Button
                            color="primary"
                            variant="contained"
                            style={{
                                display: "block",
                                width: 150,
                                margin: "auto",
                                marginBottom: 24,
                            }}
                            onClick={this.handleSend(lesson.id, 1)}
                        >
                            Pago online
                        </Button>
                        <Button
                            color="primary"
                            style={{
                                display: "block",
                                width: 150,
                                margin: "auto",
                            }}
                            variant="outlined"
                            onClick={this.handleSend(lesson.id, 3)}
                        >
                            Transferencia
                        </Button>
                    </DialogContent>
                </Dialog>
                {lesson.subject && lesson.student ? (
                    <Lazy loading={this.props.loading}>
                        <div className={classes.container}>
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                                    gridTemplateAreas: "'info info . form'",
                                    gridGap: 24,
                                }}
                            >
                                <div
                                    className={classes.generalInfo}
                                    style={{ gridArea: "info" }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography variant="h1">
                                            Clase de {lesson.subject.name}
                                        </Typography>
                                        {/* <IconButton
                                            size="small"
                                            onClick={this.handleOpenEdit}
                                        >
                                            <Edit />
                                        </IconButton> */}
                                    </div>
                                    <Divider />
                                    <InfoLine
                                        name="Tutor:"
                                        value={lesson.tutor.name}
                                    />
                                    <InfoLine
                                        name="Apoderado:"
                                        value={lesson.agent.name}
                                    />
                                    <InfoLine
                                        name="Estudiante:"
                                        value={lesson.student.name}
                                    />
                                    <InfoLine
                                        name="Asignatura:"
                                        value={`${lesson.subject.name} - ${lesson.subject.subject_type}`}
                                    />
                                    <InfoLine
                                        name="Duración:"
                                        value={formatHour(lesson.duration)}
                                    />
                                </div>
                                <div style={{ gridArea: "form" }}>
                                    <Transition on={openEdit}>
                                        <GeneralForm
                                            title="Editar Clase"
                                            info={this.setOptions(info)}
                                            params={params}
                                            handleChange={this.handleChange}
                                            submit="Editar"
                                            create={this.handleEdit}
                                        />
                                    </Transition>
                                </div>
                            </div>

                            <Typography
                                variant="h2"
                                style={{ margin: "24px 0" }}
                            >
                                Estado de la clase
                            </Typography>
                            <Divider />
                            {/* <div className={classes.step}>
                                <div style={{ display: "flex" }}>
                                    <CheckCircleOutline
                                        color="primary"
                                        className={
                                            lesson.confirmed
                                                ? classes.confirmed
                                                : classes.notConfirmed
                                        }
                                    />
                                    <Typography
                                        variant="subtitle1"
                                        style={{ marginLeft: 24 }}
                                    >
                                        Confirmada por tutor
                                    </Typography>
                                </div>
                                <Button
                                    size="small"
                                    style={{ width: 150 }}
                                    variant="contained"
                                    onClick={this.handleSend(lesson.id, 0)}
                                    disabled={
                                        account.user.level === "agent" ||
                                        lesson.confirmed
                                    }
                                >
                                    Confirmar clase
                                </Button>
                            </div> */}
                            <div className={classes.step}>
                                <div style={{ display: "flex" }}>
                                    <CheckCircleOutline
                                        color="primary"
                                        className={lesson.payed}
                                    />
                                    <Button
                                        size="small"
                                        style={{ width: 150 }}
                                        variant="contained"
                                        onClick={this.handleSend(lesson.id, 2)}
                                        disabled={
                                            !lesson.payed ||
                                            account.user.level !== "admin" ||
                                            lesson.confirmpay
                                        }
                                    >
                                        Realizar pago
                                    </Button>
                                </div>

                                <Conditional
                                    condition={
                                        lesson.status &&
                                        lesson.status !== "done"
                                    }
                                >
                                    <Typography
                                        variant="subtitle1"
                                        style={{ marginLeft: 24 }}
                                    >
                                        Pago en proceso de confirmación...
                                    </Typography>
                                </Conditional>
                                <Conditional
                                    condition={
                                        lesson.status &&
                                        lesson.status === "done"
                                    }
                                >
                                    <Typography
                                        variant="subtitle1"
                                        style={{ marginLeft: 24 }}
                                    >
                                        Pagado el:{" "}
                                        {moment(lesson.payment_date).format(
                                            "DD [de] MMMM [del] YYYY"
                                        )}
                                    </Typography>
                                </Conditional>
                            </div>
                            <div className={classes.step}>
                                <div style={{ display: "flex" }}>
                                    <CheckCircleOutline
                                        color="primary"
                                        className={
                                            lesson.confirmpay
                                                ? classes.confirmed
                                                : classes.notConfirmed
                                        }
                                    />
                                    <Typography
                                        variant="subtitle1"
                                        style={{ marginLeft: 24 }}
                                    >
                                        Pago confirmado
                                    </Typography>
                                </div>
                                <Button
                                    size="small"
                                    style={{ width: 150 }}
                                    variant="contained"
                                    onClick={this.handleSend(lesson.id, 2)}
                                    disabled={
                                        !lesson.payed ||
                                        account.user.level !== "admin" ||
                                        lesson.confirmpay
                                    }
                                >
                                    Confirmar pago
                                </Button>
                            </div>
                            <Conditional condition={show}>
                                <Divider />
                                <div>
                                    <Typography variant="subtitle1">
                                        Debe hacer una transferencia a los
                                        siguientes datos.
                                    </Typography>
                                    <List>
                                        <ListItem>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        Rut: 12345678-9
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        Banco: Santander
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        {`Monto: $ ${Math.floor(
                                                            parseInt(
                                                                lesson.subject
                                                                    .cost
                                                            ) *
                                                                parseFloat(
                                                                    lesson.duration
                                                                )
                                                        )}`}
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                    <Typography variant="subtitle1">
                                        Favor agregar al mensaje de la
                                        transferencia:{" "}
                                        {`"Pago clase de ${lesson.subject.name} por ${lesson.tutor.name}"`}
                                    </Typography>
                                </div>
                            </Conditional>
                        </div>
                    </Lazy>
                ) : (
                    <div />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    lesson: state.lesson.selected,
    account: state.account,
    tutor: state.tutor,
    types: state.types,
});

const mapDispatchToProps = (dispatch) => ({
    getLesson: (id) => reduxMaker(getLesson(id), dispatch),
    confirmLesson: (id) => dispatch(confirmLesson(id)),
    payLesson: (id) => dispatch(payLesson(id)),
    payTransfer: (id) => dispatch(payTransfer(id)),
    confirmLessonPayment: (id) => dispatch(confirmLessonPayment(id)),
    getTutor: (id) => dispatch(getTutor(id)),
    editLesson: (id, body) => dispatch(editLesson(id, body)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(style)(Lesson));
