export const dict = {
    name: "Nombre",
    email: "Correo",
    rut: "RUT",
    account: "Numero de Cuenta",
    sii_rut: "Rut SII",
    school_id: "Colegio",
    bank_id: "Banco",
    phone: "Teléfono",
    address: "Dirección",
    code: "Código",
    student_id: "Alumno",
    agent_id: "Apoderado",
    subject_id: "Asignatura",
    tutor_id: "Tutor",
    date: "Fecha",
    hours: "Horas",
    minutes: "Minutos",
    lesson_amount: "Cantidad de clases",
    cost: "Cobro",
    start_date: "Fecha de inicio",
    end_date: "Fecha de término",
    payment_mail: "Correo de cobro"
}

export const validate = (keys, params, snackbar) => {
    let result = true
    keys.forEach((element) => {
        if (element.type) {
            switch (element.type) {
                case "rut": {
                    if (
                        params[element.name] === "" ||
                        params[element.name] === undefined
                    ) {
                        result = false
                        snackbar(
                            `El campo ${dict[element.name]} es obligatorio!`,
                            {
                                variant: "warning",
                            }
                        )
                    } else {
                        if (
                            params[element.name].length < 9 ||
                            params[element.name].length > 10
                        ) {
                            result = false
                            snackbar(`Debe ingresar un RUT valido!`, {
                                variant: "warning",
                            })
                        }
                    }
                    break
                }
                case "select": {
                    if (
                        params[element.name] === "" ||
                        params[element.name] === undefined
                    ) {
                        result = false
                        snackbar(
                            `El campo ${dict[element.name]} es obligatorio!`,
                            {
                                variant: "warning",
                            }
                        )
                    }
                    break
                }
                default: {
                    if (
                        params[element.name] === "" ||
                        params[element.name] === undefined
                    ) {
                        result = false
                        snackbar(
                            `El campo ${dict[element.name]} es obligatorio!`,
                            {
                                variant: "warning",
                            }
                        )
                    }
                }
            }
        } else {
            if (
                params[element.name] === "" ||
                params[element.name] === undefined
            ) {
                result = false
                snackbar(`El campo ${dict[element.name]} es obligatorio!`, {
                    variant: "warning",
                })
            }
        }
    })
    return result
}

export const getRequired = (info) => {
    return info
        .map(
            (input) => input.required && { name: input.name, type: input.type }
        )
        .filter((input) => input)
}
