import instance from "../Utils/Instance"

export const getAllRegisters = () => {
    const REQUEST = instance({
        method: "get",
        url: `registers`,
    })
    return {
        type: "getAllRegisters",
        payload: REQUEST,
    }
}

export const deleteRegister = (id) => {
    const REQUEST = instance({
        method: "delete",
        url: `registers/${id}`
    })
    return {
        type: "deleteRegister",
        payload: REQUEST
    }
}

export const registerDispatcher = (dispatch) => ({
    getAllRegisters: () => dispatch(getAllRegisters()),
})
