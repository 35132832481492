import React, { Component } from "react"
import {
    Typography,
    Divider,
    withStyles,
    Icon,
    Button,
    Paper,
    Collapse,
} from "@material-ui/core"
import { AccountCircle } from "@material-ui/icons"
import InfoLine from "./InfoLine"
import { dict, getRequired, validate } from "../Utils/validations"
import GeneralForm from "./GeneralForm"
import { withSnackbar } from "notistack"
import { connect } from "react-redux"
import { changePassword, resetAccountAction } from "../Actions/LoginActions"
import { autobind } from "../Utils/functions"

const style = (theme) => ({
    title: {
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 12,
    },
    subtitle: {
        color: "grey",
    },
    icon: {
        color: theme.palette.secondary.main,
    },
    info: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "6px 0",
    },
    container: {
        padding: 12,
    },
})

const infoForm = [
    {
        name: "old_password",
        label: "Contraseña actual",
        type: "password",
        required: true,
    },
    {
        name: "new_password",
        label: "Contraseña nueva",
        type: "password",
        required: true,
    },
]

class UserCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            params: {},
            open: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleCreate = this.handleCreate.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        autobind(UserCard, this)
    }

    handleChange(event) {
        const { target } = event
        const params = { ...this.state.params }
        params[target.name] = target.value
        this.setState({ params })
    }

    handleCreate() {
        const { params } = this.state
        const user = this.props.account.user.user
        const level = this.props.account.user.level
        const validations = getRequired(infoForm)
        if (validate(validations, params, this.props.enqueueSnackbar)) {
            const body = {
                ...params,
                user_id: user.id,
                level,
            }
            this.props.changePassword(body, this.props.enqueueSnackbar)
            this.setState({ params: {} })
        }
    }

    handleOpen() {
        this.setState({ open: !this.state.open })
    }

    handleResetUser() {
        const { resetAccount, data } = this.props
        const body = {
            level: data.level,
            id: data.user_id
        }
        resetAccount(body)
    }

    renderLines() {
        const { data, onChange, able, banks } = this.props
        const lines = []
        console.log(data)
        Object.keys(data).forEach((key) => {
            if (dict[key] && !key.includes("_id")) {
                const line = (
                    <InfoLine
                        key={key}
                        name={dict[key]}
                        value={data[key]}
                        realName={key}
                        change={onChange}
                        type={key}
                        able={able}
                    />
                )
                lines.push(line)
            }
        })
        if (Object.keys(data).indexOf("bank_id") !== -1 && banks) {
            lines.push(
                <InfoLine
                    name={dict["bank_id"]}
                    value={data.bank ? data.bank.name : ""}
                    realName={"bank_id"}
                    change={onChange}
                    type={"select"}
                    options={banks || []}
                    able={able}
                    key={"bank_id"}
                />
            )
        }
        if (data?.school?.name) {
            lines.push(
                <InfoLine
                    name={'Colegio'}
                    value={data?.school?.name}
                    realName={"school_id"}
                    change={undefined}
                    type={"text"}
                    able={false}
                    key={"school_id"}
                />
            )
        }
        return lines
    }
    render() {
        const { params, open } = this.state
        const { classes, data, account } = this.props
        return (
            <Paper style={this.props.style} className={classes.container}>
                <div className={classes.title}>
                    <Typography variant="h1">Perfil de {data.name}</Typography>
                    <Icon className={classes.icon}>
                        <AccountCircle />
                    </Icon>
                </div>
                <Divider />
                <div className={classes.subtitle}>{this.renderLines()}</div>
                {data.level === account.user.level &&
                    String(data.id) === String(account.user.user.id) && (
                        <>
                            <div style={{ textAlign: "end", margin: "12px 0" }}>
                                <Button
                                    color="primary"
                                    size="small"
                                    variant="outlined"
                                    onClick={this.handleOpen}
                                >
                                    Cambiar Contraseña
                                </Button>
                            </div>
                            <Collapse in={open}>
                                <GeneralForm
                                    params={params}
                                    info={infoForm}
                                    title="Cambio de contraseña"
                                    handleChange={this.handleChange}
                                    create={this.handleCreate}
                                    submit="Cambiar"
                                />
                            </Collapse>
                        </>
                    )}
                {account.user.level === "admin" &&
                    <>
                        <div style={{ textAlign: "end", margin: "12px 0" }}>
                            <Button
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={this.handleResetUser}
                            >
                                Reestablecer
                            </Button>
                        </div>
                    </>
                }
            </Paper>
        )
    }
}

const mapStateToProps = (state) => ({
    account: state.account,
})

const mapDispatchToProps = (dispatch) => ({
    changePassword: (body, snackbar) => dispatch(changePassword(body, snackbar)),
    resetAccount: body => dispatch(resetAccountAction(body))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withSnackbar(withStyles(style)(UserCard)))
