import React, { Component } from "react";
import { connect } from "react-redux";
import {
    getCoordinator,
    getTutors,
    asignTutor,
    resolve,
    unasignTutor,
    editCoordinator,
} from "../../Actions/CoordinatorActions";
import UserCard from "../../Shared/UserCard";
import { withStyles, Typography, Paper, Divider } from "@material-ui/core";
import { withSnackbar } from "notistack";
import ColorList from "../../Shared/ColorList";
import Comments from "../../Shared/Comments";
import { getAllBanks } from "../../Actions/BankAction";
import { checkUser, autobind } from "../../Utils/functions";

const style = () => ({
    grid: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: 24,
        marginTop: 12,
    },
    container: {
        padding: 12,
    },
});

class Coordinator extends Component {
    constructor(props) {
        super(props);
        autobind(Coordinator, this);
    }

    componentDidMount() {
        const { getCoordinator, match } = this.props;
        const { id } = match.params;
        getCoordinator(id);
        this.props.getAllBanks();
    }

    handleEdit(event) {
        const { target } = event;
        const body = {
            id: this.props.match.params.id,
            [target.name]: target.value,
        };
        this.props.editCoordinator(body, this.props.enqueueSnackbar);
    }

    handleGoto(id) {
        this.props.history.push("/tutor/" + id);
    }

    handleResolve(id) {
        const coord_id = this.props.match.params.id;
        const body = { comment_id: id };
        this.props.resolve(coord_id, body);
    }

    checkInfo() {
        const { coordinator, account } = this.props;
        let ready = true;
        if (account.user.level === "admin") {
            return ready;
        }
        Object.keys(coordinator).forEach((key) => {
            if (!key.includes("_id")) {
                if (
                    coordinator[key] === null ||
                    coordinator[key] === undefined ||
                    coordinator[key] === ""
                ) {
                    ready = false;
                }
            }
        });
        return ready;
    }

    render() {
        const { coordinator, classes, bank, account, match } = this.props;
        let tutors = [];
        let comments = [];
        if (coordinator) {
            tutors = coordinator.tutors;
            comments = coordinator.comments;
        }
        return (
            <>
                {checkUser(account, match, "coordinator")}
                <div className={classes.container}>
                    <UserCard
                        data={coordinator}
                        style={{ width: "60%" }}
                        able
                        onChange={this.handleEdit}
                        banks={bank.all}
                    />
                    {this.checkInfo() ? (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginTop: 48,
                                }}
                            >
                                <Typography variant="h2">
                                    Mis Tutores
                                </Typography>
                            </div>
                            <Divider />
                            <div style={{ maxHeight: 300, overflow: "auto" }}>
                                <ColorList
                                    data={tutors || []}
                                    goto={this.handleGoto}
                                />
                            </div>
                            <Comments
                                data={comments}
                                resolve={this.handleResolve}
                            />
                        </>
                    ) : (
                        <div style={{ marginTop: 128 }}>
                            <Paper
                                style={{
                                    width: "60%",
                                    margin: "auto",
                                    padding: 24,
                                }}
                            >
                                <Typography
                                    variant="h2"
                                    style={{ marginBottom: 12 }}
                                >
                                    Bienvenido!
                                </Typography>
                                <Typography variant="body2">
                                    Por favor rellena la información necesaria
                                    para completar el proceso de ingreso y
                                    comenzar a utilizar la aplicación. Recuerda
                                    que la informacion que ingreses será
                                    considerara como correcta y se utilizará
                                    para enviar correos y realizar
                                    transacciones. Si te equivocas no te
                                    preocupes siempre puedes volver a editar la
                                    información :)
                                </Typography>
                            </Paper>
                        </div>
                    )}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    account: state.account,
    coordinator: state.coordinator.selected,
    bank: state.bank,
});

const mapDispatchToProps = (dispatch) => ({
    getCoordinator: (id) => dispatch(getCoordinator(id)),
    getTutors: (id) => dispatch(getTutors(id)),
    asignTutor: (c_id, t_id, snackbar) =>
        dispatch(asignTutor(c_id, t_id, snackbar)),
    resolve: (id, body) => dispatch(resolve(id, body)),
    unasignTutor: (c_id, t_id, snackbar) =>
        dispatch(unasignTutor(c_id, t_id, snackbar)),
    editCoordinator: (body, snackbar) =>
        dispatch(editCoordinator(body, snackbar)),
    getAllBanks: () => dispatch(getAllBanks()),
});

export default withSnackbar(
    withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(Coordinator))
);
