export const createSnackbar = (payload, snackbar) => {
    payload.then(response => {
        const data = response.data;
        if (data.status === "error") {
            snackbar(data.message, { variant: "error" });
        } else {
            snackbar(data.message, { variant: "success" });
        }
    });
};
