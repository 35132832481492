import React, { Component } from "react"
import { connect } from "react-redux"
import { markLesson } from "../../Actions/LessonActions"
import { Typography } from "@material-ui/core"

class AgentPay extends Component {
    componentDidMount() {
        const lessonId = this.props.match.params.lesson_id
        const id = this.props.match.params.id
        this.props.markLesson(lessonId).then(() => {
            this.props.history.push("/agent/" + id)
        })
    }
    render() {
        return (
            <div style={{ padding: 12 }}>
                <Typography variant="subtitle1">
                    Redirigiendo a inicio...
                </Typography>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    markLesson: (id) => dispatch(markLesson(id)),
})

export default connect(null, mapDispatchToProps)(AgentPay)
