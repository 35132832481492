import React, { Component } from "react";
import { Paper, Typography, Divider, withTheme } from "@material-ui/core";
import { formatMoney } from "../../Utils/functions";

class Summary extends Component {
    render() {
        const { theme, incomes, salary, payments, margin, payed } = this.props;
        return (
            <>
                <div
                    style={{
                        display: "grid",
                        gridTemplateAreas:
                            '"info info . . ." "income . . . payed"',
                        gridGap: 12,
                        marginBottom: 24,
                    }}
                >
                    <div style={{ gridArea: "info" }}>
                        <Divider />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography variant="subtitle1" color="primary">
                                Ingresos
                            </Typography>
                            <Typography variant="subtitle1" color="primary">
                                {formatMoney(incomes)}
                            </Typography>
                        </div>
                        <Divider />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography variant="subtitle1" color="primary">
                                Sueldos Tutores
                            </Typography>
                            <Typography variant="subtitle1" color="primary">
                                {formatMoney(payments)}
                            </Typography>
                        </div>
                        <Divider />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Typography variant="subtitle1" color="primary">
                                Margen
                            </Typography>
                            <Typography variant="subtitle1" color="primary">
                                {formatMoney(margin)}
                            </Typography>
                        </div>
                        <Divider />
                    </div>
                    <Paper
                        style={{
                            gridArea: "income",
                            background: theme.palette.primary.main,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: 12,
                            }}
                        >
                            <Typography variant="h3">
                                Sueldo Coordinador
                            </Typography>
                            <Typography variant="h3">
                                {formatMoney(salary)}
                            </Typography>
                        </div>
                    </Paper>
                    <Paper
                        style={{
                            gridArea: "payed",
                            background: "transparent",
                            border: `1px solid ${theme.palette.primary.main}`,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: 12,
                            }}
                        >
                            <Typography
                                variant="h3"
                                style={{ color: theme.palette.grey.dark }}
                            >
                                Sueldo Pagado Coordinador
                            </Typography>
                            <Typography
                                variant="h3"
                                style={{ color: theme.palette.grey.dark }}
                            >
                                {formatMoney(payed)}
                            </Typography>
                        </div>
                    </Paper>
                </div>
            </>
        );
    }
}

export default withTheme(Summary);
