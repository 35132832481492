import React, { Component } from 'react'
import { FormControl, InputLabel, MenuItem, Select, withStyles } from '@material-ui/core'
import moment from 'moment'
import { getMonthNumber } from '../../Components/Agents/Profile/Utils'
import DateIntervalContext from './DateIntevalContext'
import { autobind } from '../../Utils/functions'

const style = () => ({

})
class DateIntervalSelector extends Component {
  constructor() {
    super()
    autobind(DateIntervalSelector, this)
  }

  componentDidMount() {
    const { update } = this.context
    const newState = {
      sdate: moment(new Date()).format("YYYY-MM-[01]"),
      edate: moment(new Date())
        .add(1, "month")
        .format("YYYY-MM-[01]"),
      smonth: moment(new Date()).format("MMMM"),
      emonth: moment(new Date()).add(1, "month").format("MMMM"),
      syear: moment(new Date()).format("YYYY"),
      eyear: moment(new Date()).add(1, "month").format("YYYY"),
    }
    update(newState)
  }

  handleChange(event) {
    const { target } = event
    const { update, params } = this.context
    params[target.name] = target.value
    params.sdate = `${params.syear}-${getMonthNumber(params.smonth)}-01`
    params.edate = `${params.eyear}-${getMonthNumber(params.emonth)}-01`
    update(params)
  }

  renderMonths() {
    const months = moment.months()
    return months.map((month) => (
      <MenuItem value={month} key={month}>
        {month}
      </MenuItem>
    ))
  }

  renderYears() {
    const startYear = 2020
    const actualYear = parseInt(moment(new Date()).format("YYYY"), 10)
    const finalYear = actualYear + 2
    const years = []
    for (let i = startYear; i < finalYear; i += 1) {
      years.push(i)
    }
    return years.map((year) => (
      <MenuItem value={year} key={year}>
        {year}
      </MenuItem>
    ))
  }

  render() {
    const { classes } = this.props
    const { params, disabled } = this.context
    return (
      <div className={classes.container}>
        <div className={classes.inline}>
          <FormControl style={{ margin: "6px 6px", minWidth: 229 }}>
            <InputLabel shrink>Mes desde</InputLabel>
            <Select
              disabled={disabled}
              value={moment(params.sdate).format("MMMM")}
              onChange={this.handleChange}
              name="smonth"
            >
              {this.renderMonths()}
            </Select>
          </FormControl>

          <FormControl style={{ margin: "6px 6px", minWidth: 100 }}>
            <InputLabel shrink>Año desde</InputLabel>
            <Select
              disabled={disabled}
              value={moment(params.sdate).format("YYYY")}
              onChange={this.handleChange}
              name="syear"
            >
              {this.renderYears()}
            </Select>
          </FormControl>
        </div>
        <div className={classes.inline}>
          <FormControl style={{ margin: "6px 6px", minWidth: 229 }}>
            <InputLabel shrink>Mes hasta</InputLabel>
            <Select
              disabled={disabled}
              value={moment(params.edate).format("MMMM")}
              onChange={this.handleChange}
              name="emonth"
            >
              {this.renderMonths()}
            </Select>
          </FormControl>

          <FormControl style={{ margin: "6px 6px", minWidth: 100 }}>
            <InputLabel shrink>Año hasta</InputLabel>
            <Select
              disabled={disabled}
              value={moment(params.edate).format("YYYY")}
              onChange={this.handleChange}
              name="eyear"
            >
              {this.renderYears()}
            </Select>
          </FormControl>
        </div>
      </div>
    )
  }
}

DateIntervalSelector.contextType = DateIntervalContext

export default withStyles(style)(DateIntervalSelector)