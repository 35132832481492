import React, { Component } from "react";
import { Typography, withStyles, Divider } from "@material-ui/core";
import { defaultStyle } from "../Utils/staticStyles";
import Comment from "./Comment";

class Comments extends Component {
    renderComments() {
        const data = this.props.data || [];
        return data.map((comment) => (
            <Comment
                data={comment}
                resolve={this.props.resolve}
                key={comment.id}
            />
        ));
    }
    render() {
        const { data } = this.props;
        return (
            <div>
                <Typography
                    variant="h2"
                    style={{ marginBottom: 12, marginTop: 24 }}
                >
                    Comentarios
                </Typography>
                <Divider />
                {data && data.length > 0 ? (
                    this.renderComments()
                ) : (
                    <Typography variant="subtitle1">
                        No tienes nuevos comentarios...
                    </Typography>
                )}
            </div>
        );
    }
}

export default withStyles(defaultStyle)(Comments);
