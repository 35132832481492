import React, { Component } from "react"
import { Typography, withStyles, Divider } from "@material-ui/core"
import propTypes from "prop-types"
import { formatMoney } from "../../../Utils/functions"
import Conditional from "../../../Shared/Conditional"

const style = () => ({
    inline: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    dataContainer: {
        width: "60%",
        "@media (max-width:500px)": {
            width: "100%",
        },
    },
})

class TransferDialog extends Component {
    render() {
        const { classes, total, message, credits } = this.props

        return (
            <>
                <Typography variant="h1">
                    Por favor realiza la transferencia desde tu banco a los
                    siguientes datos:
                </Typography>
                <Divider />
                <div className={classes.dataContainer}>
                    <div className={classes.inline}>
                        <Typography variant="subtitle1">Nombre:</Typography>
                        <Typography variant="subtitle2">Tutores</Typography>
                    </div>
                    <div className={classes.inline}>
                        <Typography variant="subtitle1">Banco:</Typography>
                        <Typography variant="subtitle2">Santander</Typography>
                    </div>
                    <div className={classes.inline}>
                        <Typography variant="subtitle1">Rut:</Typography>
                        <Typography variant="subtitle2">
                            76.155.902-8
                        </Typography>
                    </div>
                    <div className={classes.inline}>
                        <Typography variant="subtitle1">
                            Tipo de Cuenta:
                        </Typography>
                        <Typography variant="subtitle2">
                            Cuenta Corriente
                        </Typography>
                    </div>
                    <div className={classes.inline}>
                        <Typography variant="subtitle1">
                            N° de Cuenta:
                        </Typography>
                        <Typography variant="subtitle2">72463587</Typography>
                    </div>
                    <div className={classes.inline}>
                        <Typography variant="subtitle1">Correo:</Typography>
                        <Typography variant="subtitle2">
                            cobros@rtu.cl
                        </Typography>
                    </div>
                </div>
                <Divider />
                <Conditional condition={credits > 0}>
                    <>
                        <div className={classes.inline}>
                            <Typography variant="subtitle1">
                                Subtotal:
                            </Typography>
                            <Typography variant="subtitle2">
                                {formatMoney(total)}
                            </Typography>
                        </div>
                        <div className={classes.inline}>
                            <Typography variant="subtitle1">
                                Créditos:
                            </Typography>
                            <Typography variant="subtitle2">
                                -{formatMoney(credits)}
                            </Typography>
                        </div>
                        <Divider />
                    </>
                </Conditional>
                <div className={classes.inline}>
                    <Typography variant="subtitle1">Monto Total:</Typography>
                    <Typography variant="subtitle2">
                        {formatMoney(total - credits)}
                    </Typography>
                </div>
                <div className={classes.inline} style={{ flexWrap: "wrap" }}>
                    <Typography variant="subtitle1">
                        Mensaje a enviar:
                    </Typography>
                    <Typography variant="subtitle2">{message}</Typography>
                </div>
                <Divider />

                <div>
                    <Typography variant="caption">
                        Al presionar el siguiente botón usted afirma haber
                        realizado la transferencia y las clases seleccionadas
                        pasarán a estar en proceso de confirmación.
                    </Typography>
                </div>
                <Typography
                    variant="caption"
                    color="primary"
                    style={{ marginTop: 6, fontSize: 16 }}
                >
                    Esta acción no es reversible.
                </Typography>
            </>
        )
    }
}

TransferDialog.propTypes = {
    total: propTypes.number,
    message: propTypes.string,
}

TransferDialog.defaultProps = {
    total: 0,
    message: "Colegio: C, Apoderado: A, estudiante: E",
    credits: 0,
}

export default withStyles(style)(TransferDialog)
