const actionMaker = (state, name, type, action) => {
    const data = action.payload.data;
    if (data && data.status === "success") {
        switch (type) {
            case "fetch":
                const newState = { ...state };
                newState[name] = data.info;
                return newState;
            case "create":
                const toAdd = state[name];
                toAdd.push(data.info);
                state = { ...state };
                state[name] = toAdd;
                return state;
            case "delete":
                const toDelete = state[name];
                const result = toDelete.filter(
                    element => element.id !== data.info.id
                );
                state = { ...state };
                state[name] = result;
                return state;
            case "select":
                const selected = state[name].filter(
                    element => element.id.toString() === data.info.toString()
                )[0];
                state = { ...state, selected };
                return state;
            case "edit":
                const toEdit = [...state[name]];
                toEdit.forEach((element, index) => {
                    if (element.id === data.info.id) {
                        toEdit[index] = data.info;
                    }
                });
                state = { ...state };
                state[name] = toEdit;
                return state;
            default:
                return state;
        }
    } else {
        return state;
    }
};

export default actionMaker;
