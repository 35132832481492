import React, { Component } from "react"
import { connect } from "react-redux"
import {
    getAllSchools,
    createSchool,
    deleteSchool,
    selectSchool,
} from "../../Actions/SchoolsActions"
import { withStyles, Typography, IconButton, Divider } from "@material-ui/core"
import { withSnackbar } from "notistack"
import { validate } from "../../Utils/validations"
import GeneralForm from "../../Shared/GeneralForm"
import { formInfo, schoolTableActions } from "./schoolInfo"
import { defaultStyle } from "../../Utils/staticStyles"
import { tableInfo } from "./schoolInfo"
import GeneralTable from "../../Shared/GeneralTable"
import { AddCircle } from "@material-ui/icons"
import { getSchools } from "../../API/schools"

class Schools extends Component {
    constructor(props) {
        super(props)
        this.state = {
            params: {},
            open: false,
            schools: []
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleCreate = this.handleCreate.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
    }

    async componentDidMount() {
        const reponse = await getSchools()
        this.setState({ schools: reponse.data })
    }

    handleChange(event) {
        const params = { ...this.state.params }
        const target = event.target
        params[target.name] = target.value
        this.setState({ params })
    }

    handleCreate() {
        const { enqueueSnackbar, createSchool } = this.props
        const { params } = this.state
        if (validate(formInfo, params, enqueueSnackbar)) {
            const body = { ...params }
            createSchool(body, enqueueSnackbar)
            this.setState({ params: { name: "", address: "" } })
        }
    }

    handleDelete(id) {
        const { deleteSchool, enqueueSnackbar } = this.props
        deleteSchool(id, enqueueSnackbar)
    }

    handleSelect(row) {
        return () => {
            const { selectSchool, history } = this.props
            selectSchool(row.id)
            history.push("/school/" + row.id)
        }
    }

    handleOpen() {
        this.setState({ open: !this.state.open })
    }

    render() {
        const { open, params, schools } = this.state
        const { classes } = this.props
        return (
            <div style={{ maxHeight: "100%", padding: 12 }}>
                <div className={classes.flexLine}>
                    <Typography variant="h1" className={classes.title}>
                        Colegios
                    </Typography>
                    <IconButton color="primary" onClick={this.handleOpen}>
                        <AddCircle />
                    </IconButton>
                </div>
                <Typography variant="h2">Tabla de Colegios</Typography>
                <Divider />
                <div className={classes.tableContainer}>
                    <GeneralTable
                        data={schools}
                        info={tableInfo}
                        name="schools"
                        open
                        actions={schoolTableActions(this)}
                    />
                </div>
                <div className={classes.formContainer}>
                    <div style={{ width: "50%" }}>
                        {open && (
                            <GeneralForm
                                params={params}
                                title={"Nuevo colegio"}
                                submit="Agregar colegio"
                                handleChange={this.handleChange}
                                create={this.handleCreate}
                                info={formInfo}
                            />
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    school: state.school,
})

const mapDispatchToProps = (dispatch) => ({
    getAllSchools: () => dispatch(getAllSchools()),
    createSchool: (body, snackbar) => dispatch(createSchool(body, snackbar)),
    deleteSchool: (id, snackbar) => dispatch(deleteSchool(id, snackbar)),
    selectSchool: (id) => dispatch(selectSchool(id)),
})

export default withSnackbar(
    withStyles(defaultStyle)(
        connect(mapStateToProps, mapDispatchToProps)(Schools)
    )
)
