const lessonInfo = [
    { name: "date", label: "Fecha", required: true, type: "date" },
    {
        name: "hours",
        label: "Horas",
        required: true,
        type: "select",
        options: [
            { name: 0, id: 0 },
            { name: 1, id: 1 },
            { name: 2, id: 2 },
            { name: 3, id: 3 },
            { name: 4, id: 4 },
        ],
    },
    {
        name: "minutes",
        label: "Minutos",
        required: true,
        type: "select",
        options: [
            { name: 0, id: 0 },
            { name: 5, id: 5 },
            { name: 10, id: 10 },
            { name: 15, id: 15 },
            { name: 20, id: 20 },
            { name: 25, id: 25 },
            { name: 30, id: 30 },
            { name: 35, id: 35 },
            { name: 40, id: 40 },
            { name: 45, id: 45 },
            { name: 50, id: 50 },
            { name: 55, id: 55 },
        ],
    },
    {
        name: "agent_id",
        label: "Apoderado",
        required: true,
        type: "select",
        options: [
            { name: "mama", id: 1 },
            { name: "papa", id: 2 },
            { name: "hermano", id: 3 },
        ],
    },
    {
        name: "student_id",
        label: "Alumno",
        required: true,
        type: "select",
        options: [],
    },
    {
        name: "subject_id",
        label: "Asignatura",
        required: true,
        type: "select",
        options: [],
    },
];

export default lessonInfo;
