export const reduxMaker = (action, dispatch) => {
    dispatch({ type: action.type + "REQUEST", payload: 'DONE REQUEST' });
    action.payload
        .then(response => {
            dispatch({ type: action.type + "SUCCESS", payload: response });
        })
        .catch(error => {
            dispatch({ type: action.type + "SUCCESS", payload: error });
        });
};
