import actionMaker from "../Utils/reducers";

const TutorReducer = (
    state = { all: [], selected: {}, lessons: [], agents: [], subjects: [] },
    action
) => {
    switch (action.type) {
        case "getAllTutors":
            return actionMaker(state, "all", "fetch", action);
        case "getTutorsPayments":
            return actionMaker(state, "payments", "fetch", action);
        case "createTutor":
            return actionMaker(state, "all", "create", action);
        case "deleteTutor":
            return actionMaker(state, "all", "delete", action);
        case "getTutor":
            return actionMaker(state, "selected", "fetch", action);
        case "getLessons":
            return actionMaker(state, "lessons", "fetch", action);
        case "createLesson":
            return actionMaker(state, "selected", "fetch", action);
        case "deleteLesson":
            return actionMaker(state, "selected", "fetch", action);
        case "getTutorAgents":
            return actionMaker(state, "agents", "fetch", action);
        case "getTutorSubjects":
            return actionMaker(state, "subjects", "fetch", action);
        case "asignAgent":
            return actionMaker(state, "selected", "fetch", action);
        case "asignSubject":
            return actionMaker(state, "selected", "fetch", action);
        case "unasignAgent":
            return actionMaker(state, "selected", "fetch", action);
        case "unasignSubject":
            return actionMaker(state, "selected", "fetch", action);
        default:
            return state;
    }
};

export default TutorReducer;
