import axios from "axios"
import store from "../store"

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api/`,
    timeout: 30000,
})

instance.interceptors.request.use((config) => {
    const newConfig = { ...config }
    const { user } = store.getState().account
    newConfig.headers.level = user?.level
    newConfig.headers.id = user?.user?.id
    return config
})

export default instance
