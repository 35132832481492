import React, { Component } from "react";
import {
    withStyles,
    Typography,
    Tooltip,
    IconButton,
    withTheme,
} from "@material-ui/core";
import { AddCircle, Delete } from "@material-ui/icons";

const style = (theme) => ({
    title: {
        display: "flex",
        alignItems: "center",
    },
    subtitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 48,
    },
    listItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 5,
        borderRadius: 12,
        margin: "6px 0",
        background: theme.palette.primary.dark,
    },
});

class Asociations extends Component {
    handleUnasign(option, id) {
        const { unasign } = this.props;
        return () => unasign(option, id);
    }

    handleOpen(option) {
        const { open } = this.props;
        return () => open(option);
    }

    renderElements() {
        const { elements, classes, isTutor, option, theme } = this.props;
        return elements.map((element, index) => (
            <div key={element.id} className={classes.listItem}>
                <Typography
                    style={{ paddingLeft: 12, color: "white" }}
                    variant="subtitle2"
                >
                    {element.name}
                    {element.subject_type && ` - ${element.subject_type}`}
                </Typography>
                {!isTutor && (
                    <IconButton
                        color="secondary"
                        onClick={this.handleUnasign(option, element.id)}
                        size="small"
                        style={{
                            background: theme.palette.indigo.lighter,
                            marginLeft: 12,
                        }}
                    >
                        <Delete />
                    </IconButton>
                )}
            </div>
        ));
    }

    render() {
        const { classes, isTutor, option, title } = this.props;
        return (
            <>
                <div className={classes.title}>
                    <Typography variant="h2">{title}</Typography>
                    <Tooltip
                        title={
                            isTutor
                                ? "Solicite a su coordinador para agregar elementos"
                                : ""
                        }
                    >
                        <span>
                            <IconButton
                                color="primary"
                                onClick={this.handleOpen(option + "Form")}
                                disabled={isTutor}
                            >
                                <AddCircle />
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
                <div>{this.renderElements()}</div>
            </>
        );
    }
}

export default withTheme(withStyles(style)(Asociations));
