import actionMaker from "../Utils/reducers";

export const LessonReducer = (
    state = { selected: {}, pendings: { orphans: [], gruops: [] } },
    action
) => {
    switch (action.type) {
        case "GetLessonSUCCESS": {
            const newState = actionMaker(state, "selected", "fetch", action);
            newState.loading = false;
            return newState;
        }
        case "GetLessonREQUEST": {
            const newState = { ...state, loading: true };
            return newState;
        }
        case "ConfirmSomething": {
            return actionMaker(state, "selected", "fetch", action);
        }
        case "EditLesson": {
            return actionMaker(state, "selected", "fetch", action);
        }
        case "getPendings": {
            return actionMaker(state, "pendings", "fetch", action);
        }
        default:
            return state;
    }
};
