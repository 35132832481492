import React, { Component } from "react";
import { withStyles, Typography, IconButton } from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";

const style = (theme) => ({
    listItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 5,
        borderRadius: 12,
        background: theme.palette.primary.dark,
        flexBasis: 500,
    },
    flexContainer: {
        display: "flex",
        gap: "12px",
        flexWrap: "wrap",
        position: "relative",
    },
});

class ColorList extends Component {
    goto(id) {
        return () => this.props.goto(id);
    }

    renderList() {
        const { classes, data } = this.props;
        return data.map((element, index) => (
            <div key={element.id} className={classes.listItem}>
                <Typography
                    variant="subtitle2"
                    style={{ padding: "0 12px", color: "white" }}
                >
                    {element.name}
                </Typography>
                <div>
                    {this.props.goto && (
                        <IconButton
                            size="small"
                            onClick={this.goto(element.id)}
                            style={{ color: "lightgrey" }}
                        >
                            <ExitToApp />
                        </IconButton>
                    )}
                </div>
            </div>
        ));
    }
    render() {
        const { classes } = this.props;
        return <div className={classes.flexContainer}>{this.renderList()}</div>;
    }
}

export default withStyles(style)(ColorList);
