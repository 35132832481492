import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

class NotFound extends Component {
    render() {
        const { account } = this.props;
        if (account.user.level === "admin") {
            return <Redirect to={"/schools"} />;
        } else if (account.user.level === "coordinator") {
            return <Redirect to={"/tutors"} />;
        } else if (account.user.level === "tutor") {
            return <Redirect to={"/tutor/" + account.user.user.id} />;
        } else {
            return <Redirect to={"/agent/" + account.user.user.id} />;
        }
    }
}

const mapStateToProps = state => ({
    account: state.account
});

export default connect(mapStateToProps)(NotFound);
