import actionMaker from "../Utils/reducers";

const CoordinatorReducer = (
    state = { all: [], selected: {}, tutors: [], lessons: [], payments: [] },
    action
) => {
    switch (action.type) {
        case "getAllCoordinators":
            return actionMaker(state, "all", "fetch", action);
        case "getCoordPayments":
            return actionMaker(state, "payments", "fetch", action);
        case "createCoordinator":
            return actionMaker(state, "all", "create", action);
        case "deleteCoordinator":
            return actionMaker(state, "all", "delete", action);
        case "getCoordinator":
            return actionMaker(state, "selected", "fetch", action);
        case "getCoordTutors":
            return actionMaker(state, "tutors", "fetch", action);
        case "asignTutor":
            return actionMaker(state, "selected", "fetch", action);
        case "loadCoordLessons":
            return actionMaker(state, "lessons", "fetch", action);
        case "loadDashboard":
            return actionMaker(state, "dashboard", "fetch", action);
        default:
            return state;
    }
};

export default CoordinatorReducer;
