import React, { Component } from "react";
import {
    Typography,
    withStyles,
    IconButton,
    TextField,
    Select,
    MenuItem,
    FormControl
} from "@material-ui/core";
import { Edit, Cancel } from "@material-ui/icons";

const style = () => ({
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 30
    }
});

class InfoLine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            value: ""
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSend = this.handleSend.bind(this);
    }

    componentDidMount() {
        this.setState({ value: this.props.value });
    }

    handleChange(event) {
        const { target } = event;
        const value = this.format(target.value);
        this.setState({ value });
    }

    handleOpen() {
        const { open } = this.state;
        if (!open) {
            this.setState({ value: this.props.value });
        }
        this.setState({ open: !this.state.open });
    }

    format(value) {
        const { type } = this.props;
        if (type.includes("rut")) {
            let newValue = value.replace(".", "").replace("-", "");
            if (newValue.length === 8) {
                const arr = Array.from(newValue);
                arr.splice(7, 0, "-");
                newValue = arr.join("");
            } else if (newValue.length === 9) {
                const arr = Array.from(newValue);
                arr.splice(8, 0, "-");
                newValue = arr.join("");
            }
            if (newValue.length <= 10) {
                return newValue;
            } else {
                return Array.from(newValue)
                    .splice(10, 1)
                    .join("");
            }
        } else {
            return value;
        }
    }

    handleSend() {
        this.props.change({
            target: { value: this.state.value, name: this.props.realName }
        });
        this.setState({ open: false });
    }

    field(type) {
        const { realName, options } = this.props;
        if (type !== "select") {
            return (
                <TextField
                    name={realName}
                    value={this.state.value}
                    onChange={this.handleChange}
                    onBlur={this.handleOpen}
                    autoFocus
                    onKeyPress={e => e.key === "Enter" && this.handleSend()}
                />
            );
        } else {
            return (
                <FormControl
                    onKeyPress={e => e.key === "Enter" && this.handleSend()}
                >
                    <Select
                        value={this.state.value}
                        onChange={this.handleChange}
                        onBlur={this.handleSend}
                    >
                        {options.map(bank => (
                            <MenuItem value={bank.id} key={bank.id}>
                                {bank.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        }
    }

    render() {
        const { open } = this.state;
        const { classes, name, value, able, type } = this.props;
        return (
            <div className={classes.container}>
                <Typography variant="body1">{name}</Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {open ? (
                        this.field(type)
                    ) : (
                        <Typography variant="body1">{value}</Typography>
                    )}
                    {able && (
                        <IconButton
                            size="small"
                            style={{ marginLeft: 6 }}
                            onClick={this.handleOpen}
                        >
                            {open ? <Cancel /> : <Edit />}
                        </IconButton>
                    )}
                </div>
            </div>
        );
    }
}

export default withStyles(style)(InfoLine);
