import React, { Component } from "react";

export default class Transition extends Component {
    render() {
        const { on } = this.props;
        return (
            <div
                style={{
                    transition: "max-height 0.3s, opacity 0.8s",
                    maxHeight: on ? 500 : 0,
                    opacity: on ? 1 : 0,
                }}
            >
                {on && this.props.children}
            </div>
        );
    }
}
