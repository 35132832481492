import React, { Component } from "react"
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    withStyles,
    withTheme,
    IconButton,
} from "@material-ui/core"
import {
    RemoveCircle,
    CheckCircle,
    ExitToApp,
    Check,
} from "@material-ui/icons"
import { autobind, formatMoney } from "../../Utils/functions"

const style = () => ({
    cell: {
        padding: "12px 6px",
    },
    head: {
        padding: 6,
    },
})

class AgentDash extends Component {
    constructor() {
        super()
        autobind(AgentDash, this)
    }
    handleGoto(id) {
        return () => this.props.goto(id)
    }

    handleAddSimulation(id) {
        return () => {
            this.props.addSimulation(id)
        }
    }

    handleSimulateAll() {
        const { data, addSimulation } = this.props
        const ids = []
        for (let element of data) {
            if (!element.payed) { ids.push(element.id) }
        }
        const final_ids = ids.join("&")
        addSimulation(final_ids)
    }

    renderBody() {
        const { data, classes, theme, simulation_ids } = this.props
        return data.map((element) => (
            <TableRow key={element.id}>
                <TableCell className={classes.cell}>{element.name}</TableCell>
                <TableCell
                    className={classes.cell}
                    style={{ textAlign: "end" }}
                >
                    {formatMoney(element.total)}
                </TableCell>
                <TableCell
                    className={classes.cell}
                    style={{ textAlign: "end" }}
                >
                    {formatMoney(element.to_pay)}
                </TableCell>
                <TableCell style={{ textAlign: "end" }}>
                    {element.payed ? (
                        <IconButton style={{ paddingLeft: 0 }} disabled>
                            <CheckCircle style={{ color: "#249e24" }} />
                        </IconButton>
                    ) : simulation_ids.includes(element.id + "") ? (
                        <IconButton style={{ paddingLeft: 0 }} disabled>
                            <CheckCircle style={{ color: "#adaa2e" }} />
                        </IconButton>
                    ) : (
                        <IconButton style={{ paddingLeft: 0 }} disabled>
                            <RemoveCircle
                                style={{ color: theme.palette.error.main }}
                            />
                        </IconButton>
                    )}
                </TableCell>
                <TableCell style={{ textAlign: "end" }}>
                    <IconButton
                        color="primary"
                        onClick={this.handleGoto(element.id)}
                    >
                        <ExitToApp />
                    </IconButton>
                    {this.props.level === "admin" && (
                        <IconButton
                            color="primary"
                            onClick={this.handleAddSimulation(element.id)}
                            disabled={element.payed}
                        >
                            <Check />
                        </IconButton>
                    )}
                </TableCell>
            </TableRow>
        ))
    }
    render() {
        const { classes } = this.props
        return (
            <>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.head}>
                                Nombre
                            </TableCell>
                            <TableCell
                                className={classes.head}
                                style={{ textAlign: "end" }}
                            >
                                Monto a Total
                            </TableCell>
                            <TableCell
                                className={classes.head}
                                style={{ textAlign: "end" }}
                            >
                                Monto por cobrar
                            </TableCell>
                            <TableCell
                                className={classes.head}
                                style={{ textAlign: "end" }}
                            >
                                Pagado
                            </TableCell>
                            <TableCell style={{ textAlign: 'end' }}>
                                <IconButton onClick={this.handleSimulateAll}>
                                    <Check />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{this.renderBody()}</TableBody>
                </Table>
            </>
        )
    }
}

export default withTheme(withStyles(style)(AgentDash))
