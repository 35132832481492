const LoginReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case "Login":
            const data = action.payload.data;
            if (data.status === "success") {
                state = { ...state, user: data.info };
            }
            return state;
        case "Logout":
            state = { ...state, user: {} };
            return state;
        default:
            return state;
    }
};

export default LoginReducer;
