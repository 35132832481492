import actionMaker from "../Utils/reducers";

export const subjectTypeReducer = (state = { all: [] }, action) => {
    switch (action.type) {
        case "getSubjectTypes": {
            const newState = actionMaker(state, "all", "fetch", action);
            return newState;
        }
        default:
            return state;
    }
};
