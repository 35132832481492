import React, { Component } from "react";
import {
    Paper,
    withStyles,
    Typography,
    IconButton,
    Divider,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";

const style = (theme) => ({
    container: {
        padding: 12,
        background: theme.palette.primary.lighter,
        height: 160,
        transition: "all 0.2s ease-in-out",
        "&:hover": {
            transform: "scale(1.02)",
            cursor: "pointer",
        },
        borderRadius: 0,
        position: "relative",
        boxShadow: "none",
    },
    btn: {
        position: "absolute",
        bottom: 6,
        right: 6,
    },
    text: {
        position: "absolute",
        bottom: 12,
        left: 12,
        width: "60%",
    },
    separated: {
        display: "flex",
        justifyContent: "space-between",
    },
    separatedTop: {
        display: "flex",
        justifyContent: "space-between",
        width: "90%",
        marginTop: 6,
    },
});

class CoordinatorCard extends Component {
    goto(id) {
        return () => this.props.goto(id);
    }
    render() {
        const { classes, data } = this.props;
        return (
            <Paper className={classes.container} onClick={this.goto(data.id)}>
                <Typography variant="subtitle1">{data.name}</Typography>
                <Divider />
                <div className={classes.separatedTop}>
                    <Typography variant="subtitle2">Email: </Typography>
                    <Typography variant="caption" color="primary">
                        {data.email}
                    </Typography>
                </div>
                <div className={classes.separatedTop}>
                    <Typography variant="subtitle2">RUT: </Typography>
                    <Typography variant="caption" color="primary">
                        {data.rut}
                    </Typography>
                </div>
                <IconButton
                    color="secondary"
                    onClick={(e) => {
                        e.stopPropagation();
                        this.props.delete(data.id);
                    }}
                    className={classes.btn}
                >
                    <Delete />
                </IconButton>
                <div className={classes.text}>
                    <div className={classes.separated}>
                        <Typography variant="body1">
                            Tutores a cargo:
                        </Typography>
                        <Typography variant="body1">{data.tutors}</Typography>
                    </div>
                    <div className={classes.separated}>
                        <Typography variant="body1">
                            Comentarios sin resolver:
                        </Typography>
                        <Typography variant="body1">{data.comments}</Typography>
                    </div>
                </div>
            </Paper>
        );
    }
}

export default withStyles(style)(CoordinatorCard);
