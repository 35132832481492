import React, { Component } from 'react'
import { Button, Dialog, DialogContent, Divider, Typography, withStyles } from '@material-ui/core'
import LoaderSpinner from '../../../Shared/Loader/LoaderSpinner'
import { Cancel, CheckCircle } from '@material-ui/icons'

const style = () => ({
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "6px 0",
  },
  list: {
    maxHeight: 200,
    overflowY: "auto",
  },
  button: {
    marginTop: 12,
    textAlign: "end",
  },
  loader: {
    position: "relative",
    height: 80,
    marginTop: 12,
  }

})
class MailDialog extends Component {

  renderAgents() {
    const { mails_info, classes } = this.props
    const mails = mails_info.agents ? mails_info.agents : []
    return mails.map((agent) => {
      return (
        <div className={classes.listItem}>
          <Typography variant="body1">{`${agent.agent.name} ----- ${agent.agent.payment_mail || agent.agent.email}`}</Typography>
          {agent.status === "success" ? (
            <CheckCircle style={{ color: "green" }} />
          ) : (
            <Cancel style={{ color: "red" }} />
          )}
        </div>
      )
    })
  }

  render() {
    const { classes, sending, mails_info, onClose } = this.props
    return (
      <Dialog open={sending} maxWidth="md" fullWidth>
        <DialogContent className={classes.dialog}>
          <Typography variant="h1">
            Enviando correos...
          </Typography>
          <Typography variant="subtitle1">
            Esto podría tardar varios minutos, por favor espere.
          </Typography>
          <Divider />
          <Typography variant="subtitle2">
            {`Comenzamos envío: ${mails_info.sent} de ${mails_info.amount}`}
          </Typography>
          <Divider />
          {mails_info.sent >= 1 ? (
            <div className={classes.list}>
              {this.renderAgents()}
            </div>
          ) : (
            <div className={classes.loader}>
              <LoaderSpinner />
            </div>
          )}
          <div className={classes.button}>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={onClose}
            // disabled={
            //   mails_info.amount === 0 ||
            //   mails_info.amount !== mails_info.sent
            // }
            >
              Cerrar
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(style)(MailDialog)