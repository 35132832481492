import actionMaker from "../Utils/reducers";

export const AgentReducer = (
    state = {
        all: [],
        payed: [],
        defaulters: [],
        selected: {},
        lessons: [],
        students: [],
    },
    action
) => {
    switch (action.type) {
        case "getAllAgents":
            return actionMaker(state, "all", "fetch", action);
        case "getAllPayed":
            return actionMaker(state, "payed", "fetch", action);
        case "getAllDefaulters":
            return actionMaker(state, "defaulters", "fetch", action);
        case "createAgent":
            return actionMaker(state, "all", "create", action);
        case "deleteAgent":
            return actionMaker(state, "all", "delete", action);
        case "selectAgent":
            return actionMaker(state, "selected", "fetch", action);
        case "getAgentLessons":
            return actionMaker(state, "lessons", "fetch", action);
        case "deleteLesson":
            return actionMaker(state, "lessons", "delete", action);
        case "createStudent":
            return actionMaker(state, "selected", "fetch", action);
        case "getAgentStudents":
            return actionMaker(state, "students", "fetch", action);

        default:
            return state;
    }
};
