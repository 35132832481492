import React, { Component } from "react";
import {
    TextField,
    withStyles,
    Typography,
    IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { autobind } from "../../Utils/functions";

const style = (theme) => ({
    tag: {
        background: theme.palette.indigo.lighter,
        padding: 6,
        marginLeft: 6,
        width: "fit-content",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottomLeftRadius: 24,
        borderBottomRightRadius: 24,
        borderTopRightRadius: 24,
        borderTopLeftRadius: 24,
    },
});

class MultipleCreate extends Component {
    constructor(props) {
        super(props);
        autobind(MultipleCreate, this);
    }
    renderTags() {
        const { params, input, classes } = this.props;
        const { name } = input;
        const actualValue = params[name] || "";
        const tags = actualValue.split("&");
        return tags.map((tag) =>
            tag !== "" ? (
                <div className={classes.tag} key={tag}>
                    <Typography style={{ margin: "0 12px" }}>{tag}</Typography>
                    <IconButton
                        size="small"
                        onClick={this.handleDeleteTag(tag)}
                    >
                        <Close />
                    </IconButton>
                </div>
            ) : null
        );
    }

    handlePress(e) {
        if (e.key === "Enter") {
            const { params, input, onChange } = this.props;
            const { name } = input;
            const actualValue = params[name] || "";
            let newValue = actualValue;
            if (actualValue !== "") {
                newValue = actualValue + "&";
            }
            const event = { target: { name: name, value: newValue } };
            onChange(event);
        }
    }

    handleChange(e) {
        const { params, input, onChange } = this.props;
        const { name } = input;
        const actualValue = params[name] || "";
        const tagList = actualValue.split("&");
        tagList.splice(tagList.length - 1, 1, e.target.value);
        const newValue = tagList.join("&");
        const event = { target: { name: name, value: newValue } };
        onChange(event);
    }

    handleDeleteTag(tagName) {
        return () => {
            const { params, input, onChange } = this.props;
            const { name } = input;
            const actualValue = params[name] || "";
            const tagList = actualValue.split("&");
            tagList.splice(tagList.indexOf(tagName), 1);
            const newValue = tagList.join("&");
            const event = { target: { name: name, value: newValue } };
            onChange(event);
        };
    }

    render() {
        const { input, params } = this.props;
        const { name, label, required, type, index } = input;
        const value = params[name] || "";
        const inputValue = value.split("&")[value.split("&").length - 1];
        return (
            <div>
                <TextField
                    key={`${name}&${label}`}
                    style={{ margin: "6px 6px" }}
                    name={name}
                    label={label + (required ? " *" : "")}
                    onChange={this.handleChange}
                    value={inputValue}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    type={type}
                    autoFocus={index === 0}
                    onKeyPress={this.handlePress}
                />
                <div style={{ display: "flex", width: 300, flexWrap: "wrap" }}>
                    {this.renderTags()}
                </div>
            </div>
        );
    }
}

export default withStyles(style)(MultipleCreate);
