import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles, Typography, IconButton, Divider } from "@material-ui/core"
import { getAllBanks, createBank, deleteBank } from "../../Actions/BankAction"
import { getRequired, validate } from "../../Utils/validations"
import { formInfo, tableInfo } from "./info"
import GeneralForm from "../../Shared/GeneralForm"
import { withSnackbar } from "notistack"
import GeneralTable from "../../Shared/GeneralTable"
import { defaultStyle } from "../../Utils/staticStyles"
import { AddCircle } from "@material-ui/icons"
import Transition from "../../Shared/Transition"

class Banks extends Component {
    constructor(props) {
        super(props)
        this.state = {
            params: {},
            open: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleCreate = this.handleCreate.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
    }

    componentDidMount() {
        this.props.getAllBanks()
    }

    handleChange(event) {
        const { target } = event
        const params = { ...this.state.params }
        params[target.name] = target.value
        this.setState({ params })
    }

    handleCreate() {
        const { params } = this.state
        const { createBank, enqueueSnackbar } = this.props
        const validations = getRequired(formInfo)
        if (validate(validations, params, enqueueSnackbar)) {
            const body = { ...params }
            createBank(body, enqueueSnackbar)
            this.setState({ params: {}, open: false })
        }
    }

    handleDelete(id) {
        const { deleteBank, enqueueSnackbar } = this.props
        deleteBank(id, enqueueSnackbar)
    }

    handleOpen() {
        this.setState({ open: !this.state.open })
    }

    render() {
        const { classes, bank } = this.props
        const { params, open } = this.state
        return (
            <div className={classes.container}>
                <div className={classes.flexLine}>
                    <Typography variant="h1" className={classes.title}>
                        Bancos
                    </Typography>
                    <IconButton color="primary" onClick={this.handleOpen}>
                        <AddCircle />
                    </IconButton>
                </div>
                <Typography variant="h2">Tabla de Bancos</Typography>
                <Divider />
                <div className={classes.tableContainer}>
                    <GeneralTable
                        info={tableInfo}
                        data={bank.all}
                        delete={this.handleDelete}
                        name="all"
                    />
                </div>
                <div className={classes.formContainer}>
                    <div style={{ width: "50%" }}>
                        <Transition on={open}>
                            <GeneralForm
                                info={formInfo}
                                title="Nuevo Banco"
                                params={params}
                                handleChange={this.handleChange}
                                create={this.handleCreate}
                                submit="Agregar Banco"
                            />
                        </Transition>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    bank: state.bank,
})

const mapDispatchToProps = (dispatch) => ({
    getAllBanks: () => dispatch(getAllBanks()),
    createBank: (body, snackbar) => dispatch(createBank(body, snackbar)),
    deleteBank: (id, snackbar) => dispatch(deleteBank(id, snackbar)),
})

export default withSnackbar(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(defaultStyle)(Banks))
)
