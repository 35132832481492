import React, { Component } from "react";
import {
    Dialog,
    DialogContent,
    Typography,
    Button,
    withTheme,
    DialogActions,
    IconButton
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

class DeletePopup extends Component {
    constructor(props) {
        super(props);
        this.handleDelete = this.handleDelete.bind(this);
    }
    handleDelete() {
        const { action, deleteId, close } = this.props;
        action(deleteId);
        close();
    }
    render() {
        const { on, close, theme } = this.props;
        return (
            <Dialog open={on} onClose={close}>
                <DialogContent style={{ position: "relative", padding: 48 }}>
                    <Typography variant="subtitle1" style={{ marginBottom: 6 }}>
                        ¿Estas seguro que quieres eliminar este elemento?
                    </Typography>
                    <IconButton
                        size="small"
                        style={{ position: "absolute", right: 6, top: 6 }}
                        onClick={close}
                    >
                        <Close />
                    </IconButton>
                    <Typography variant="caption">
                        Esta acción es irreversible.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{
                            color: theme.palette.grey.main
                        }}
                        onClick={close}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            background: theme.palette.error.main,
                            color: "white"
                        }}
                        onClick={this.handleDelete}
                    >
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTheme(DeletePopup);
