import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import './animation.css'
const style = () => ({

})
class LoaderSpinner extends Component {

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <div className="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    )
  }
}

export default withStyles(style)(LoaderSpinner)