import React, { Component } from "react"
import {
    withStyles,
    Typography,
    Paper,
    TextField,
    Button,
    IconButton,
    Tooltip,
} from "@material-ui/core"
import LoginForm from "./LoginForm"
import { login, logout } from "../../Actions/LoginActions"
import { connect } from "react-redux"
import LoginFormHidden from "./LoginFormHidden"
import Conditional from "../../Shared/Conditional"
import { autobind } from "../../Utils/functions"
import axios from "axios"
import { createSnackbar } from "../../Utils/snackbars"
import { withSnackbar } from "notistack"
import { Visibility, VisibilityOff } from "@material-ui/icons"

const style = () => ({
    container: {
        width: "70%",
        margin: "auto",
        position: "relative",
        padding: 48,
        "@media (max-width:1000px)": {
            width: "100%",
            padding: 24,
        },
    },
    outer: {
        display: "flex",
        alignItems: "center",
        minHeight: "100vh",
        background: "whitesmoke",
    },
    title: {
        padding: 12,
        paddingBottom: 24,
        width: "80%",
        margin: "auto",
        "@media (max-width:1000px)": {
            width: "100%",
        },
    },
    input: {
        margin: "12px 0",
    },
    forgot: {
        width: "80%",
        margin: "auto",
        boxSizing: "border-box",
        padding: 24,
        position: "relative",
        "@media (max-width:1000px)": {
            width: "100%",
            padding: 24,
        },
    },
    button: {
        display: "flex",
        justifyContent: "flex-end",
    },
    floating: {
        position: "absolute",
        right: 24,
        bottom: 64,
    },
})

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            option: 1,
            open: false,
            openChange: false,
            params: {
                username: "",
                password: "",
                code: "",
                newpassword1: "",
                newpassword2: "",
                recoverymail: "",
            },
            loading: false,
            show: false,
        }
        autobind(Login, this)
    }

    componentDidMount() {
        this.props.logout()
        if (
            this.props.match.path !== "/login" &&
            this.props.match.path !== "/login/administracion"
        ) {
            this.props.history.push("/login")
        }
    }

    handleOption(event, value) {
        this.setState({ option: value })
    }

    handleChange(event) {
        const params = { ...this.state.params }
        params[event.target.name] = event.target.value
        this.setState({ params })
    }

    handleLogin() {
        this.props.history.push("/home")
    }

    handleFalseLogin() {
        this.props.history.push("/login/administracion")
    }

    handleOpen() {
        this.setState({ open: !this.state.open })
    }

    handleShow() {
        this.setState({ show: !this.state.show })
    }

    handleSendRecovery() {
        console.log(this.state.params.recoverymail)
        this.setState({ loading: true })
        const username = this.state.params.recoverymail
        const REQUEST = axios.post(
            `${process.env.REACT_APP_API_URL}/api/recovery/ask`,
            {
                username,
            }
        )
        createSnackbar(REQUEST, this.props.enqueueSnackbar)
        REQUEST.then((response) => {
            if (response.data.status === "success") {
                this.setState({
                    openChange: true,
                    open: false,
                    loading: false,
                })
            } else {
                this.setState({ loading: false })
            }
        })
    }

    handleRecoveryPassword() {
        console.log(this.state.params)
        // Check if same password
        const password = this.state.params.newpassword1
        const confirmation = this.state.params.newpassword2
        const code = this.state.params.code
        if (password === confirmation) {
            if (code !== "") {
                const REQUEST = axios.put(
                    `${process.env.REACT_APP_API_URL}/api/recovery/password`,
                    { code, password }
                )
                createSnackbar(REQUEST, this.props.enqueueSnackbar)
                REQUEST.then((response) => {
                    if (response.data.status === "success") {
                        this.setState({ openChange: false })
                    }
                })
            } else {
                this.props.enqueueSnackbar("Por favor ingrese un código", {
                    variant: "error",
                })
            }
        } else {
            this.props.enqueueSnackbar("Las contraseñas no coinciden!", {
                variant: "error",
            })
        }
    }

    getOption() {
        const { option, params } = this.state
        if (this.props.match.path.includes("administracion")) {
            return (
                <LoginFormHidden
                    option={option}
                    handleOption={this.handleOption}
                    login={this.props.login}
                    handleChange={this.handleChange}
                    username={params.username}
                    password={params.password}
                    handleLogin={this.handleLogin}
                    handleOpen={this.handleOpen}
                    handleFalseLogin={this.handleFalseLogin}
                />
            )
        } else {
            return (
                <LoginForm
                    option={option}
                    handleOption={this.handleOption}
                    login={this.props.login}
                    handleChange={this.handleChange}
                    username={params.username}
                    password={params.password}
                    handleLogin={this.handleLogin}
                    handleOpen={this.handleOpen}
                />
            )
        }
    }

    render() {
        const { open, params, openChange, loading, show } = this.state
        const { classes } = this.props
        return (
            <div className={classes.outer}>
                <div className={classes.container}>
                    <Typography className={classes.title} variant="h5">
                        Inicia Sesión
                    </Typography>
                    <Conditional condition={openChange} hasElse>
                        <Paper className={classes.forgot}>
                            <Typography variant="subtitle1">
                                Nueva contraseña
                            </Typography>
                            <Typography variant="caption">
                                Ingrese a continuación el código enviado a su
                                correo junto a la nueva contraseña que desea.
                            </Typography>
                            <TextField
                                className={classes.input}
                                label="Codigo de recuperación"
                                name="code"
                                value={params.code}
                                type="text"
                                placeholder="..."
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                onChange={this.handleChange}
                            />
                            <TextField
                                className={classes.input}
                                label="Nueva contraseña"
                                name="newpassword1"
                                value={params.newpassword1}
                                type={show ? "text" : "password"}
                                placeholder="..."
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                onChange={this.handleChange}
                            />
                            <TextField
                                className={classes.input}
                                label="Confirmar contraseña"
                                name="newpassword2"
                                value={params.newpassword2}
                                type={show ? "text" : "password"}
                                placeholder="..."
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                onChange={this.handleChange}
                            />
                            <div className={classes.button}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    onClick={this.handleRecoveryPassword}
                                >
                                    Cambiar Contraseña
                                </Button>
                            </div>
                            <Tooltip
                                title={
                                    show
                                        ? "Ocultar contraseña"
                                        : "Mostrar contraseña"
                                }
                            >
                                <IconButton
                                    color="primary"
                                    className={classes.floating}
                                    onClick={this.handleShow}
                                >
                                    {show ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </Tooltip>
                        </Paper>
                        {this.getOption()}
                    </Conditional>
                    <Conditional condition={open}>
                        <>
                            <Typography
                                className={classes.title}
                                variant="subtitle1"
                            >
                                Recuperación de contraseña
                            </Typography>
                            <Paper className={classes.forgot}>
                                <Typography variant="caption">
                                    Se le enviará un correo con un código para
                                    recuperar su contraseña.
                                </Typography>
                                <TextField
                                    className={classes.input}
                                    label="Correo de recuperación"
                                    name="recoverymail"
                                    value={params.recoverymail}
                                    type="text"
                                    placeholder="..."
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleChange}
                                />
                                <div className={classes.button}>
                                    <Conditional condition={loading}>
                                        <div className="lds-ring">
                                            <div />
                                            <div />
                                            <div />
                                            <div />
                                        </div>
                                    </Conditional>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        onClick={this.handleSendRecovery}
                                    >
                                        Recuperar
                                    </Button>
                                </div>
                            </Paper>
                        </>
                    </Conditional>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    account: state.account,
})

const mapDispatchToProps = (dispatch) => ({
    login: (body, snackbar) => dispatch(login(body, snackbar)),
    logout: () => dispatch(logout()),
})

export default withSnackbar(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Login))
)
