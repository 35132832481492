import React, { Component } from "react"
import { connect } from "react-redux"
import { tutorDispatcher } from "../../Actions/TutorActions"
import { withSnackbar } from "notistack"
import { schoolDispatcher } from "../../Actions/SchoolsActions"
import { bankDispatcher } from "../../Actions/BankAction"
import { formInfo } from "./tutorInfo"
import GeneralForm from "../../Shared/GeneralForm"
import { getRequired, validate } from "../../Utils/validations"
import { Typography, withStyles, IconButton, Button } from "@material-ui/core"
import { ExitToApp } from "@material-ui/icons"
import { defaultStyle } from "../../Utils/staticStyles"
import { autobind, combineDispatchers } from "../../Utils/functions"
import PaymentTable from "./Components/PaymentTable"
import Conditional from "../../Shared/Conditional"
import { getAllStore } from "../../Utils/stateUtils"
import moment from "moment"
import ViewTabs from "../../Shared/ViewTabs"
import All from "./All"
import { getSchools } from '../../API/schools'
import { getTutors } from "../../API/tutor"
import LoaderSpinner from "../../Shared/Loader/LoaderSpinner"

class Tutors extends Component {
    constructor(props) {
        super(props)
        this.state = {
            params: {},
            open: false,
            level: "",
            selected: 0,
            schools: [],
            tutors: [],
            loading: false,
        }
        autobind(Tutors, this)
    }
    async componentDidMount() {
        const { user } = this.props.account
        const { month } = this.state
        const date = {
            month: moment.months().indexOf(month) + 1,
            year: moment().format("YYYY"),
        }
        this.props.getTutorsPayments(date)
        this.setState({ loading: true })
        const tutorResponse = await getTutors()
        console.log(tutorResponse)
        const schoolsResponse = await getSchools()
        this.setState({ loading: false })
        this.props.getAllBanks()
        this.setState({ level: user.level, schools: schoolsResponse.data, tutors: tutorResponse.data.info })
    }

    handleSelect(selected) {
        return () => {
            this.setState({ selected })
        }
    }

    handleChange(event) {
        const params = { ...this.state.params }
        const { target } = event
        params[target.name] = target.value
        this.setState({ params })
    }

    handleCreate() {
        const { enqueueSnackbar, createTutor } = this.props
        const { params } = this.state
        const validations = getRequired(formInfo)
        if (validate(validations, params, enqueueSnackbar)) {
            const body = { ...params }
            createTutor(body, enqueueSnackbar)
            this.setState({ params: {} })
        }
    }

    handleDelete(id) {
        const { deleteTutor, enqueueSnackbar } = this.props
        deleteTutor(id, enqueueSnackbar)
    }

    handleGoto(row) {
        return () => {
            const { getTutor, history } = this.props
            getTutor(row.id)
            history.push("/tutor/" + row.id)
        }
    }

    handleOpen() {
        this.setState({ open: !this.state.open })
    }

    handleMonth(event) {
        const { target } = event
        this.setState({ month: target.value })
        const result = moment.months().indexOf(target.value) + 1
        const year = moment(new Date()).format("YYYY")
        const date = { month: result, year }
        this.props.getTutorsPayments(date)
    }

    setOptions(info) {
        const result = [...info]
        const { schools = [] } = this.state
        const { bank } = this.props
        result.forEach((item, index) => {
            if (item.type === "select") {
                switch (item.name) {
                    case "school_id":
                        result[index].options = schools
                        break
                    case "bank_id":
                        result[index].options = bank.all || []
                        break
                    default:
                        break
                }
            }
        })
        return result
    }

    render() {
        const { tutor, classes } = this.props
        const { params, open, level, selected, tutors, loading } = this.state
        const tutorsActions = [
            {
                name: "Ver Perfil",
                icon: ({ row }) => (
                    <IconButton onClick={this.handleGoto(row)} color="primary">
                        <ExitToApp />
                    </IconButton>
                ),
            },
        ]
        return (
            <div className={classes.container}>
                <div className={classes.flexLine}>
                    <Typography variant="h1" className={classes.title}>
                        Tutores
                    </Typography>
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={this.handleOpen}
                    >
                        Nuevo Tutor
                    </Button>
                </div>
                <Conditional condition={open} transition>
                    <div style={{ marginBottom: 24 }}>
                        <GeneralForm
                            info={this.setOptions(formInfo)}
                            handleChange={this.handleChange}
                            params={params}
                            title={"Nuevo Tutor"}
                            create={this.handleCreate}
                            submit="agregar tutor"
                        />
                    </div>
                </Conditional>
                <ViewTabs
                    tabs={["TODOS", "SUELDOS"]}
                    selected={selected}
                    handleSelect={this.handleSelect}
                >
                    {loading ? <LoaderSpinner /> : <All actions={tutorsActions} tutors={tutors} />}
                    <PaymentTable
                        data={tutor.payments}
                        handleGoto={this.handleGoto}
                        level={level}
                        name="payments"
                        actions={tutorsActions}
                    />
                </ViewTabs>
            </div>
        )
    }
}

const mapStateToProps = (state) => getAllStore(state)

const mapDispatchToProps = (dispatch) =>
    combineDispatchers(
        dispatch,
        tutorDispatcher,
        schoolDispatcher,
        bankDispatcher
    )

export default withStyles(defaultStyle)(
    withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Tutors))
)
