import { createSnackbar } from "../Utils/snackbars";
import instance from "../Utils/Instance";

export const getAllCoordinators = () => {
    const REQUEST = instance({
        method: "get",
        url: `coordinators`,
    });
    return {
        type: "getAllCoordinators",
        payload: REQUEST,
    };
};

export const createCoordinator = (body, snackbar) => {
    const REQUEST = instance({
        method: "post",
        url: `coordinators`,
        data: body,
    });
    createSnackbar(REQUEST, snackbar);
    return {
        type: "createCoordinator",
        payload: REQUEST,
    };
};

export const editCoordinator = (body, snackbar) => {
    const REQUEST = instance({
        method: "put",
        url: `coordinator/${body.id}`,
        data: body,
    });
    createSnackbar(REQUEST, snackbar);
    return {
        type: "getCoordinator",
        payload: REQUEST,
    };
};

export const deleteCoordinator = (id, snackbar) => {
    const REQUEST = instance({
        method: "delete",
        url: `coordinator/${id}`,
    });
    createSnackbar(REQUEST, snackbar);
    return {
        type: "deleteCoordinator",
        payload: REQUEST,
    };
};

export const getCoordinator = (id) => {
    const REQUEST = instance({
        method: "get",
        url: `coordinator/${id}`,
    });
    return {
        type: "getCoordinator",
        payload: REQUEST,
    };
};

export const getTutors = (id) => {
    const REQUEST = instance({
        method: "get",
        url: `coordinator/tutors/${id}`,
    });
    return {
        type: "getCoordTutors",
        payload: REQUEST,
    };
};

export const asignTutor = (coord_id, tutor_id, snackbar) => {
    const REQUEST = instance({
        method: "post",
        url: `coordinators/asign/${coord_id}/${tutor_id}`,
    });
    createSnackbar(REQUEST, snackbar);
    return {
        type: "asignTutor",
        payload: REQUEST,
    };
};

export const unasignTutor = (coord_id, tutor_id, snackbar) => {
    const REQUEST = instance({
        method: "post",
        url: `coordinators/uasign/${coord_id}/${tutor_id}`,
    });
    createSnackbar(REQUEST, snackbar);
    return {
        type: "asignTutor",
        payload: REQUEST,
    };
};

export const resolve = (id, body) => {
    const REQUEST = instance({
        method: "post",
        url: `coordinator/${id}/comment`,
        data: body,
    });
    return {
        type: "getCoordinator",
        payload: REQUEST,
    };
};

export const loadLessons = (id) => {
    const REQUEST = instance({
        method: "get",
        url: `coordinator/${id}/lessons`,
    });
    return {
        type: "loadCoordLessons",
        payload: REQUEST,
    };
};

export const loadDashboard = (id, date) => {
    const REQUEST = instance({
        method: "get",
        url: `coordinator/${id}/summary/`,
        headers: date,
    });
    return {
        type: "loadDashboard",
        payload: REQUEST,
    };
};

export const getCoordPayments = (date) => {
    const REQUEST = instance({
        method: "get",
        url: "coordinators/payments",
        headers: date,
    });
    return {
        type: "getCoordPayments",
        payload: REQUEST,
    };
};

export const payCoordinatorsAction = (date) => {
    const REQUEST = instance({
        method: "get",
        url: "coordinators/pay",
        headers: date,
    });
    return {
        type: "getCoordPayments",
        payload: REQUEST,
    };
};

export const addSimulationAction = (body) => {
    const REQUEST = instance({
        method: "post",
        url: "coordinators/simulation",
        data: body,
        headers: body,
    });
    return {
        type: "loadDashboard",
        payload: REQUEST,
    };
};

export const coordinatorDispatcher = (dispatch) => ({
    getAllCoordinators: () => dispatch(getAllCoordinators()),
    createCoordinator: (body, snackbar) =>
        dispatch(createCoordinator(body, snackbar)),
    editCoordinator: (body, snackbar) =>
        dispatch(editCoordinator(body, snackbar)),
    deleteCoordinator: (id, snackbar) =>
        dispatch(deleteCoordinator(id, snackbar)),
    getCoordinator: (id) => dispatch(getCoordinator(id)),
    getTutors: (id) => dispatch(getTutors(id)),
    asignTutor: (coord_id, tutor_id, snackbar) =>
        dispatch(asignTutor(coord_id, tutor_id, snackbar)),
    unasignTutor: (coord_id, tutor_id, snackbar) =>
        dispatch(unasignTutor(coord_id, tutor_id, snackbar)),
    resolve: (id, body) => dispatch(resolve(id, body)),
    loadLessons: (id) => dispatch(loadLessons(id)),
    loadDashboard: (id, month, year) =>
        dispatch(loadDashboard(id, month, year)),
    getCoordPayments: (date) => dispatch(getCoordPayments(date)),
});
