import { createSnackbar } from "../Utils/snackbars";
import instance from "../Utils/Instance";

export const getAllTutors = () => {
    const REQUEST = instance({
        method: "get",
        url: "tutors",
    });
    return {
        type: "getAllTutors",
        payload: REQUEST,
    };
};

export const getTutorsPayments = (date) => {
    const REQUEST = instance({
        method: "get",
        url: "tutors/payments",
        headers: date,
    });
    return {
        type: "getTutorsPayments",
        payload: REQUEST,
    };
};

export const payTutorsAction = (date) => {
    const REQUEST = instance({
        method: "get",
        url: "tutors/pay",
        headers: date,
    });
    return {
        type: "getTutorsPayments",
        payload: REQUEST,
    };
};

export const createTutor = (body, snackbar) => {
    const REQUEST = instance({
        method: "post",
        url: "tutors",
        data: body,
    });
    createSnackbar(REQUEST, snackbar);
    return {
        type: "createTutor",
        payload: REQUEST,
    };
};

export const deleteTutor = (id, snackbar) => {
    const REQUEST = instance({
        method: "delete",
        url: `tutor/${id}`,
    });
    createSnackbar(REQUEST, snackbar);
    return {
        type: "deleteTutor",
        payload: REQUEST,
    };
};

export const getTutor = (id, date) => {
    const REQUEST = instance({
        method: "get",
        url: `tutor/${id}`,
        headers: date,
    });
    return {
        type: "getTutor",
        payload: REQUEST,
    };
};

export const getLessons = (id) => {
    const REQUEST = instance({
        method: "get",
        url: `tutor/lessons/${id}`,
    });
    return {
        type: "getLessons",
        payload: REQUEST,
    };
};

export const createLesson = (body, snackbar, date) => {
    const REQUEST = instance({
        method: "post",
        url: `lessons`,
        data: body,
        headers: date,
    });
    createSnackbar(REQUEST, snackbar);
    return {
        type: "createLesson",
        payload: REQUEST,
    };
};

export const deleteLesson = (id, snackbar, date) => {
    const REQUEST = instance({
        method: "delete",
        url: `tutor/lesson/${id}`,
        headers: date,
    });
    createSnackbar(REQUEST, snackbar);
    return {
        type: "deleteLesson",
        payload: REQUEST,
    };
};

export const getTutorAgents = (id) => {
    const REQUEST = instance({
        method: "get",
        url: `tutor/agents/${id}`,
    });
    return {
        type: "getTutorAgents",
        payload: REQUEST,
    };
};

export const getTutorSubjects = (id) => {
    const REQUEST = instance({
        method: "get",
        url: `tutor/subjects/${id}`,
    });
    return {
        type: "getTutorSubjects",
        payload: REQUEST,
    };
};

export const asignAgent = (body, snackbar) => {
    const REQUEST = instance({
        method: "post",
        url: `tutor/agent`,
        headers: body.date,
        data: body,
    });
    createSnackbar(REQUEST, snackbar);
    return {
        type: "asignAgent",
        payload: REQUEST,
    };
};

export const asignSubject = (body, snackbar) => {
    const REQUEST = instance({
        method: "post",
        url: `tutor/subject`,
        data: body,
        headers: body.date,
    });
    createSnackbar(REQUEST, snackbar);
    return {
        type: "asignSubject",
        payload: REQUEST,
    };
};

export const unasignAgent = (body, snackbar) => {
    const REQUEST = instance({
        method: "post",
        url: `tutor/agent/un`,
        data: body,
        headers: body.date,
    });
    createSnackbar(REQUEST, snackbar);
    return {
        type: "unasignAgent",
        payload: REQUEST,
    };
};

export const unasignSubject = (body, snackbar) => {
    const REQUEST = instance({
        method: "post",
        url: `tutor/subject/un`,
        data: body,
        headers: body.date,
    });
    createSnackbar(REQUEST, snackbar);
    return {
        type: "unasignSubject",
        payload: REQUEST,
    };
};

export const editTutor = (body, snackbar, date) => {
    const REQUEST = instance({
        method: "put",
        url: `tutor/${body.id}`,
        data: body,
        headers: date,
    });
    createSnackbar(REQUEST, snackbar);
    return {
        type: "getTutor",
        payload: REQUEST,
    };
};

export const tutorDispatcher = (dispatch) => ({
    getAllTutors: () => dispatch(getAllTutors()),
    getTutorsPayments: (date) => dispatch(getTutorsPayments(date)),
    createTutor: (body, snackbar) => dispatch(createTutor(body, snackbar)),
    deleteTutor: (id, snackbar) => dispatch(deleteTutor(id, snackbar)),
    getTutor: (id, date) => dispatch(getTutor(id, date)),
    getLessons: (id) => dispatch(getLessons(id)),
    createLesson: (body, snackbar, date) =>
        dispatch(createLesson(body, snackbar, date)),
    deleteLesson: (id, snackbar, date) =>
        dispatch(deleteLesson(id, snackbar, date)),
    getTutorAgents: (id) => dispatch(getTutorAgents(id)),
    getTutorSubjects: (id) => dispatch(getTutorSubjects(id)),
    asignAgent: (body, snackbar) => dispatch(asignAgent(body, snackbar)),
    asignSubject: (body, snackbar) => dispatch(asignSubject(body, snackbar)),
    unasignAgent: (body, snackbar) => dispatch(unasignAgent(body, snackbar)),
    unasignSubject: (body, snackbar) =>
        dispatch(unasignSubject(body, snackbar)),
    editTutor: (body, snackbar, date) =>
        dispatch(editTutor(body, snackbar, date)),
});
