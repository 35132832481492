import actionMaker from "../Utils/reducers";

export const BankReducer = (state = { all: [] }, action) => {
    switch (action.type) {
        case "getAllBanks":
            return actionMaker(state, "all", "fetch", action);
        case "createBank":
            return actionMaker(state, "all", "create", action);
        case "deleteBank":
            return actionMaker(state, "all", "delete", action);
        default:
            return state;
    }
};
