import React, { Component } from "react";
import {
    Paper,
    withStyles,
    Typography,
    IconButton,
    Divider,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";

const style = (theme) => ({
    container: {
        padding: 12,
        background: theme.palette.primary.lighter,
        height: 260,
        transition: "all 0.2s ease-in-out",
        "&:hover": {
            transform: "scale(1.02)",
            cursor: "pointer",
        },
        borderRadius: 0,
        position: "relative",
        boxShadow: "none",
    },
    btn: {
        position: "absolute",
        bottom: 6,
        right: 6,
    },
    text: {
        position: "absolute",
        bottom: 12,
        left: 12,
        width: "60%",
    },
    separated: {
        display: "flex",
        justifyContent: "space-between",
    },
    separatedTop: {
        display: "flex",
        justifyContent: "space-between",
        width: "90%",
        marginTop: 6,
    },
    tag: {
        background: theme.palette.primary.main,
        color: "white",
        fontSize: 11,
        fontWeight: 600,
        padding: "3px 6px",
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        borderBottomLeftRadius: 20,
        margin: "2px 0",
    },
});

class TutorCard extends Component {
    goto(id) {
        return () => this.props.goto(id);
    }
    render() {
        const { classes, data } = this.props;
        return (
            <Paper className={classes.container} onClick={this.goto(data.id)}>
                <Typography variant="subtitle1">{data.name}</Typography>
                <Divider />
                <div className={classes.separatedTop}>
                    <Typography variant="subtitle2">Email: </Typography>
                    <Typography variant="caption" color="primary">
                        {data.email}
                    </Typography>
                </div>
                <div className={classes.separatedTop}>
                    <Typography variant="subtitle2">RUT: </Typography>
                    <Typography variant="caption" color="primary">
                        {data.rut}
                    </Typography>
                </div>
                <div className={classes.separatedTop}>
                    <Typography variant="subtitle2">
                        Clases realizadas:
                    </Typography>
                    <Typography variant="caption">{data.lessons}</Typography>
                </div>
                <Divider />
                <Typography variant="subtitle1">Asignaturas</Typography>
                <div style={{ width: "70%" }}>
                    {data.subjects.length > 0 ? (
                        <>
                            {data.subjects.slice(0, 2).map((subject) => (
                                <Typography
                                    className={classes.tag}
                                    key={subject.name}
                                >
                                    {subject.name}
                                </Typography>
                            ))}
                            {data.subjects.length > 2 && (
                                <Typography className={classes.tag}>
                                    {data.subjects.length - 2} más...
                                </Typography>
                            )}
                        </>
                    ) : (
                        <Typography variant="caption">
                            No tiene asignaturas asignadas...
                        </Typography>
                    )}
                </div>
                <IconButton
                    color="secondary"
                    onClick={(e) => {
                        e.stopPropagation();
                        this.props.delete(data.id);
                    }}
                    className={classes.btn}
                >
                    <Delete />
                </IconButton>
            </Paper>
        );
    }
}

export default withStyles(style)(TutorCard);
