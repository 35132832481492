import React, { Component } from "react";
import {
    withStyles,
    TextField,
    Paper,
    Typography,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from "@material-ui/core";
import ReactDOM from "react-dom";
import moment from "moment";
import MultipleCreate from "./Fields/MultipleCreate";

const style = (theme) => ({
    container: {
        padding: 12,
    },
    inputs: {
        display: "flex",
        flexWrap: "wrap",
    },
    submit: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: 12,
    },
});

const defaultValue = (type) => {
    if (type === "date") {
        return moment(new Date()).format("YYYY-MM-DD");
    }
    return "";
};

const MyInput = (
    name,
    label,
    handle,
    params,
    type,
    required,
    index,
    create
) => (
    <TextField
        key={`${name}&${label}`}
        style={{ margin: "6px 6px" }}
        name={name}
        label={label + (required ? " *" : "")}
        onChange={handle}
        value={params[name] || defaultValue(type)}
        InputLabelProps={{
            shrink: true,
        }}
        type={type}
        autoFocus={index === 0}
        onKeyPress={(e) => e.key === "Enter" && create()}
    />
);

const sort = (a, b) => {
    if (
        a.name.toString().toLocaleLowerCase() >
        b.name.toString().toLocaleLowerCase()
    ) {
        return 1;
    }
    if (
        a.name.toString().toLocaleLowerCase() <
        b.name.toString().toLocaleLowerCase()
    ) {
        return -1;
    }
    return 0;
};

const format = (event, type, handle, name) => {
    let value = event.target.value.split(".").join("").split("-").join("");
    if (value.length === 8) {
        const arr = Array.from(value);
        arr.splice(7, 0, "-");
        value = arr.join("");
    } else if (value.length === 9) {
        const arr = Array.from(value);
        arr.splice(8, 0, "-");
        value = arr.join("");
    }
    const numberAmount = value.replace("-", "").length;
    if (numberAmount <= 9) {
        handle({ target: { value, name } });
    }
};

const MyFormattedInput = (
    name,
    label,
    handle,
    params,
    type,
    required,
    index,
    create
) => {
    return (
        <TextField
            key={`${name}&${label}`}
            style={{ margin: "6px 6px" }}
            name={name}
            label={label + (required ? " *" : "")}
            onChange={(event) => format(event, type, handle, name)}
            value={params[name] || ""}
            InputLabelProps={{
                shrink: true,
            }}
            type={type}
            autoFocus={index === 0}
            onKeyPress={(e) => e.key === "Enter" && create()}
        />
    );
};

const MySelect = (
    name,
    label,
    handle,
    params,
    options,
    required,
    value,
    disabled
) => (
    <FormControl
        style={{ margin: "6px 6px", minWidth: 229 }}
        key={`${name}&${label}`}
    >
        <InputLabel shrink>{label + (required ? " *" : "")}</InputLabel>
        <Select
            name={name}
            onChange={handle}
            value={String(params[name]) || ""}
            disabled={options.length === 0 || disabled}
        >
            {renderMenu(options)}
        </Select>
    </FormControl>
);

const renderMenu = (options) => {
    if (options.length > 0) {
        return options.sort(sort).map((option) => (
            <MenuItem key={option.id} value={option.id}>
                {option.name}
            </MenuItem>
        ));
    }
};

class GeneralForm extends Component {
    componentDidMount() {
        const element = ReactDOM.findDOMNode(this);
        const position = element.getBoundingClientRect();

        if (position.top >= 0 && position.bottom <= window.innerHeight) {
            console.log("Element is fully visible in screen");
        } else {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }

    renderInputs() {
        const { info, params, handleChange } = this.props;
        return info.map((input, index) => {
            if (input.type && input.type === "select") {
                return MySelect(
                    input.name,
                    input.label,
                    handleChange,
                    params,
                    input.options,
                    input.required,
                    input.value,
                    input.disabled
                );
            } else if (input.type && input.type === "date") {
                return MyInput(
                    input.name,
                    input.label,
                    handleChange,
                    params,
                    "date",
                    input.required,
                    index,
                    this.props.create
                );
            } else if (input.type && input.type === "rut") {
                return MyFormattedInput(
                    input.name,
                    input.label,
                    handleChange,
                    params,
                    "rut",
                    input.required,
                    index,
                    this.props.create
                );
            } else if (input.type && input.type === "password") {
                return MyInput(
                    input.name,
                    input.label,
                    handleChange,
                    params,
                    input.type,
                    input.required,
                    index,
                    this.props.create
                );
            } else if (input.type && input.type === "multiple") {
                return (
                    <MultipleCreate
                        key={index}
                        input={input}
                        onChange={handleChange}
                        params={params}
                    />
                );
            } else {
                return MyInput(
                    input.name,
                    input.label,
                    handleChange,
                    params,
                    "text",
                    input.required,
                    index,
                    this.props.create
                );
            }
        });
    }
    render() {
        const { classes, title, create, submit, noPaper } = this.props;
        return (
            <>
                {noPaper ? (
                    <div>
                        <Typography
                            variant="body1"
                            style={{ marginBottom: 12, fontWeight: 600 }}
                            color="primary"
                        >
                            {title}
                        </Typography>

                        <div className={classes.inputs}>
                            {this.renderInputs()}
                        </div>
                        <div className={classes.submit}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={create}
                            >
                                {submit || "Crear"}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <Paper className={classes.container}>
                        <Typography
                            variant="body1"
                            style={{ marginBottom: 12, fontWeight: 600 }}
                            color="primary"
                        >
                            {title}
                        </Typography>

                        <div className={classes.inputs}>
                            {this.renderInputs()}
                        </div>
                        <div className={classes.submit}>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={create}
                            >
                                {submit || "Crear"}
                            </Button>
                        </div>
                    </Paper>
                )}
            </>
        );
    }
}

GeneralForm.propTypes = {};

GeneralForm.defaultProps = {
    info: [],
};

export default withStyles(style)(GeneralForm);
