import React, { Component } from "react"
import {
    withStyles,
    Typography,
    Divider,
    IconButton,
    TextField,
} from "@material-ui/core"
import { connect } from "react-redux"
import {
    getSchoolTutors,
    getSchoolCoordinators,
    getSchoolAgents,
    getSchoolSubjects,
    createSubject,
    deleteSubject,
    editSubject,
    editSchool,
    selectSchool,
    hideSubjectAction,
} from "../../Actions/SchoolsActions"
import SchoolResume from "./SchoolResume"
import { coordinatorInfo } from "../Coordinators/formInfo"
import {
    createCoordinator,
    deleteCoordinator,
} from "../../Actions/CoordinatorActions"
import { formInfo as tutorInfo } from "../Tutors/tutorInfo"
import { createTutor, deleteTutor } from "../../Actions/TutorActions"
import {
    createSchoolAgent,
    deleteSchoolAgent,
} from "../../Actions/AgentActions"
import { formInfo as schoolInfo } from "../Agents/info"
import SchoolSubjects from "./SchoolSubjects"
import { subjectInfo } from "./schoolInfo"
import { getAllBanks } from "../../Actions/BankAction"
import { getSubjectTypes } from "../../Actions/Subjetc"
import DiscountContainer from "./DiscountContainer"
import { autobind } from "../../Utils/functions"
import { withSnackbar } from "notistack"
import { Edit } from "@material-ui/icons"

const style = () => ({
    container: {
        padding: 12,
    },
    divider: {
        margin: "12px 0",
    },
    title: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: 5,
    },
    paper: {
        display: "grid",
        gridTemplateColumns: "5fr 1fr",
        gridGap: 12,
    },
    pageTitle: {},
    info: {
        width: "40%",
        display: "flex",
        alignItems: "center",
        marginBottom: 6,
    },
})

class School extends Component {
    constructor(props) {
        super(props)
        this.state = {
            edit: false,
            params: {},
        }
        autobind(School, this)
    }

    componentDidMount() {
        const id = this.props.match.params.id
        this.props.selectSchool(id)
        this.props.getSchoolCoordinators(id)
        this.props.getSchoolSubjects(id)
        this.props.getSchoolTutors(id)
        this.props.getSchoolAgents(id)
        this.props.getAllBanks()
        this.props.getSubjectTypes()
    }

    handleOpenEdit() {
        const { edit } = this.state
        if (edit) {
            this.handleEdit()
        }
        this.setState({ edit: !this.state.edit })
    }

    handleGoto(option, id) {
        this.props.history.push("/" + option + "/" + id)
    }

    handleEdit() {
        const { editSchool, enqueueSnackbar, match, school } = this.props
        const { params } = this.state
        const body = {
            base: params.base || school.selected.base,
            variable: params.variable || school.selected.variable,
            id: match.params.id,
        }
        editSchool(body, enqueueSnackbar)
    }

    handleChange(event) {
        const { target } = event
        const { params } = this.state
        params[target.name] = target.value
        this.setState({ params })
    }

    setOptions(info) {
        const result = [...info]
        const { school, bank, types } = this.props
        const actualSchool = school.all.find(
            (s) => `${s.id}` === this.props.match.params.id
        )
        result.forEach((item, index) => {
            if (item.type === "select") {
                switch (item.name) {
                    case "school_id":
                        result[index].options = [actualSchool]
                        break
                    case "bank_id":
                        result[index].options = bank.all || []
                        break
                    case "subject_type_id":
                        result[index].options = types.all || []
                        break
                    default:
                        break
                }
            }
        })
        return result
    }

    render() {
        const { edit, params } = this.state
        const { classes, school, types, hideSubject } = this.props
        return (
            <div className={classes.container}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h1" className={classes.pageTitle}>
                        {school.selected.name}
                    </Typography>
                    <IconButton onClick={this.handleOpenEdit}>
                        <Edit />
                    </IconButton>
                </div>
                <div className={classes.info}>
                    <Typography variant="subtitle1" style={{ marginRight: 12 }}>
                        Sueldo base coordinador:
                    </Typography>
                    {edit ? (
                        <TextField
                            type="number"
                            value={params.base || school.selected.base}
                            onChange={this.handleChange}
                            name="base"
                        />
                    ) : (
                            <Typography variant="subtitle2">
                                {school.selected.base} %
                            </Typography>
                        )}
                </div>
                <div className={classes.info}>
                    <Typography variant="subtitle1" style={{ marginRight: 12 }}>
                        Sueldo variable coordinador:
                    </Typography>
                    {edit ? (
                        <TextField
                            type="number"
                            value={params.variable || school.selected.variable}
                            onChange={this.handleChange}
                            name="variable"
                        />
                    ) : (
                            <Typography variant="subtitle2">
                                {school.selected.variable} %
                            </Typography>
                        )}
                </div>
                <SchoolSubjects
                    subjects={school.subjects}
                    create={this.props.createSubject}
                    id={school.selected.id}
                    types={types.all}
                    info={this.setOptions(subjectInfo)}
                    delete={this.props.deleteSubject}
                    edit={this.props.editSubject}
                    hideSubject={hideSubject}
                />
                <Divider />
                <DiscountContainer />
                <SchoolResume
                    name="Coordinadores"
                    data={school.coordinators}
                    singular="Coordinador"
                    info={this.setOptions(coordinatorInfo)}
                    create={this.props.createCoordinator}
                    delete={this.props.deleteCoordinator}
                    id={school.selected.id}
                    goto={this.handleGoto}
                    option="coordinator"
                />
                <SchoolResume
                    name="Tutores"
                    data={school.tutors}
                    singular="Tutor"
                    info={this.setOptions(tutorInfo)}
                    create={this.props.createTutor}
                    delete={this.props.deleteTutor}
                    id={school.selected.id}
                    goto={this.handleGoto}
                    option={"tutor"}
                />
                <SchoolResume
                    name="Apoderados"
                    data={school.agents}
                    singular="Apoderado"
                    info={this.setOptions(schoolInfo)}
                    create={this.props.createAgent}
                    delete={this.props.deleteAgent}
                    id={school.selected.id}
                    goto={this.handleGoto}
                    option={"agent"}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    school: state.school,
    bank: state.bank,
    types: state.types,
})

const mapDispatchToProps = (dispatch) => ({
    getSchoolTutors: (id) => dispatch(getSchoolTutors(id)),
    getSchoolCoordinators: (id) => dispatch(getSchoolCoordinators(id)),
    getSchoolAgents: (id) => dispatch(getSchoolAgents(id)),
    createCoordinator: (body, snackbar) =>
        dispatch(createCoordinator(body, snackbar)),
    createTutor: (body, snackbar) => dispatch(createTutor(body, snackbar)),
    deleteCoordinator: (id, snackbar) =>
        dispatch(deleteCoordinator(id, snackbar)),
    deleteTutor: (id, snackbar) => dispatch(deleteTutor(id, snackbar)),
    createAgent: (body, snackbar) =>
        dispatch(createSchoolAgent(body, snackbar)),
    deleteAgent: (id, snackbar) => dispatch(deleteSchoolAgent(id, snackbar)),
    getSchoolSubjects: (id) => dispatch(getSchoolSubjects(id)),
    createSubject: (body, snackbar) => dispatch(createSubject(body, snackbar)),
    deleteSubject: (id, snackbar) => dispatch(deleteSubject(id, snackbar)),
    editSubject: (body, snackbar) => dispatch(editSubject(body, snackbar)),
    getAllBanks: () => dispatch(getAllBanks()),
    getSubjectTypes: () => dispatch(getSubjectTypes()),
    editSchool: (body, snackbar) => dispatch(editSchool(body, snackbar)),
    selectSchool: (id) => dispatch(selectSchool(id)),
    hideSubject: body => dispatch(hideSubjectAction(body))
})

export default withSnackbar(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(School))
)
