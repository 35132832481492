import moment from 'moment'

export function getMonthNumber(month) {
  return moment.months().indexOf(month) + 1
}

export function getActualYear() {
  return moment(new Date()).format("YYYY")
}

export function redirectToPayment(url) {
  window.location = url
}

export function getPayed(lessons) {
  return lessons.filter((lesson) => lesson.payed).reduce((l1, l2) => ({ cost: l1.cost + l2.cost }), { cost: 0 }).cost
}

export function getToPay(lessons) {
  return lessons.filter((lesson) => !lesson.payed).reduce((l1, l2) => ({ cost: l1.cost + l2.cost }), { cost: 0 }).cost
}