import actionMaker from "../Utils/reducers"

export const RegisterReducer = (state = { all: [] }, action) => {
    switch (action.type) {
        case "getAllRegisters":
            return actionMaker(state, "all", "fetch", action)
        case "deleteRegister":
            return actionMaker(state, "all", "fetch", action)
        default:
            return state
    }
}
