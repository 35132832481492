import {
    formatDateMobile,
    formatHour,
    formatMoney,
    renderStatusCell,
} from "../../Utils/functions"
import { MonetizationOn, AddComment, CheckCircleOutline, HighlightOffOutlined } from "@material-ui/icons"
import { Tooltip, IconButton, Badge, Typography } from "@material-ui/core"
import React from "react"
import moment from "moment"

const formInfo = [
    { name: "name", label: "Nombre", required: true },
    { name: "email", label: "Correo", required: true },
    { name: "rut", label: "Rut", required: true, type: "rut" },
    { name: "phone", label: "Numero de Telefono" },
    { name: "address", label: "Direccion" },
    {
        name: "school_id",
        label: "Colegio",
        required: true,
        type: "select",
        option: [],
    },
    { name: "students", label: "Estudiantes", type: "multiple" },
]

const studentInfo = [{ name: "name", label: "Nombre", required: true }]

const tableInfo = [
    { key: "name", label: "Nombre" },
    { key: "email", label: "Correo" },
    { key: "school&name", label: "Colegio" },
]

export const defaulterInfo = [
    { key: "name", label: "Nombre" },
    { key: "email", label: "Correo" },
    { key: "payment_mail", label: "Correo de cobro" },
    {
        key: "sent_reminder&date", label: "Enviado", format: (value) => !!value ?
            (<div style={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <CheckCircleOutline style={{ color: 'green' }} />
                <Typography variant="caption" style={{ fontSize: 10 }}>{moment(value).format("YYYY-MM-DD HH:mm:ss")}</Typography>
            </div>)
            :
            <HighlightOffOutlined style={{ color: "#6a6a6a" }} />
    },
    { key: "school&name", label: "Colegio" },
    { key: "not_payed", label: "Deuda", format: formatMoney, align: "right" },
]

export const agentLessonInfo = [
    { key: "date", label: "Fecha", format: formatDateMobile },
    { key: "duration", label: "Duración", format: formatHour },
    { key: "subject&name", label: "Asignatura" },
    { key: "tutor&name", label: "Tutor" },
    { key: "agent&name", label: "Apoderado" },
    { key: "student&name", label: "Alumno" },
    { key: "cost", label: "Monto", format: formatMoney, align: "right" },
    {
        key: "all",
        label: "Estado",
        format: renderStatusCell,
        align: "right",
        notSortable: true,
    },
]

export const agentMobileInfo = [
    { key: "date", label: "Fecha", format: formatDateMobile },
    {
        key: "all",
        label: "Estado",
        format: renderStatusCell,
        align: "right",
        notSortable: true,
    },
    { key: "cost", label: "Monto", format: formatMoney, align: "right" },
    { key: "subject&name", label: "Asignatura" },
    { key: "tutor&name", label: "Tutor" },
    { key: "student&name", label: "Alumno" },
    { key: "agent&name", label: "Apoderado" },
    { key: "duration", label: "Duración", format: formatHour },
]

export const agentGroupInfo = [
    { key: "date", label: "Fecha", format: formatDateMobile },
    { key: "tutor&name", label: "Tutor" },
    { key: "agent&name", label: "Apoderado" },
    { key: "school&name", label: "Colegio" },
    { key: "payed", label: "Pagado", format: formatMoney, align: "right" },
    { key: "pending", label: "Pendiente", format: formatMoney, align: "right" },
    {
        key: "credits",
        label: "Descuentos",
        format: formatMoney,
        align: "right",
    },
    {
        key: "total",
        label: "Total Deposito",
        format: formatMoney,
        align: "right",
    },
]

export const agentLessonActions = (self) => {
    return [
        {
            name: "Pagar Clase",
            icon: ({ row }) => (
                <IconButton
                    onClick={self.handlePay(row)}
                    color="primary"
                    disabled={row.payed}
                >
                    <MonetizationOn />
                </IconButton>
            ),
        },
        {
            icon: ({ row }) => (
                <Tooltip
                    title={
                        row.comment
                            ? row.comment.resolved
                                ? "Su comentario ha sido resuelto"
                                : "Su comentario está siendo revisado"
                            : "Dejar un comentario"
                    }
                >
                    <span>
                        <IconButton
                            onClick={self.handleOpenComment(row.id)}
                            disabled={
                                row.comment ||
                                self.props.account.user.level !== "agent"
                            }
                        >
                            <Badge
                                color={
                                    row.comment && row.comment.resolved
                                        ? "primary"
                                        : "secondary"
                                }
                                variant={row.comment ? "dot" : "standard"}
                            >
                                <AddComment />
                            </Badge>
                        </IconButton>
                    </span>
                </Tooltip>
            ),
            color: "primary",
        },
    ]
}

export { formInfo, studentInfo, tableInfo }
