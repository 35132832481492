const routes = [
    { type: 0, name: "Colegios", path: "/schools" },
    { type: 0, name: "Coordinadores", path: "/coordinators" },
    { type: 1, name: "Tutores", path: "/tutors" },
    { type: 1, name: "Apoderados", path: "/agents" },
    { type: 1, name: "Resumen", path: "/dashboard", only: true },
    { type: 1, name: "Tarifas", path: "/subjects", only: true },
    { type: 0, name: "Resumen", path: "/dashboards" },
    { type: 0, name: "Bancos", path: "/banks" },
    { type: 0, name: "Boletas", path: "/bills" },
    { type: 1, name: "Pagos", path: "/pendings" },
    { type: 1, name: "Registros", path: "/registers" },
]

export default routes
