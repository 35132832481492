import React, { Component } from "react";
import { Typography, withStyles } from "@material-ui/core";
import moment from "moment";
import { firstUpper, formatMoney } from "../../Utils/functions";
import Conditional from "../Conditional";

const style = (theme) => ({
    container: {
        display: "grid",
        gridGap: 6,
        alignSelf: "center",
    },
    block: {
        width: "60%",
        padding: 6,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        justifySelf: "center",
        "@media (max-width:1000px)": {
            width: "95%",
            boxSizing: "border-box",
        },
    },
    success: {
        color: theme.palette.confirm.main,
    },
    middle: {
        color: theme.palette.error.main,
    },
    total: {
        color: theme.palette.primary.dark,
    },
    credits: {
        color: theme.palette.primary.main,
    },
});

class LessonsResumeCard extends Component {
    render() {
        const { classes, payed, payment, month, adjustments } = this.props;
        const upperMonth = firstUpper(month);
        return (
            <>
                <div className={classes.container}>
                    <div className={classes.block}>
                        <Typography variant="subtitle2">
                            Total clases de {upperMonth}:
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            className={classes.success}
                        >
                            {formatMoney(payment)}
                        </Typography>
                    </div>
                    <Conditional condition={adjustments > 0}>
                        <div
                            className={classes.block}
                            style={{ borderColor: "#249e24" }}
                        >
                            <Typography variant="subtitle2">
                                Ajustes por descuentos:
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                className={classes.middle}
                            >
                                -{formatMoney(adjustments)}
                            </Typography>
                        </div>
                    </Conditional>
                    <div
                        className={classes.block}
                        style={{ borderColor: "#249e24" }}
                    >
                        <Typography variant="subtitle2">
                            Total por pagar {upperMonth}:
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            className={classes.credits}
                        >
                            {formatMoney(payment - adjustments - payed)}
                        </Typography>
                    </div>
                    <div className={classes.block}>
                        <Typography variant="subtitle2">
                            Total pagado {upperMonth}:
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            className={classes.total}
                        >
                            {formatMoney(payed > 0 ? payed : 0)}
                        </Typography>
                    </div>
                </div>
            </>
        );
    }
}

LessonsResumeCard.propTypes = {};

LessonsResumeCard.defaultProps = {
    payed: 0,
    notPayed: 0,
    month: moment(new Date()).format("MMMM"),
    credits: 0,
};

export default withStyles(style)(LessonsResumeCard);
