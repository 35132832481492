import React, { Component } from "react"
import { connect } from "react-redux"
import {
    withStyles,
    Typography,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    withTheme,
    Button,
} from "@material-ui/core"
import {
    selectAgent,
    createStudent,
    createComment,
    editAgent,
    deleteStudent,
    getAgentLessons,
    payMultiple,
    markAsPayed,
    useCredits,
} from "../../../Actions/AgentActions"
import { withSnackbar } from "notistack"
import { deleteLesson } from "../../../Actions/TutorActions"
import { studentInfo, agentLessonInfo } from "../info"
import GeneralForm from "../../../Shared/GeneralForm"
import { getRequired, validate } from "../../../Utils/validations"
import UserCard from "../../../Shared/UserCard"
import { AddCircle, Delete } from "@material-ui/icons"
import moment from "moment"
import {
    firstUpper,
    checkInfo,
    autobind,
} from "../../../Utils/functions"
import Transition from "../../../Shared/Transition"
import Conditional from "../../../Shared/Conditional"
import WelcomeMessage from "../../../Shared/WelcomeMessage"
import GeneralTable from "../../../Shared/GeneralTable"
import LessonsResumeCard from "../../../Shared/Cards/LessonsResumeCard"
import NewSymbols from "../../../Shared/Cards/NewSymbols"
import { payLesson } from "../../../Actions/LessonActions"
import PaymentDialog from "./PaymentDialog"
import TransferDialog from "./TransferDialog"
import GeneralDialog from "../../../Shared/GeneralDialog"
import ResponsiveLesson from "./ResponsiveLesson"
import { getActualYear, getMonthNumber, getPayed, getToPay, redirectToPayment } from "./Utils"
import YearSelector from "../../../Shared/YearSelector"


const style = (theme) => ({
    container: {
        padding: 12,
    },
    title: {
        display: "flex",
        alignItems: "center",
    },
    subtitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    grid: {
        display: "grid",
        gridTemplateColumns: "2fr 1fr 2fr",
        gridGap: 12,
    },
    listItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 5,
        borderRadius: 12,
        margin: "6px 0",
        background: theme.palette.primary.dark,
    },
    resume: {
        background: theme.palette.indigo.lighter,
        display: "grid",
        borderRadius: 24,
        position: "relative",
    },
    dialog: {
        padding: 24,
    },
    bigdialog: {
        padding: 24,
    },
    button: {
        width: 200,
    },
    inline: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    topContainer: {
        display: "grid",
        marginBottom: 48,
        gridTemplateColumns: "1fr 1fr",
        "@media (max-width:1000px)": {
            display: "grid",
            marginBottom: 48,
            gridTemplateColumns: "1fr",
        },
        gridGap: 24,
    },
    students: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        gap: "12px",
        "& > *": {
            flexBasis: 500,
        },
    },
    inlineJoin: {
        display: 'flex',
        '& > *': {
            marginRight: 3
        },
        marginBottom: 24
    }
})

class Agent extends Component {
    constructor() {
        super()
        this.state = {
            params: {},
            open: false,
            month: moment(new Date()).format("MMMM"),
            year: moment(new Date()).format("YYYY"),
            selected: [],
            lesson_id: "",
            dialogName: "",
            selectedAmount: 0,
        }

        autobind(Agent, this)
    }
    componentDidMount() {
        const { match, selectAgent, getLessons } = this.props
        let { month } = this.state
        const id = match.params.id
        month = getMonthNumber(month)
        const year = getActualYear()
        const date = { month, year }
        selectAgent(id, date)
        getLessons(id)
    }

    handleReload() {
        this.componentDidMount()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match !== this.props.match) {
            this.componentDidMount()
        }
    }

    handleSwitchDialog(name) {
        return () => {
            let dialogName = this.state.dialogName
            if (dialogName !== name) {
                dialogName = name
            } else {
                dialogName = ""
            }
            this.setState({ dialogName }, this.componentDidMount)
        }
    }

    handleGoto(row) {
        const { history } = this.props
        return () => history.push(`/lesson/${row.id}/${row.tutor.id}`)
    }

    handlePayAll() {
        const { selected } = this.state
        const { payMultiple } = this.props
        const ids = selected
        payMultiple({ ids }).then((response) => {
            redirectToPayment(response.payload.data.url)
            console.log(response)
            this.setState({ selected: [] })
        })
    }

    handleUseCredits(id) {
        return () => {
            let { month } = this.state
            const { match, enqueueSnackbar, useCredits } = this.props
            const year = getActualYear()
            month = getMonthNumber(month)
            const date = { month, year }
            const { agent_id } = match.params
            useCredits({ ids: [id] }, enqueueSnackbar, date)
                .then(() => {
                    this.props.getLessons(agent_id).then((response) => {
                        const lessons = response.payload.data.info
                        const selected = lessons
                            .filter((l) => !l.payed)
                            .map((l) => l.id)
                        this.setState({ selected })
                    })
                })
            this.setState({ dialogName: "", selected: [] })
        }
    }

    handleUseMultipleCredits() {
        const body = { ids: this.state.selected }
        const { year } = this.state
        const month = moment.months().indexOf(this.state.month) + 1
        const date = { month, year }
        this.props
            .useCredits(body, this.props.enqueueSnackbar, date)
            .then((_) => {
                const { id } = this.props.match.params
                this.props.getLessons(id).then((response) => {
                    const lessons = response.payload.data.info
                    const selected = lessons
                        .filter((l) => !l.payed)
                        .map((l) => l.id)
                    this.setState({ selected })
                })
            })
        this.setState({ selected: [], dialogName: "" })
    }

    pay(id) {
        return () => {
            const { payLesson } = this.props
            payLesson(id).then((response) => {
                redirectToPayment(response.payload.data.payment_url)
            })
        }
    }



    // Mark selected lessons as payed by transfer
    handleTransfer() {
        const { enqueueSnackbar, markAsPayed } = this.props
        const body = { ids: this.state.selected }
        const { year } = this.state
        const month = moment.months().indexOf(this.state.month) + 1
        const date = { month, year }
        markAsPayed(body, enqueueSnackbar, date).then((_) => {
            const { id } = this.props.match.params
            this.props.getLessons(id).then((response) => {
                const lessons = response.payload.data.info
                const selected = lessons
                    .filter((l) => !l.payed)
                    .map((l) => l.id)
                this.setState({ selected })
            })
        })
        this.setState({ selected: [], dialogName: "" })
    }

    // Opens multiple payment dialog

    handleOpenPayment() {
        // Select all lessons that are less than the past month
        const { allLessons } = this.props
        const notPayed = allLessons.filter((lesson) => lesson.payed === false)
        const fromPastMonths = notPayed
            .filter(
                (lesson) =>
                    moment(lesson.date) < moment(new Date()).startOf("month")
            )
            .map((l) => l.id)

        this.setState({ selected: fromPastMonths, dialogName: "multiple" })
    }

    // handle changes on forms
    handleChange(event) {
        const { target } = event
        const params = { ...this.state.params }
        params[target.name] = target.value
        this.setState({ params })
    }

    // Send information to create student
    handleCreate() {
        const { params } = this.state
        const validations = getRequired(studentInfo)
        if (validate(validations, params, this.props.enqueueSnackbar)) {
            const body = { ...params, agent_id: this.props.match.params.id }
            const { year } = this.state

            const month = moment.months().indexOf(this.state.month) + 1
            const date = { month, year }
            this.props.createStudent(body, this.props.enqueueSnackbar, date)
            this.setState({ params: {}, open: false })
        }
    }

    // Opens form to create student
    handleOpen() {
        this.setState({ open: !this.state.open })
    }

    // Deletes student
    handleDeleteStudent(id) {
        const { year } = this.state
        const month = moment.months().indexOf(this.state.month) + 1
        const date = { month, year }
        return () =>
            this.props.deleteStudent(id, this.props.enqueueSnackbar, date)
    }

    // Edits user
    handleEdit(event) {
        const { target } = event
        const body = {
            id: this.props.match.params.id,
            [target.name]: target.value,
        }
        const { year } = this.state
        const month = moment.months().indexOf(this.state.month) + 1
        const date = { month, year }
        this.props.editAgent(body, this.props.enqueueSnackbar, date)
    }

    // Selects a month on the selector
    handleMonth(event) {
        const { target } = event
        this.setState({ month: target.value })
        const result = moment.months().indexOf(target.value) + 1
        const { id } = this.props.match.params
        const { year } = this.state
        const date = { month: result, year }
        this.props.selectAgent(id, date)
    }

    handleYear(event) {
        const { target } = event
        this.setState({ year: target.value })
        const month = moment.months().indexOf(this.state.month) + 1
        const { id } = this.props.match.params
        const year = target.value
        const date = { month, year }
        this.props.selectAgent(id, date)
    }

    // Selects a row on the table
    handleSelectRow(id) {
        return () => {
            const { selected } = this.state
            if (selected.indexOf(id) === -1) {
                selected.push(id)
            } else {
                selected.splice(selected.indexOf(id), 1)
            }
            this.setState({ selected })
        }
    }

    handleUnselect() {
        this.setState({ selected: [] })
    }

    renderStudents() {
        const { agent, classes, theme } = this.props
        const students = agent.students || []
        return students.map((student, index) => (
            <div key={student.id} className={classes.listItem}>
                <Typography
                    variant="subtitle2"
                    style={{ paddingLeft: 12, color: "white" }}
                >
                    {student.name}
                </Typography>
                <IconButton
                    color="secondary"
                    onClick={this.handleDeleteStudent(student.id)}
                    size="small"
                    style={{
                        background: theme.palette.indigo.lighter,
                        marginLeft: 12,
                    }}
                >
                    <Delete />
                </IconButton>
            </div>
        ))
    }

    renderMonths() {
        return moment.months().map((month) => (
            <MenuItem value={month} key={month}>
                {firstUpper(month)}
            </MenuItem>
        ))
    }

    render() {
        const {
            params,
            open,
            month,
            selected,
            dialogName,
            year
        } = this.state
        const { classes, agent, allLessons, account } = this.props
        const lessons = agent.lessons || []
        let totalToPay = 0
        const isMobile = window.innerWidth <= 500
        allLessons
            .filter((l) => selected.indexOf(l.id) !== -1)
            .forEach((l) => {
                totalToPay += l.cost
            })
        const message =
            agent && agent.school
                ? selected.length > 1
                    ? `${agent.school.name} ${agent.name} clases multiples`
                    : `${agent.school.name} ${agent.name}`
                : "no message yet..."
        const transferCredits =
            agent && agent.school
                ? selected.length > 1
                    ? agent.all_credits
                    : agent.credits
                : 0
        const multiCreditActions = [
            {
                name: "Utilizar creditos",
                func: this.handleUseMultipleCredits,
            },
        ]

        const multiPayActions = [
            {
                name: isMobile ? "Transferencia" : "Pagar por Transferencia",
                func: this.handleSwitchDialog("transfer"),
                disabled: selected.length <= 1,
            },
            {
                name: isMobile ? "WebPay" : "Pagar por WebPay",
                func: this.handlePayAll,
                disabled: selected.length <= 1,
            },
        ]
        return (
            <>
                <GeneralDialog
                    name="multiple"
                    selected={dialogName}
                    actions={
                        totalToPay - agent.all_credits > 0
                            ? multiPayActions
                            : multiCreditActions
                    }
                    size="xl"
                    close={this.handleSwitchDialog("multiple")}
                >
                    <PaymentDialog
                        allLessons={allLessons}
                        selected={selected}
                        select={this.handleSelectRow}
                        unselect={this.handleUnselect}
                        totalToPay={totalToPay}
                        credits={agent.all_credits}
                    />
                </GeneralDialog>

                <GeneralDialog
                    name="transfer"
                    close={this.handleSwitchDialog("transfer")}
                    selected={dialogName}
                    actions={[
                        {
                            name: "Notificar Transferencia",
                            func: this.handleTransfer,
                        },
                    ]}
                >
                    <TransferDialog
                        total={totalToPay}
                        message={message}
                        credits={transferCredits}
                    />
                </GeneralDialog>


                <div className={classes.container}>
                    <div className={classes.topContainer}>
                        <div>
                            <UserCard
                                data={agent}
                                onChange={this.handleEdit}
                                able
                            />
                        </div>
                        <div className={classes.resume}>
                            <LessonsResumeCard
                                payed={getPayed(lessons)}
                                notPayed={getToPay(lessons)}
                                month={month}
                                credits={agent.credits}
                                spent={agent.spent_credits}
                                base={agent.base_credits}
                                reload={this.handleReload}
                            />
                        </div>
                    </div>
                    <Conditional condition={checkInfo(agent, account)} hasElse>
                        <>
                            <div className={classes.inlineJoin}>
                                <FormControl>
                                    <InputLabel shrink>Seleccione Mes</InputLabel>
                                    <Select
                                        value={month}
                                        onChange={this.handleMonth}
                                    >
                                        {this.renderMonths()}
                                    </Select>
                                </FormControl>
                                <YearSelector value={year} onChange={this.handleYear} />
                            </div>
                            {/* Reducible a month selector */}
                            <div className={classes.inline}>
                                <Typography variant="h2">
                                    Clases de {agent.name}
                                </Typography>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={this.handleOpenPayment}
                                >
                                    Pagar clases
                                </Button>
                            </div>
                            <Divider />
                            <GeneralTable
                                data={agent.lessons}
                                info={agentLessonInfo}
                                name="lessons"
                                open={true}
                                ResponsiveRender={ResponsiveLesson}
                            />
                            <div style={{ height: 10 }} />
                            <NewSymbols />
                            <Divider />
                            <div className={classes.title}>
                                <Typography variant="h2">
                                    Estudiantes
                                </Typography>

                                <IconButton
                                    color="primary"
                                    onClick={this.handleOpen}
                                >
                                    <AddCircle />
                                </IconButton>
                            </div>
                            <div className={classes.students}>
                                <div>{this.renderStudents()}</div>
                                <Transition on={open}>
                                    <div>
                                        <GeneralForm
                                            info={studentInfo}
                                            params={params}
                                            handleChange={this.handleChange}
                                            create={this.handleCreate}
                                            title={"Agregar estudiante"}
                                        />
                                    </div>
                                </Transition>
                            </div>
                            <Divider />
                        </>
                        <WelcomeMessage />
                    </Conditional>
                </div>
            </>
        )
    }
}

Agent.propTypes = {

}


Agent.defaultProps = {
    agent: {
        lessons: []
    }
}

const mapStateToProps = (state) => ({
    account: state.account,
    agent: state.agent.selected,
    allLessons: state.agent.lessons,
})

const mapDispatchToProps = (dispatch) => ({
    selectAgent: (id, date) => dispatch(selectAgent(id, date)),
    deleteLesson: (id, snackbar) => dispatch(deleteLesson(id, snackbar)),
    createStudent: (body, snackbar, date) =>
        dispatch(createStudent(body, snackbar, date)),
    createComment: (body, snackbar, date) =>
        dispatch(createComment(body, snackbar, date)),
    editAgent: (body, snackbar, date) =>
        dispatch(editAgent(body, snackbar, date)),
    deleteStudent: (id, snackbar, date) =>
        dispatch(deleteStudent(id, snackbar, date)),
    payLesson: (id) => dispatch(payLesson(id)),
    getLessons: (id) => dispatch(getAgentLessons(id)),
    payMultiple: (ids) => dispatch(payMultiple(ids)),
    markAsPayed: (body, snackbar, date) =>
        dispatch(markAsPayed(body, snackbar, date)),
    useCredits: (ids, snackbar, date) =>
        dispatch(useCredits(ids, snackbar, date)),
})

export default withTheme(
    withSnackbar(
        connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Agent))
    )
)
