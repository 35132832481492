/* eslint-disable no-useless-computed-key */
import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
    spacing: 6,
    shape: { borderRadius: 5 },
    palette: {
        primary: {
            main: "#2cace3",
            contrastText: "white",
            dark: "#22607b",
            light: "#51b4de",
            lighter: "#dce9f2",
        },
        secondary: {
            main: "#814b79",
        },
        paper: {
            main: "#ECECEC",
            dark: "#BABABA",
        },
        error: {
            main: "#cb6a6d",
        },
        grey: {
            main: "#ECECEC",
            medium: "#6A6A6A",
            dark: "#3E3E3E",
            dark2: "#6A6A6A",
            border: "#CACACA",
        },
        indigo: {
            main: "#1A237E",
            dark: "#000051",
            light: "#534BAE",
            lighter: "#DCE2F2",
        },
        confirm: {
            main: "#249e24",
        },
        middle: {
            main: "#adaa2e",
        },
    },
    typography: {
        fontFamily: "Montserrat, sans-serif",
        h1: {
            color: "#22607b",
            fontSize: 18,
            fontWeight: 600,
        },
        h2: {
            color: "#6C6C6C",
            fontSize: 16,
            fontWeight: 600,
        },
        subtitle1: {
            color: "#6A6A6A",
            fontSize: 15,
            fontWeight: 600,
        },
        subtitle2: {
            color: "#6A6A6A",
            fontSize: 15,
        },
        body1: {
            letterSpacing: 0,
            color: "#707070",
            fontSize: 13,
        },
        body2: {
            color: "#404040",
            fontSize: 15,
            ["@media (max-width:500px)"]: {
                fontSize: 13,
            },
        },
        h3: {
            color: "white",
            fontSize: 16,
            fontWeight: 600,
        },
        h4: {
            color: "white",
            fontSize: 22,
            fontWeight: 600,
        },
        h5: {
            color: "#2cace3",
            fontSize: 24,
            fontWeight: 600,
        },
        subtitle: {
            color: "#6A6A6A",
            fontSize: 16,
            fontWeight: 600,
        },
        caption: {
            color: "#6A6A6A",
            fontStyle: "italic",
            fontSize: 13,
            fontWeight: 600,
        },
    },
    overrides: {
        MuiTableCell: {
            body: {
                fontSize: 12,
                ["@media (max-width:600px)"]: {
                    fontSize: 11,
                },
            },
            head: {
                fontSize: 13,
                ["@media (max-width:600px)"]: {
                    fontSize: 11,
                },
            },
            root: {
                padding: "3px 0",
                ["@media (max-width:600px)"]: {
                    fontSize: 11,
                },
                borderBottom: "1px solid #534bae75",
            },
            stickyHeader: {
                backgroundColor: "#dce9f2",
            },
        },
        MuiTableRow: {
            root: {
                height: 55,
            },
        },
        MuiButton: {
            root: {
                fontWeight: 600,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: "#814b79",
                margin: "12px 0",
                opacity: 0.8,
            },
        },
        MuiIconButton: {
            colorSecondary: {
                color: "grey",
                "&:hover": {
                    color: "#cb6a6d",
                },
            },
        },
        MuiMenu: {
            paper: {
                maxHeight: 300,
            },
        },
        MuiInput: {
            formControl: {
                width: 229,
                ["@media (max-width:500px)"]: {
                    minWidth: 150,
                    width: "100%",
                },
            },
        },
        MuiFormControl: {
            root: {
                ["@media (max-width:500px)"]: {
                    minWidth: 150,
                    width: "100%",
                },
            },
        },
        MuiInputBase: {
            root: {
                ["@media (max-width:500px)"]: {
                    fontSize: 11,
                },
            },
            input: {
                background: "#00000017",
                paddingLeft: 6,
                fontSize: 13,
            },
        },
        MuiBadge: {
            colorPrimary: {
                backgroundColor: "#249e24",
            },
            colorSecondary: {
                backgroundColor: "#cb6a6d",
            },
        },
    },
});

export default theme;
