import React, { Component } from "react";
import { Paper, Typography, withStyles, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { logout } from "../Actions/LoginActions";
import {
    ExitToApp,
    AccountCircle,
    KeyboardArrowLeft
} from "@material-ui/icons";
import { withRouter } from "react-router-dom";

const style = () => ({
    container: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
    }
});

class Header extends Component {
    constructor(props) {
        super(props);

        this.handleProfile = this.handleProfile.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }
    handleProfile() {
        const { account } = this.props;
        if (account.user.type === 1)
            this.props.history.push("/coordinator/" + account.user.user.id);
    }

    handleBack() {
        this.props.history.goBack();
    }
    render() {
        const { classes, account } = this.props;
        return (
            <Paper style={{ display: "flex", justifyContent: "space-between" }}>
                <IconButton color="primary" onClick={this.handleBack}>
                    <KeyboardArrowLeft />
                </IconButton>
                <div className={classes.container}>
                    <Typography style={{ padding: 12 }}>
                        {account.user.user.name}
                    </Typography>
                    <IconButton onClick={this.props.logout} color="secondary">
                        <ExitToApp />
                    </IconButton>
                    {account.user.level !== "admin" && (
                        <IconButton
                            onClick={this.handleProfile}
                            color="primary"
                        >
                            <AccountCircle />
                        </IconButton>
                    )}
                </div>
            </Paper>
        );
    }
}

const mapStateToProps = state => ({
    account: state.account
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout())
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Header))
);
