import instance from "../Utils/Instance";

export const getSubjectTypes = () => {
    const REQUEST = instance({
        method: "get",
        url: `subject_types`,
    });
    return {
        type: "getSubjectTypes",
        payload: REQUEST,
    };
};

export const subjectDispatcher = (dispatch) => ({
    getSubjectTypes: () => dispatch(getSubjectTypes()),
});
