import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class Home extends Component {
    renderHome() {
        const { account } = this.props;
        switch (account.user.type) {
            case 0: {
                return <Redirect to="/schools" />;
            }
            case 1: {
                return <Redirect to="/tutors" />;
            }
            case 2: {
                if (account.user.level === "tutor") {
                    return <Redirect to={"/tutor/" + account.user.user.id} />;
                } else {
                    return <Redirect to={"/agent/" + account.user.user.id} />;
                }
            }
            default: {
                return <Redirect to="notfound" />;
            }
        }
    }
    render() {
        return <div>{this.renderHome()}</div>;
    }
}

const mapStateToProps = state => ({
    account: state.account
});

export default connect(mapStateToProps)(Home);
