import React, { Component } from "react";
import { IconButton, Typography, withStyles } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";

const style = (theme) => ({
    inline: {
        display: "flex",
        alignItems: "center",
    },
    success: {
        color: "#249e24",
    },
    middle: {
        color: "#adaa2e",
    },
    fail: {
        color: theme.palette.error.main,
    },
});

class NewSymbols extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div>
                <Typography variant="caption">
                    Simbología de la tabla
                </Typography>
                <div className={classes.inline}>
                    <IconButton
                        disabled
                        size="small"
                        style={{ padding: "3px 6px 3px 0" }}
                    >
                        <CheckCircleOutline className={classes.fail} />
                    </IconButton>
                    <Typography variant="body1">Por pagar</Typography>
                </div>
                <div className={classes.inline}>
                    <IconButton
                        disabled
                        size="small"
                        style={{ padding: "3px 6px 3px 0" }}
                    >
                        <CheckCircleOutline className={classes.middle} />
                    </IconButton>
                    <Typography variant="body1">
                        Pago en proceso de confirmación
                    </Typography>
                </div>
                <div className={classes.inline}>
                    <IconButton
                        disabled
                        size="small"
                        style={{ padding: "3px 6px 3px 0" }}
                    >
                        <CheckCircleOutline className={classes.success} />
                    </IconButton>
                    <Typography variant="body1">Pago confirmado</Typography>
                </div>
            </div>
        );
    }
}

export default withStyles(style)(NewSymbols);
