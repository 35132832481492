import React, { Component } from "react";
import propTypes from "prop-types";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    withStyles,
} from "@material-ui/core";
import Conditional from "./Conditional";

const style = () => ({
    dialog: {
        padding: 24,
    },
    bigdialog: {
        padding: 24,
    },
});

class GeneralDialog extends Component {
    renderActions() {
        const { actions } = this.props;
        return actions.map((action) => {
            return (
                <Button
                    onClick={
                        actions.args ? action.func(...action.args) : action.func
                    }
                    key={action.name}
                    color={action.color ? action.color : "primary"}
                    variant={action.variant ? action.variant : "contained"}
                    size={action.size ? action.size : "small"}
                >
                    {action.name}
                </Button>
            );
        });
    }
    render() {
        const {
            selected,
            actions,
            name,
            children,
            close,
            size,
            classes,
        } = this.props;
        return (
            <Dialog
                onClose={close}
                open={selected === name}
                fullWidth
                maxWidth={size ? size : "md"}
            >
                <DialogContent>{children}</DialogContent>
                <Conditional condition={actions.length > 0}>
                    <DialogActions className={classes.dialog}>
                        {this.renderActions()}
                    </DialogActions>
                </Conditional>
            </Dialog>
        );
    }
}

GeneralDialog.propTypes = {
    name: propTypes.string,
    actions: propTypes.arrayOf(propTypes.object),
    data: propTypes.object,
};

GeneralDialog.defaultProps = {
    name: "dialog",
    actions: [],
    data: { name: "" },
};

export default withStyles(style)(GeneralDialog);
